import '../../../_base.scss';
import '../Modals.scss';
import './MessageModal.scss';
import { GlobalStyle } from '../../../global-style';
import React from 'react';
import Modal from 'react-modal';
import _ from 'lodash';


export class MessageModal extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <Modal isOpen={this.props.showModal} className="common-modal message-modal">
        <div className="message-container">
            {this.props.msg}
        </div>
          <button className="button yes-btn" onClick={this.props.ok}>Okay</button>
      </Modal>
    );
  }
}

export default MessageModal;
