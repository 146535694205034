import axios from 'axios'
import { axiosInstance } from '../../redux/AuthRedux/axiosInterceptors'

const baseUrl = `${process.env.REACT_APP_CMS_API_URL}agreements`
export const REFRESH_FAILURE = 'REFRESH_FAILURE'

export async function getAgreements(filterStr) {
  const response = await axiosInstance.get(
    `${baseUrl}${filterStr ? filterStr : ''}`
  )
  return response.data
}

export async function updateAgreement(id, data) {
  const response = await axiosInstance.put(`${baseUrl}/${id}`, data)

  return response.data
}

export async function getAgreement(id) {
  const response = await axiosInstance.get(`${baseUrl}/${id}`)
  return response.data
}

export async function createAgreement(data) {
  const response = await axiosInstance.post(baseUrl, data)

  return response.data
}
