import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createHistory from 'history/createBrowserHistory'
import { routerMiddleware } from 'react-router-redux'
import { authMiddleware } from './middleware/authmiddleware'
import thunk from 'redux-thunk'
import promiseMiddleware from './middleware/promise'

import appReducer from './reducer'

export const history = createHistory()

const historyMiddleware = routerMiddleware(history)

const middleware = [promiseMiddleware, thunk, historyMiddleware, authMiddleware]

const store = createStore(
  appReducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store

if (module.hot) {
  module.hot.accept('./reducer', () => {
    // eslint-disable-next-line
    const nextRootReducer = require('./reducer')
    store.replaceReducer(nextRootReducer)
  })
}
