import React, { useEffect } from 'react'
import AgreementForm from '../AgreementForm'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUsersByCompany } from '../../redux/CompaniesRedux/CompanyDetailRedux'
import { createAgreement } from '../../redux/AgreementsRedux/AgreementsRedux'
import './styles.scss'

const NewAgreement = ({
  history,
  error,
  companyId,
  clearError,
  companyUsers,
  getUsersByCompany,
  createAgreement
}) => {
  const _handleCancel = () => history.push(`/companies/${companyId}`)
  const _handleSubmit = values => {
    const data = {
      code: `${values.entity.value}${values.product.value}${values.term.value}${
        values.number
      }`,
      companies: [parseInt(companyId, 10)],
      users: values.users.map(item => parseInt(item.value))
    }

    createAgreement(data, () => {
      setTimeout(() => history.push(`/companies/${companyId}`), 1000)
    })
  }

  useEffect(() => {
    getUsersByCompany(companyId, '?perPage=5000')
  }, [companyId])
  const parsedUsers =
    companyUsers &&
    companyUsers.users.map(item => ({
      value: item.id,
      label: item.name
    }))

  return (
    <div id='new-agreement'>
      <AgreementForm
        error={error}
        userList={parsedUsers || []}
        onSubmit={_handleSubmit}
        onCancel={_handleCancel}
        clearError={clearError}
        title='Create New Agreement No'
      />
    </div>
  )
}

export default connect(
  (state, props) => ({
    companyId: props.match.params.id,
    companyUsers: state.companyDetail.users,
    error: state.agreements.error
  }),
  dispatch => ({
    getUsersByCompany: bindActionCreators(getUsersByCompany, dispatch),
    createAgreement: bindActionCreators(createAgreement, dispatch)
  })
)(NewAgreement)
