import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getAudit, resetAudit, downloadAudit, downloadUploadLog } from '../redux/AuditRedux'
import { ContentContainer, SectionTitle, WidthContent } from '../global-style'
import LoadingContainer from '../Loading/components/LoadingContainer'
import TableComponent from '../common/TableComponent/TableComponent'
import Filter from '../common/Filter/Filter'
import moment from 'moment'
import forEach from 'lodash/forEach'

export class AuditContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      filterItems: {
        search: {
          label: 'search',
          placeHolder: '',
          type: 'text',
          onChangeHandle: e => {
            this.onSearchChange(e, 'search', 'text')
          }
        },
        fromDate: {
          placeholderText: 'From',
          dateFormat: 'dd/MM/yyyy',
          type: 'date',
          isClearable: true,
          onChangeHandle: e => {
            this.onSearchChange(e, 'fromDate', 'date')
          },
          rangeError: false
        },
        toDate: {
          placeholderText: 'To',
          dateFormat: 'dd/MM/yyyy',
          type: 'date',
          isClearable: true,
          onChangeHandle: e => {
            this.onSearchChange(e, 'toDate', 'date')
          }
        }
      },
      filterButtons: {
        isSearch: { text: 'Search' },
        isDownload: { text: 'Download Logs' }
      },
      columns: {
        date: {
          name: 'Date',
          attr: 'createdAtStr',
          type: 'text',
          isSort: true,
          isSortDesc: true
        },
        action: {
          name: 'Actions',
          attr: 'message',
          type: 'html',
          onClickHandle:(item) => this.onClickUploadLog(item)
        }
      },
      orderBy: 'createdAt',
      order: 'desc'
    }
  }

  componentDidMount() {
    const { getAudit } = this.props

    getAudit()
  }

  setFilterValue = (name, value) => {
    const { filterItems } = this.state

    return this.setState({
      filterItems: {
        ...filterItems,
        [name]: {
          ...filterItems[name],
          value
        }
      }
    })
  }

  onSearchChange = (e, param, type) => {
    if (type === 'text') {
      return this.setFilterValue(param, e.target.value)
    }

    if (type === 'date') {
      return this.setFilterValue(param, e)
    }
  }

  _handleFilter = () => {
    const { resetAudit } = this.props

    resetAudit()
    this.fetchData()
  }

  generateDownloadLink = () => {
    const { filterItems, orderBy, order } = this.state
    let filterStr = `${
      process.env.REACT_APP_CMS_API_URL
    }action-logs/download?orderBy=${orderBy}&order=${order}`

    forEach(filterItems, (item, key) => {
      if (key === 'search' && item.value) {
        filterStr += `&q=${item.value}`
      } else if (key === 'fromDate' && item.value) {
        filterStr += `&startDate=${moment(item.value).format('YYYY-MM-DD')}`
      } else if (key === 'toDate' && item.value) {
        filterStr += `&endDate=${moment(item.value).format('YYYY-MM-DD')}`
      }
    })

    return filterStr
  }

  fetchData = (page = 1) => {
    const { filterItems, orderBy, order } = this.state
    const { getAudit } = this.props
    let filterStr = `?page=${page}&orderBy=${orderBy}&order=${order}`

    forEach(filterItems, (item, key) => {
      if (key === 'search' && item.value) {
        filterStr += `&q=${item.value}`
      } else if (key === 'fromDate' && item.value) {
        filterStr += `&startDate=${moment(item.value).format('YYYY-MM-DD')}`
      } else if (key === 'toDate' && item.value) {
        filterStr += `&endDate=${moment(item.value).format('YYYY-MM-DD')}`
      }
    })

    getAudit(filterStr)
  }

  onClickUploadLog = (item) => {
    if(item.headerItems){
      this.props.downloadUploadLog(item.headerItems);
    }
  }

  _handlePageClick = data => {
    this.fetchData(data.selected + 1)
  }

  _handleSort = (col, order) => {
    let { columns } = this.state
    const { resetAudit } = this.props

    this.setState(
      {
        order,
        columns: {
          ...columns,
          date:
            order === 'asc'
              ? {
                  ...columns.date,
                  isSortDesc: false,
                  isSortAsc: true
                }
              : {
                  ...columns.date,
                  isSortDesc: true,
                  isSortAsc: false
                }
        }
      },
      () => {
        resetAudit()
        this.fetchData()
      }
    )
  }

  _handleDownload = async () => {
    const anchor = document.createElement('a')
    const file = await downloadAudit(this.generateDownloadLink())
    const objectUrl = URL.createObjectURL(file)

    document.body.appendChild(anchor)

    anchor.href = objectUrl
    anchor.download = 'auditlogs.csv'
    anchor.click()

    URL.revokeObjectURL(objectUrl)
  }

  render() {
    const { filterButtons, filterItems, columns } = this.state
    const { audit, meta } = this.props

    return (
      <ContentContainer>
        <LoadingContainer showLoading={this.state.showLoading} />
        <SectionTitle>
          <div>Audit Log</div>
        </SectionTitle>
        <WidthContent>
          <Filter
            className='mt-4'
            filters={filterItems}
            buttons={filterButtons}
            onFilter={this._handleFilter}
            onDownload={this._handleDownload}
          />
          <TableComponent
            columns={columns}
            data={audit}
            meta={meta}
            handlePageClick={this._handlePageClick}
            sort={this._handleSort}
          />
        </WidthContent>
      </ContentContainer>
    )
  }
}

export default connect(
  state => ({
    audit: state.audit.data,
    meta: state.audit.meta
  }),
  dispatch => ({
    getAudit: bindActionCreators(getAudit, dispatch),
    resetAudit: bindActionCreators(resetAudit, dispatch),
    downloadUploadLog: bindActionCreators(downloadUploadLog, dispatch)
  })
)(AuditContainer)
