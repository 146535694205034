import React, { useEffect } from 'react'
import CompanyForm from '../CompanyForm'
import {
  updateCompany,
  clearErrorMsgs,
  fetchCompanyById
} from '../../redux/CompaniesRedux/CompaniesRedux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './styles.scss'

const EditCompany = ({
  companyId,
  history,
  error,
  updateCompany,
  clearError,
  currentCompany,
  fetchCompanyById
}) => {
  const _handleCancel = () => history.push(`/companies/${companyId}`)
  const _handleSubmit = data => {
    updateCompany(companyId, data, () => {
      setTimeout(() => history.push(`/companies/${companyId}`), 1000)
    })
  }

  useEffect(() => {
    fetchCompanyById(companyId)
  }, [companyId])

  return (
    !!currentCompany && (
      <div id='edit-company'>
        <CompanyForm
          initValues={currentCompany}
          edit={true}
          error={error}
          onSubmit={_handleSubmit}
          onCancel={_handleCancel}
          clearError={clearError}
          title='Edit Company'
        />
      </div>
    )
  )
}

export default connect(
  (state, props) => ({
    companyId: props.match.params.id,
    error: state.companies.error,
    currentCompany: state.companies.companies[props.match.params.id]
  }),
  dispatch => ({
    fetchCompanyById: bindActionCreators(fetchCompanyById, dispatch),
    updateCompany: bindActionCreators(updateCompany, dispatch),
    clearError: bindActionCreators(clearErrorMsgs, dispatch)
  })
)(EditCompany)
