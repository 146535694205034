import './Submenu.scss'
import React from 'react'
import { uid } from 'react-uid'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import _ from 'lodash'

class Submenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actionTypes: [{ create: '+' }],
      dropdownOpen: false
    }
  }

  toggle = () => {
    if (this.state.dropdownOpen) {
      this.setState({ dropdownOpen: false })
    } else {
      this.setState({ dropdownOpen: true })
    }
  }

  renderDropDownButton = (button, index, items) => {
    return (
      <div key={index} className="submenu-button">
        <Dropdown
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          onClick={this.toggle}
        >
          <DropdownToggle
            tag="span"
            onClick={this.toggle}
            data-toggle="dropdown"
            aria-expanded={this.state.dropdownOpen}
          >
            {this.state.actionTypes[button.actionType]} {button.name}
          </DropdownToggle>
          <DropdownMenu>
            {_.map(button.dropdownItems, item => {
              let isEnabled = true
              if (item.label === 'New admin' && !this.props.isSuper) {
                isEnabled = false
              }
              return (
                <div
                  key={uid(item)}
                  className={`${
                    isEnabled ? 'item-parent' : 'item-parent-greyed'
                  }`}
                >
                  <div
                    className={`submenu-dropdown-item ${
                      isEnabled ? '' : 'item-greyed'
                    }`}
                    onClick={item.action}
                  >
                    {item.label}
                  </div>
                  <DropdownItem divider />
                </div>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }

  renderPlainButton = (button, index) => {
    return (
      <div key={index} className="submenu-button" onClick={button.action}>
        <span>
          {this.state.actionTypes[button.actionType]} {button.name}
        </span>
      </div>
    )
  }

  //props => menu items => {name:'text name', actionCall: 'function to call'}, action buttons:{name: 'button name', actionCall:{funcion to call on click}}
  render() {
    return (
      <div className="submenu">
        <div
          className={
            this.props.className
              ? `submenu-items ${this.props.className}`
              : 'submenu-items'
          }
        >
          {_.map(this.props.items, (item, index) => {
            if (index === 0) {
              return (
                <div
                  key={index}
                  onClick={item.action}
                  className={`submenu-item submenu-item-first ${
                    item.selected ? 'submenu-item-active' : ''
                  }`}
                >
                  {item.name}{' '}
                  {item.count && (
                    <div className="menu-count">{this.props.count}</div>
                  )}
                </div>
              )
            } else {
              return (
                <div
                  key={index}
                  onClick={item.action}
                  className={`submenu-item ${
                    item.selected ? 'submenu-item-active' : ''
                  }`}
                >
                  {item.name}
                </div>
              )
            }
          })}
          {_.map(this.props.buttons, (button, index) => {
            if (button.isDropdown) {
              return this.renderDropDownButton(button, index)
            } else {
              return this.renderPlainButton(button, index)
            }
          })}
        </div>
      </div>
    )
  }
}

export default Submenu
