import _ from 'lodash';
import * as AuthApi from '../../apis/AuthApi';
import * as UsersApi from '../../apis/UsersApi';
import { setExpiry } from './axiosInterceptors';
import { errorHandler } from '../../utils/errorHandler';
export const NAMESPACE = 'AUTH';

export const START_LOGIN = `${NAMESPACE}__START_LOGIN`;
export const SUCCESSFUL_LOGIN = `${NAMESPACE}__SUCCESSFUL_LOGIN`;
export const FAILED_LOGIN = `${NAMESPACE}__FAILED_LOGIN`;

export const UPDATE_PATH = `${NAMESPACE}__UPDATE_PATH`;
export const CLEAR_ERRORS = `${NAMESPACE}__CLEAR_ERRORS`;
export const CLEAR_MSGS = `${NAMESPACE}__CLEAR_MSGS`;

export const startLogin = () => ({ type: START_LOGIN });
export const successfulLogin = (auth) => ({ type: SUCCESSFUL_LOGIN, auth });
export const failedLogin = (error) => ({ type: FAILED_LOGIN, error });

export const START_LOGOUT = `${NAMESPACE}__START_LOGOUT`;
export const SUCCESSFUL_LOGOUT = `${NAMESPACE}__SUCCESSFUL_LOGOUT`;
export const FAILED_LOGOUT = `${NAMESPACE}__FAILED_LOGOUT`;

export const CLEAR_JUST_LOGGED_IN = `${NAMESPACE}__CLEAR_JUST_LOGGED_IN`;

export const startLogout = () => ({ type: START_LOGOUT });
export const successfulLogout = (news) => ({ type: SUCCESSFUL_LOGOUT, news });
export const failedLogout = (error) => ({ type: FAILED_LOGOUT, error });

export const SUCCESSFUL_FETCH = `${NAMESPACE}__SUCCESSFUL_FETCH`;
export const FAILED_FETCH = `${NAMESPACE}__FAILED_FETCH`;

export const successfulFetch = (data) => ({type:SUCCESSFUL_FETCH, data});
export const failedFetch = (error) => ({type:FAILED_FETCH, error});

export const SUCCESSFUL_RESET = `${NAMESPACE}__SUCCESSFUL_RESET`;
export const FAILED_RESET = `${NAMESPACE}__FAILED_RESET`;

export const successfulReset = (data) => ({type:SUCCESSFUL_RESET, data});
export const failedReset = (error) => ({type:FAILED_RESET, error});

export const SUCCESSFUL_REQUEST = `${NAMESPACE}__SUCCESSFUL_REQUEST`;
export const FAILED_REQUEST = `${NAMESPACE}__FAILED_REQUEST`;

export const successfulRequestForgot = (data) => ({type: SUCCESSFUL_REQUEST, data});
export const failedRequestForgot = (error) => ({type:FAILED_REQUEST, error});

export const SUCCESSFUL_TOKEN = `${NAMESPACE}__SUCCESSFUL_TOKEN`;
export const FAILED_TOKEN = `${NAMESPACE}__FAILED_TOKEN`;

export const successfulToken = (data) => ({type: SUCCESSFUL_TOKEN, data});
export const failedToken = (error) => ({type:FAILED_TOKEN, error});

export const SUCCESSFUL_UPDATE = `${NAMESPACE}__SUCCESSFUL_UPDATE`;
export const FAILED_UPDATE = `${NAMESPACE}__FAILED_UPDATE`;

export const successfulUpdate = (data) => ({type:SUCCESSFUL_UPDATE, data});
export const failedUpdate = (error) => ({type:FAILED_UPDATE, error});

export const SUCCESSFUL_SETUP = `${NAMESPACE}__SUCCESSFUL_SETUP`;
export const FAILED_SETUP = `${NAMESPACE}__FAILED_SETUP`;

export const successfulSetup = (data) => ({type:SUCCESSFUL_SETUP, data});
export const failedSetup = (error) => ({type:FAILED_SETUP, error});

export const updatePath = (path) => ({type:UPDATE_PATH, path});
export const clearErrors = () => ({type: CLEAR_ERRORS});
export const clearJustLoggedIn = () => ({type:CLEAR_JUST_LOGGED_IN});

export function login(auth_data, callback) {
  return async (dispatch) => {
    dispatch(startLogin());
    try {
      const auth = await AuthApi.login(auth_data);
      auth.expiry = setExpiry();
      if(callback){callback()}
      dispatch(successfulLogin({auth}));
    } catch (err){
      dispatch(failedLogin(errorHandler(err)));
    }
  };
}

export function firstLogin(auth_data, callback){
  return async (dispatch) => {
    try {
      const auth = await AuthApi.firstSignIn(auth_data);
      if(callback){callback(true)}
      dispatch(successfulSetup({auth}));
    } catch (err){
      dispatch(failedSetup(errorHandler(err)));
    }
  }
}

export function logout() {
  return async (dispatch) => {
    dispatch(startLogout());
    try {
      const res = await AuthApi.logout();
      dispatch(successfulLogout());
    } catch (err) {
      dispatch(failedLogout(errorHandler(err)));
    }
  };
}

export function getUserDetails(id, callback){
  return async (dispatch) => {
    try {
      const res = await AuthApi.getDetails(id);
      if(callback){callback()}
      dispatch(successfulFetch(res));
    } catch (err) {
      if(callback){callback()}
      dispatch(failedFetch(errorHandler(err)));
    }
  };
}

export function getTokenData(token, callback){
  return async (dispatch) => {
    try {
      const res = await AuthApi.getTokenInfo(token);
      if(callback){callback(true)}
      dispatch(successfulToken(res));
    } catch (err) {
      if(callback){callback(false)}
      dispatch(failedToken(errorHandler(err)));
    }
  };
}

export function forgotPassword(data, callback){
  return async (dispatch) => {
    try {
      const res = await AuthApi.forgotPassword(data);
      if(callback){callback(true)}
      dispatch(successfulRequestForgot(res));
    } catch (err) {
      if(callback){callback(false)}
      dispatch(failedRequestForgot(errorHandler(err)));
    }
  };
}

export function resetUserPassword(data, callback){
  return async (dispatch) => {
    try {
      const res = await AuthApi.resetPassword(data);
      if(callback){callback()}
      dispatch(successfulReset(res));
    } catch (err) {
      if(callback){callback()}
      dispatch(failedReset(errorHandler(err)));
    }
  };
}

export function updateProfile(data, userId, callback){
  return async (dispatch) => {
    try {
      const res = await UsersApi.updateUser(data, userId);
      if(callback){callback(res)}
      dispatch(successfulUpdate(res));
    } catch (err) {
      if(callback){callback()}
      dispatch(failedUpdate(errorHandler(err)));
    }
  };
}

export function clearErrMsgs(){
  return async (dispatch) => {
    dispatch(clearErrors());
  }
}

export function clearAuthMsgs(){
  return async (dispatch) => {
    dispatch({type:CLEAR_MSGS});
  }
}

export function updateAppPath(path){
  return async (dispatch) => {
    dispatch(updatePath(path));
  };
}

let local_auth;
try {
    local_auth = JSON.parse(localStorage.getItem('GOLDBELL-CMS:USER'));
} catch(e) {
    local_auth = null; // error in the above string (in this case, yes)!
}

export const initialState = {
  auth: local_auth ? local_auth.auth : {},
  isLoggingIn: false,
  isLoggingOut: false,
  loggedIn: (local_auth && local_auth.auth) ? true : false,
  error: undefined,
  token:null,
  isSuper: (local_auth && local_auth.auth && local_auth.auth.admin && (local_auth.auth.admin.role === 'SuperAdmin')) ? true: false
};

export default function authReducer(state = initialState, action) {
  let auth;
  switch (action.type) {
    case START_LOGIN:
      return {
        ...state,
        isLoggingIn: true
      };
    case SUCCESSFUL_LOGIN:
      return {
        ...state,
        isLoggingIn: false,
        loggedIn:true,
        justLoggedIn:true,
        auth: action.auth.auth
      };
    case FAILED_LOGIN:
      return {
        ...state,
        isLoggingIn: false,
        loggedIn:false,
        error: action.error,
        isSuper: false

      };
    case START_LOGOUT:
      return {
        ...state,
        isLoggingOut: true,
        loggedIn:false,
        isSuper: false
      };
    case SUCCESSFUL_UPDATE:
      auth = state.auth;
      auth.user = (action.data && action.data.user);
      return{
        ...state,
        auth,
        msg:'Profile updated successfully.'
      }
    case SUCCESSFUL_FETCH:
      auth = state.auth;
      auth.user = (action.data && action.data.user);
      return{
        ...state,
        auth
      }
    case SUCCESSFUL_SETUP:
      return{
        ...state,
        msg:'Successfully setup account. Please login.'
      }
    case SUCCESSFUL_LOGOUT:
      return {
        ...state,
        isLoggingOut: false,
        loggedIn:false,
        auth:{},
        isSuper: false
      };
    case SUCCESSFUL_RESET:
      return{
        ...state,
        msg:'Password reset successfully. Please login.'
      }
    case SUCCESSFUL_REQUEST:
      return{
        ...state,
        msg:'Reset password request success. Please check your email.'
      }
    case SUCCESSFUL_TOKEN:
      return{
        ...state,
        token:action.data
      }
    case FAILED_TOKEN:
    case FAILED_REQUEST:
    case FAILED_RESET:
    case FAILED_FETCH:
    case FAILED_SETUP:
    case FAILED_UPDATE:
      return{
        ...state,
        error:action.error
      }
    case FAILED_LOGOUT:
      return {
        ...state,
        isLoggingOut: false,
        loggedIn:false,
        auth:{},
        error: action.error,
        isSuper: false
      };
    case UPDATE_PATH:
      return{
        ...state,
        path:action.path
      }
    case CLEAR_ERRORS:
      return{
        ...state,
        error:null
      }
    case CLEAR_MSGS:
      return{
        ...state,
        msg:null
      }
    case CLEAR_JUST_LOGGED_IN:
      return{
        ...state,
        justLoggedIn:false
      }
    default:
      return state;
  }
}
