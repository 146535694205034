import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { forgotPassword, clearErrMsgs, clearAuthMsgs } from '../../redux/AuthRedux/AuthRedux';
import { GlobalStyle } from '../../global-style';
import {
  LoginDiv, InnerDiv, LoginForm,
  Label, Input, Button, Text,
  TitleOne, TitleTwo, ForgotLink,
  WebsiteLink, PlainText, LoginFooter,
  FooterImgLeft, FooterImgRight, Error, ErrorMsg, Message
 } from './style';
import { Icon } from 'react-icons-kit';
import {close} from 'react-icons-kit/fa/close';
import footerLogo1 from '../../assets/footer-logo.png';
import footerLogo2 from '../../assets/footer-logo2.png';

export class ForgotPassword extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      email:''
    }
  }

  componentDidMount(){
    const { auth } = this.props;
    if(auth.loggedIn){
      this.props.history.push('/invoices');
    }
  }

  componentWillUnmount(){
    this.props.actions.clearErrMsgs();
    this.props.actions.clearAuthMsgs();
  }

  clearErrors = () => {
    this.props.actions.clearErrMsgs();
  }

  clearMsgs = () => {
    this.props.actions.clearAuthMsgs();
  }

  onChangeHandler = (e) => {
    this.setState({email:e.target.value});
  }

  render(){
    const {handleSubmit} = this.props;
    const {fields} = this.state;
    return(
      <div id="forgot-password-container">
      <LoginDiv>
      <GlobalStyle />
        <InnerDiv>
          <Formik
          initialValues={{ email: "" }}
          validate={values => {
            let errors = {};
            // REGEX
            let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            // VALIDATION
            if(!values.email){
              errors.email = "Please enter an email.";
            }
            else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))){
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={values => {
            this.props.actions.forgotPassword(values);
          }}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return(
            <LoginForm onSubmit={handleSubmit}>
            <TitleOne>Welcome to</TitleOne>
            <TitleTwo>GOLDBELL</TitleTwo>
            {this.props.auth.msg &&
              <Message id="reset-password-message">
                <span className="close" onClick={this.clearMsgs} style={{ color: '#3c763d' }}><Icon icon={close} /></span>
                <span className="message">{this.props.auth.msg}</span>
              </Message>}
              {this.props.auth.error && <Error id="forgot-password-error" color="red"><span onClick={this.clearErrors} style={{ color: '#a94442' }}><Icon icon={close} /></span><ErrorMsg>{this.props.auth.error}</ErrorMsg></Error>}
              <Label>
                <Input
                  id="forgot-password-email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  border={touched.email && errors.email && "1px solid red"}
                  type="text"
                  name="email"
                  placeholder="Email Address"
                />
                {touched.email &&
                  errors.email && <Text color="red">{errors.email}</Text>}
              </Label>
              <Button id="forgot-password-submit" type="submit">Forgot Password</Button>
              <ForgotLink id="forgot-password-login-link"><Link to="/login">Back to login</Link></ForgotLink>
              <PlainText>Visit our website <WebsiteLink><a id="forgot-password-website-link" href="http://www.goldbellgroup.com">www.goldbellgroup.com</a></WebsiteLink></PlainText>
            </LoginForm>
          )}}
        />
        </InnerDiv>
      </LoginDiv>
      <LoginFooter>
        <FooterImgRight src={footerLogo1} />
        <FooterImgLeft src={footerLogo2} />
      </LoginFooter>
      </div>
    );
  }
}

function mapStateToProps({auth}){
  return({
    auth: auth
  });
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ forgotPassword, clearErrMsgs, clearAuthMsgs }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
