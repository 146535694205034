import axios from 'axios';
import { axiosInstance } from '../../redux/AuthRedux/axiosInterceptors';
import moment from 'moment';

const baseUrl = `${process.env.REACT_APP_CMS_API_URL}invoices`;
export const REFRESH_FAILURE = 'REFRESH_FAILURE';

export async function getInvoices(filterStr) {
  const response = await axiosInstance.get(`${baseUrl}${filterStr ? filterStr : ''}`);
  return response.data;
}

/*export async function downloadInvoice(link){
  const response = await axiosInstance.get(link);
  return response.data;
}*/

export async function downloadInvoice(invoiceId){
  const url = `${baseUrl}/${invoiceId}/download`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function downloadS3(link, fileName, s3CustomerKey, s3CustomerAlgorithm, s3customerKeyMD5){
  const response = await axios.get(link, {
    headers:{
      'x-amz-server-side-encryption-customer-key' : s3CustomerKey,
      'x-amz-server-side-encryption-customer-algorithm':s3CustomerAlgorithm,
      'x-amz-server-side-encryption-customer-key-MD5':s3customerKeyMD5
    },
    responseType:'blob',
  });
  downloadFile(fileName, URL.createObjectURL(response.data));
  return 'success!';
}

export async function downloadCSVS3(link, s3CustomerKey, s3CustomerAlgorithm, s3customerKeyMD5){
  const response = await axios.get(link, {
    headers:{
    'x-amz-server-side-encryption-customer-key' : s3CustomerKey,
    'x-amz-server-side-encryption-customer-algorithm':s3CustomerAlgorithm,
    'x-amz-server-side-encryption-customer-key-MD5':s3customerKeyMD5
  }});
  var data = '"File Name","Status","Cause"';
  response.data.forEach((file) => {
    data += '\r\n';
    data += `"${file.filename}","${file.status}","${file.error ? file.error : ''}"`;
  });
  downloadFile(`StatusLog_${moment().format('DDMMYYYY')}.csv`, 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data));
  return 'success!';
}

function downloadFile(fileName, urlData) {

  var aLink = document.createElement('a');
  aLink.download = fileName;
  aLink.href = urlData;

  var event = new MouseEvent('click');
  aLink.dispatchEvent(event);
}



function openDocInNewTab(file, fileName, fileURL, data){
  if(window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file, fileName);
  }
  else if(navigator.userAgent.match('CriOS')){
    var reader = new FileReader();
    var out = new Blob([data], {type: 'application/pdf'});
    reader.onload = function(e){
      window.location.href = reader.result;
    }
    reader.readAsDataURL(out);
  }
  else{
    window.open(fileURL);
  }
}
