import styled from 'styled-components';

export const UserFormContainer = styled.div`
  width:100%;
  margin: 40px;
`;

export const InnerDiv = styled.div`
  width: 100%;
`;


export const Label = styled.label`
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: bold;
  color: #2d2926;
  width:100%;
  padding-top:15px;
  padding-left:28px;
`;

export const Option = styled.div`
  width:100%;
  padding-left:28px;
  & span{
    font-family: VistaSansOT-Reg;
    font-size: 14px;
    color: #2d2926;
  }
`;

export const LinkText = styled.div`
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: bold;
  float:right;
  margin-right:15px;
  color: #fcb72d;
  cursor:pointer;
  margin-top:0px;
`;

export const Note = styled.div`
  font-family: VistaSansOT-Reg;
  font-size: ${props => props.size === 'small' ? '10px' : '14px'};
  color: #2d2926;
  padding-top:${props => props.size === 'small' ? '0px' : '5px'};
  visibility:${props => props.visible ? 'visible' : 'hidden'};
  & .note{
    float:left;
  }
  & .icon{
    float:left;
  }
`;

export const Title = styled.div`
font-family: VistaSansOT;
font-size: 30px;
font-weight: bold;
padding-bottom:36px;
width:100%;
padding-top:20px;
background-color:#fafafa;
`;

export const Username = styled.div`
  margin:auto;
  width:1023px;
`;

export const SubTitle = styled.div`
  font-family: VistaSansOT;
  font-size: 20px;
  font-weight: bold;
  padding-bottom:36px;
  padding-top:50px;
`;

export const SubSection = styled.div`
width:100%;
height:auto;
`;

export const OptDiv = styled.div`
  width: 300px;
  height: 112px;
  border-radius: 4px;
  border: solid 1px #dcdcdc;
  background-color: #fafafa;
  display:inline-block;
  margin-right: 15px;
  margin-bottom:15px;
`;

export const AgreementNoDiv = styled.div`
 border-bottom: solid 1px #dcdcdc;
 width:130px;
 font-family: VistaSansOT-Reg;
  font-size: 14px;
  color: #2d2926;
  padding-bottom:5px;
`;

export const TableParent = styled.div`
width:100%;
background-color:#fafafa;
padding-bottom:20px;
`;


export const Table = styled.table`
width:1023px;
margin:auto;
`;

export const GreyBand = styled.div`

`;

export const TableLabel = styled.td`
  font-family: VistaSansOT;
  font-size: 12px;
  font-weight: bold;
  color: #2d2926;
  width:110px;
  margin-right:16px;
`;

export const TableValue = styled.td`
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  color:${props => props.isLink ? '#4a90e2' : 'inherit'}
  cursor:${props => props.isLink ? 'pointer' : 'inherit'}
`;

export const BackLink = styled.div`
font-family: VistaSansOT-Reg;
font-size: 14px;
color: #2d2926;
padding-bottom:50px;
cursor:pointer;
width:1023px;
margin:auto;
margin-top:17px;
`;
