import React from 'react'
import { Link } from 'react-router-dom'


export default function () {
  return (
    <div className="error-container">
      <h1>404 Page Not Found</h1>
      <Link to="/">Home</Link>
    </div>
  )
}
