import styled from 'styled-components';

export const HeaderDiv = styled.div`
  width:100%;
`;

export const HeaderBanner = styled.div`
  width:1023px;
  height:123px;
  margin:auto;
  background-color: #edefee;

`;

export const HeaderBannerContainer = styled.div`
  width:100%;
  background-color: #edefee;
`;

export const IconImg = styled.img`
  width:47px;
  position:relative;
  left:41px;
`;

export const BannerImg = styled.img`
  width:870px;
  position:relative;
  left:105px;
`;

export const HeaderMenu = styled.div`
  width:1023px;
  height:55px;
  margin:auto;
  background-color: #ffffff;
  text-align:right;
`;

export const HeaderMenuContainer = styled.div`
  width:100%;
  background-color:#ffffff;
  border-bottom: solid 1px #dcdcdc;
`;

export const HeaderItem = styled.div`
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.selected ? "#000000" : "#9b9b9b"};
  float:right;
  padding:20px;
  cursor:pointer;
  & a{
    color:inherit;
    text-decoration:none;
  }
`;

export const UserImg = styled.img`
  width:25px;
  height:25px;
  position:relative;
  right:8px;
`;

export const HeaderUserItem = styled.div`
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: bold;
  color: #f5a623;
  float:right;
  padding:0px;
  width:195px;
  text-align:center;
  border-left: solid 1px #dcdcdc;
  & a{
    color:inherit;
  }
  & a:hover{
    text-decoration:none;
  }
`;
