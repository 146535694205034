import React from 'react';
import _ from 'lodash';
import { GlobalStyle, ContentContainer, WidthContent } from '../global-style';
import {
  UserFormContainer,
  InnerDiv,
  Title,
  SubTitle,
  SubSection,
  SubColumn,
  Label,
  SmallLabel,
  SmallValue,
  Username
} from './style';
import Submenu from '../common/Submenu/Submenu'
import { Icon } from 'react-icons-kit';
import {check} from 'react-icons-kit/fa/check'
import {chevronLeft} from 'react-icons-kit/fa/chevronLeft';

export class ContactUs extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      menuItems: [
        {
          name: 'GBCR',
          action: (e) => {},
          selected: true
        }
      ],
    }
  }

  render(){
    return(
      <div id="contactus-container">
      <ContentContainer>
      <GlobalStyle />
          <Title><Username>Contact Us</Username></Title>
          <Submenu items={this.state.menuItems} />
            <InnerDiv>
              {/*<TableParent>
              <Table>
                <tbody>
                <tr>
                  <TableLabel>EMAIL</TableLabel>
                  <TableValue id="profile-detail-email"></TableValue>
                </tr>
                <tr>
                  <TableLabel>PHONE NUMBER</TableLabel>
                  <TableValue id="profile-detail-phone"></TableValue>
                </tr>
                </tbody>
              </Table>
              </TableParent>*/}
              <WidthContent>

            <SubColumn>
              <SubTitle first={true}>For all enquiries regarding payment, please contact</SubTitle>
              <SubSection first={true}>
                <Label>Sharon Woon</Label>
                <SmallLabel>PHONE</SmallLabel>
                <SmallValue>64942964</SmallValue>
                <SmallLabel>EMAIL</SmallLabel>
                <SmallValue>sharonwoonsg@goldbellcorp.com</SmallValue>
              </SubSection>
              <SubSection>
                <Label>Carmen Chin</Label>
                <SmallLabel>PHONE</SmallLabel>
                <SmallValue>64942834</SmallValue>
                <SmallLabel>EMAIL</SmallLabel>
                <SmallValue>carmenchinkv@goldbellcorp.com</SmallValue>
              </SubSection>
              <SubTitle>For all enquiries regarding invoices, please contact</SubTitle>
              <SubSection first={true}>
                <Label>CTR admin</Label>
                <SmallLabel>PHONE</SmallLabel>
                <SmallValue>68386300</SmallValue>
                <SmallLabel>EMAIL</SmallLabel>
                <SmallValue>ctradmin@goldbellcorp.com</SmallValue>
              </SubSection>
            </SubColumn>
            <SubColumn>
              <SubTitle first={true}>Payment Info</SubTitle>
              <SubSection first={true}>
                <Label>For payment by GIRO, our bank details as below:<br/>DBS CORPORATE BANKING</Label>
                <SmallLabel>ACCOUNT NUMBER</SmallLabel>
                <SmallValue>003-905227-8</SmallValue>
              </SubSection>
              <SubTitle>Payment Instruction</SubTitle>
              <SmallValue>Please make all cheques payable to Goldbell Car Rental Pte Ltd. No receipts will be issued for Cheque Payments.<br/> Please send payments to 8 Tuas Ave 18 Singapore 638892. <br/><br/>When making payments, please quote our invoice numbers.</SmallValue>
            </SubColumn>
            </WidthContent>
          </InnerDiv>
      </ContentContainer>
      </div>
    );
  }
}

export default ContactUs;
