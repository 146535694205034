import './Header.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { GlobalStyle } from '../../global-style'
import {
  HeaderDiv,
  HeaderBanner,
  HeaderMenu,
  IconImg,
  BannerImg,
  HeaderItem,
  HeaderUserItem,
  HeaderBannerContainer,
  HeaderMenuContainer,
  UserImg
} from './style'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import _ from 'lodash'
import { logout } from '../../redux/AuthRedux/AuthRedux'
import goldbellIcon from '../../assets/goldbell-icon.png'
import bannerImg from '../../assets/smile-girl-2x.png'
import userImg from '../../assets/user.png'

export class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      headerItems: [''],
      dropdownOpen: false,
      path: 'invoices'
    }
  }

  componentDidMount = () => {
    let type = _.size(window.location.pathname.match(/\/(invoices)/))
      ? 'invoices'
      : null
    if (!type) {
      type = _.size(window.location.pathname.match(/\/(users)/))
        ? 'users'
        : null
    }
    if (!type) {
      type = _.size(window.location.pathname.match(/\/(companies)/))
        ? 'companies'
        : null
    }
    if (!type) {
      type = _.size(window.location.pathname.match(/\/(auditlogs)/))
        ? 'auditlogs'
        : null
    }
    if (!type) {
      type = _.size(window.location.pathname.match(/\/(contactus)/))
        ? 'contactus'
        : null
    }
    this.setState({ path: type })
  }

  componentWillReceiveProps = () => {
    let type = _.size(window.location.pathname.match(/\/(invoices)/))
      ? 'invoices'
      : null
    if (!type) {
      type = _.size(window.location.pathname.match(/\/(users)/))
        ? 'users'
        : null
    }
    if (!type) {
      type = _.size(window.location.pathname.match(/\/(companies)/))
        ? 'companies'
        : null
    }
    if (!type) {
      type = _.size(window.location.pathname.match(/\/(auditlogs)/))
        ? 'auditlogs'
        : null
    }
    if (!type) {
      type = _.size(window.location.pathname.match(/\/(contactus)/))
        ? 'contactus'
        : null
    }
    this.setState({ path: type })
  }

  onNavigate = path => {
    this.setState({ path })
  }

  onClickLogout = () => {
    //  this.toggle();
    this.props.actions.logout()
  }

  toggle = () => {
    if (this.state.dropdownOpen) {
      this.setState({ dropdownOpen: false })
    } else {
      this.setState({ dropdownOpen: true })
    }
  }

  setPath = path => {
    this.setState({ path })
  }

  render() {
    const { path } = this.state
    if (!this.props.loggedIn) {
      return null
    }
    const { user } = this.props.auth.auth
    const username = user.name
      ? _.truncate(user.name, { length: 15 })
      : _.truncate(`${user.firstName} ${user.lastName}`, { length: 15 })
    const isAdmin =
      user && (user.role === 'Admin' || user.role === 'SuperAdmin')
    return (
      <HeaderDiv id='header' className='header'>
        <GlobalStyle />
        <HeaderBannerContainer>
          <HeaderBanner>
            <IconImg src={goldbellIcon} />
            <BannerImg src={bannerImg} />
            {/*
            goldbell Icon
            goldbell bannerImg
            */}
          </HeaderBanner>
        </HeaderBannerContainer>
        <HeaderMenuContainer>
          <HeaderMenu>
            {user && (
              <HeaderUserItem>
                <Dropdown
                  id='header-profile-dropdown'
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggle}
                >
                  <DropdownToggle
                    tag='div'
                    onClick={this.toggle}
                    data-toggle='dropdown'
                    aria-expanded={this.state.dropdownOpen}
                  >
                    <UserImg src={userImg} />
                    {username}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <b className='notch' />
                    <div className='dropdown-item'>
                      <Link onClick={this.toggle} to='/profile'>My Profile</Link>
                    </div>
                    <DropdownItem divider />
                    <div className='dropdown-item' onClick={this.onClickLogout}>
                      Logout
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </HeaderUserItem>
            )}
            <Link to="/contactus">
            <HeaderItem
              id='header-contact-us'
              selected={path == 'contactus' ? true : false}
              onClick={() => {
                this.setPath('contactus')
              }}
            >
              Contact Us
            </HeaderItem>
            </Link>
            {isAdmin && (
              <Link to="/auditlogs">
              <HeaderItem
                id='header-audit-logs'
                selected={path == 'auditlogs' ? true : false}
                onClick={() => {
                  this.setPath('auditlogs')
                }}
              >
                Audit log
              </HeaderItem>
              </Link>
            )}
            {isAdmin && (
              <Link to="/companies">
              <HeaderItem
                id='header-company'
                selected={path == 'companies' ? true : false}
                onClick={() => {
                  this.setPath('companies')
                }}
              >
                Company
              </HeaderItem>
              </Link>
            )}
            {isAdmin && (
              <Link to="/users">
              <HeaderItem
                id='header-users'
                selected={path == 'users' ? true : false}
                onClick={() => {
                  this.setPath('users')
                }}
              >
                User
              </HeaderItem>
              </Link>
            )}
            <Link to="/invoices">
            <HeaderItem
              id='header-invoices'
              selected={path == 'invoices' ? true : false}
              onClick={() => {
                this.setPath('invoices')
              }}
            >
              Invoice
            </HeaderItem>
            </Link>
          </HeaderMenu>
        </HeaderMenuContainer>
      </HeaderDiv>
    )
  }
}

function mapStateToProps({ auth }) {
  return {
    auth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ logout }, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
