import _ from 'lodash';

export function formatInvoices(data){
  const formatted_invoices = _.map(data, (invoice) => {
    let new_invoice = invoice;
    //invoice number truncate at 8
    //agreementNo 14 truncate
    //cpmpany name truncate at 38;
    new_invoice.invoiceNumber = invoice.no;
    new_invoice.invoiceNumberTip = invoice.no;
    new_invoice.agreementNo = invoice.agreement ? invoice.agreement.code : '';
    new_invoice.agreementNoTip = invoice.agreement ? invoice.agreement.code : '';
    new_invoice.customer = invoice.company ? _.truncate(invoice.company.name, {length:38}) : '';
    new_invoice.customerTip = invoice.company ? invoice.company.name : '';
    new_invoice.download = `${process.env.REACT_APP_CMS_API_URL}invoices/download?ids=${invoice.id}`;
    return new_invoice;
  });
  return formatted_invoices;
}

export function formatSOAs(data){
  //soa 30 characters
  //company name at 39
  _.forEach(data,(item) => {
    let new_item = item;
    new_item.customer = item.company.name;
    new_item.download = `${process.env.REACT_APP_CMS_API_URL}account-statements/download?ids=${item.code}`;
    return new_item;
  });
  return data;
}
