import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { forgotPassword, clearErrMsgs, clearAuthMsgs, resetUserPassword } from '../../redux/AuthRedux/AuthRedux';
import { GlobalStyle } from '../../global-style';
import {
  LoginDiv, InnerDiv, LoginForm,
  Label, Input, Button, Text,
  TitleOne, TitleTwo, ForgotLink,
  WebsiteLink, PlainText, LoginFooter,
  FooterImgLeft, FooterImgRight, Error,
  Message
 } from './style';
import { Icon } from 'react-icons-kit';
import {close} from 'react-icons-kit/fa/close';
import footerLogo1 from '../../assets/footer-logo.png';
import footerLogo2 from '../../assets/footer-logo2.png';
import ReactTooltip from 'react-tooltip'
import {checkCircleO} from 'react-icons-kit/fa/checkCircleO'
import {checkCircle} from 'react-icons-kit/fa/checkCircle'

export class ResetPassword extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      token:null,
      tokenError:false,
      email:null
    }
  }

  componentDidMount = () => {
    const { auth } = this.props;
    if(auth.loggedIn){
      this.props.history.push('/invoices');
    }
    const token = window.location.search.match(/^\?email=(.*)&token=(.*)$/);
    if(token && token.length>2){
      this.setState({token:token[2], email:token[1]});
    }
    else{
      this.setState({tokenError:true});
    }
  }

  componentWillUnmount(){
    this.props.actions.clearErrMsgs();
    this.props.actions.clearAuthMsgs();
  }

  clearErrors = () => {
    this.props.actions.clearErrMsgs();
  }

  clearMsgs = () => {
    this.props.actions.clearAuthMsgs();
  }

  onChangeHandler = (e) => {
    this.setState({email:e.target.value});
  }

  handleSubmit = (values) => {
    this.props.actions.clearErrMsgs();
    this.props.actions.clearAuthMsgs();
    const {email,token} = this.state;
    const data = {
      email,
      resetToken:decodeURIComponent(token),
      password:values.password,
      confirmPassword:values.confirmPassword
    }
    this.props.actions.resetUserPassword(data);
  }

  render(){
    const {handleSubmit} = this.props;
    const {fields} = this.state;
    return(
      <div id="reset-password-container">
      <LoginDiv>
      <GlobalStyle />
        <InnerDiv>
          <Formik
          initialValues={{ password: "", confirmPassword:"" }}
          validate={values => {
            let errors = {};
            // REGEX
            let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            // VALIDATION
            if(!values.password){
              errors.password = "Password is required";
            }
            if(!values.confirmPassword){
              errors.confirmPassword = "Confirm password is required";
            }
            if((values.password && values.confirmPassword && !(values.password === values.confirmPassword))){
              errors.password = "Passwords do not match.";
              errors.confirmPassword = "Passwords do not match.";
            }
            return errors;
          }}
          onSubmit={values => {
            this.handleSubmit(values);
          }}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return(
            <LoginForm onSubmit={handleSubmit}>
            <TitleOne>Welcome to</TitleOne>
            <TitleTwo>GOLDBELL</TitleTwo>
            {this.props.auth.msg &&
              <Message id="reset-password-message">
                <span className="close" onClick={this.clearMsgs} style={{ color: '#3c763d' }}><Icon icon={close} /></span>
                <span className="message">{this.props.auth.msg}</span>
              </Message>}
              {this.props.auth.error && <Error id="reset-password-error" color="red">{this.props.auth.error} <span onClick={this.clearErrors} style={{ color: '#a94442' }}><Icon icon={close} /></span></Error>}
              <Label>
                <Input
                  id="reset-password-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  border={touched.password && errors.password && "1px solid red"}
                  type="password"
                  name="password"
                  placeholder="Password"
                  data-tip="info"
                  data-event="focus"
                  data-event-off="blur"
                  data-for="password-tooltip"
                />
                <ReactTooltip id="password-tooltip" place="left" type="dark" effect="float">
                {((values.password).match(/^(.{6,})$/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 6 characters<br/>
                {((values.password).match(/([A-Z]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 upper case letter<br/>
                {((values.password).match(/([0-9]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 number
                </ReactTooltip>
                {touched.password &&
                  errors.password && <Text color="red">{errors.password}</Text>}
              </Label>
              <Label>
                <Input
                  id="reset-password-confirm-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  border={touched.confirmPassword && errors.confirmPassword && "1px solid red"}
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  data-tip="info"
                  data-event="focus"
                  data-event-off="blur"
                  data-for="confirmPassword-tooltip"
                />
                {touched.confirmPassword &&
                  errors.confirmPassword && <Text color="red">{errors.confirmPassword}</Text>}
                  <ReactTooltip id="confirmPassword-tooltip" place="left" type="dark" effect="float">
                  {((values.confirmPassword).match(/^(.{6,})$/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 6 characters<br/>
                  {((values.confirmPassword).match(/([A-Z]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 upper case letter<br/>
                  {((values.confirmPassword).match(/([0-9]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 number
                  </ReactTooltip>
              </Label>
              <Button id="reset-password-submit-button" type="submit">Set Password</Button>
              <ForgotLink><Link id="reset-password-login-link" to="/login">Login</Link></ForgotLink>
              <PlainText>Visit our website <WebsiteLink><a id="reset-password-website-link" href="http://www.goldbellgroup.com">www.goldbellgroup.com</a></WebsiteLink></PlainText>
            </LoginForm>
          )}}
        />
        </InnerDiv>
      </LoginDiv>
      <LoginFooter>
        <FooterImgRight src={footerLogo1} />
        <FooterImgLeft src={footerLogo2} />
      </LoginFooter>
      </div>
    );
  }
}

function mapStateToProps({auth}){
  return({
    auth: auth
  });
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ forgotPassword, clearErrMsgs, resetUserPassword, clearAuthMsgs }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
