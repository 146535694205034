import React, { useEffect } from 'react'
import AgreementForm from '../AgreementForm'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getUsersByCompany } from '../../redux/CompaniesRedux/CompanyDetailRedux'
import {
  createAgreement,
  getAgreement,
  updateAgreement
} from '../../redux/AgreementsRedux/AgreementsRedux'
import './styles.scss'

const NewAgreement = ({
  history,
  error,
  companyId,
  clearError,
  companyUsers,
  getUsersByCompany,
  editAgreement,
  agreementId,
  agreement,
  getAgreement,
  updateAgreement
}) => {
  const _handleCancel = () => history.push(`/companies/${companyId}`)
  const _handleSubmit = values => {
    const data = {
      code: values.code,
      users: values.users.map(item => parseInt(item.value))
    }

    updateAgreement(agreementId, data, () => {
      setTimeout(() => history.push(`/companies/${companyId}`), 1000)
    })
  }

  useEffect(() => {
    getUsersByCompany(companyId, '?perPage=5000')
    getAgreement(agreementId)
  }, [companyId, agreementId])

  const parsedUsers =
    companyUsers &&
    companyUsers.users.map(item => ({
      value: item.id,
      label: item.name
    }))

  if (!agreement || agreement.agreement.id !== agreementId) {
    return null
  }

  const parsedAgreement = {
    code: agreement.agreement.code,
    id: agreement.agreement.id,
    users: agreement.agreement.users.map(item => ({
      value: item.id,
      label: item.name
    }))
  }

  return (
    <div id='edit-agreement'>
      <AgreementForm
        error={error}
        userList={parsedUsers || []}
        onSubmit={_handleSubmit}
        onCancel={_handleCancel}
        clearError={clearError}
        initValues={parsedAgreement}
        title='Edit Agreement'
      />
    </div>
  )
}

export default connect(
  (state, props) => ({
    companyId: props.match.params.id,
    agreementId: parseInt(props.match.params.agreementId, 10),
    companyUsers: state.companyDetail.users,
    agreement: state.agreements.agreement,
    error: state.agreements.error
  }),
  dispatch => ({
    getUsersByCompany: bindActionCreators(getUsersByCompany, dispatch),
    createAgreement: bindActionCreators(createAgreement, dispatch),
    getAgreement: bindActionCreators(getAgreement, dispatch),
    updateAgreement: bindActionCreators(updateAgreement, dispatch)
  })
)(NewAgreement)
