import _ from 'lodash'
import * as CompaniesApi from '../../apis/CompaniesApi'
import { errorHandler } from '../../utils/errorHandler'
import * as UsersApi from "../../apis/UsersApi";

export const NAMESPACE = 'COMPANIES'

export const CLEAR_ERROR_MSGS = 'CLEAR_ERROR_MSGS'
export const CLEAR_MSGS = 'CLEAR_MSGS'

export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const UPDATE_FILTER = `${NAMESPACE}__UPDATE_FILTER`;

export const START_FETCH = `${NAMESPACE}__START_FETCH`
export const SUCCESSFUL_FETCH = `${NAMESPACE}__SUCCESSFUL_FETCH`
export const SUCCESSFUL_FETCH_ID = `${NAMESPACE}__SUCCESSFUL_FETCH_ID`
export const FAILED_FETCH = `${NAMESPACE}__FAILED_FETCH`

export const startFetch = () => ({ type: START_FETCH })
export const successfulFetch = companies => ({
  type: SUCCESSFUL_FETCH,
  companies
})
export const successfulFetchById = company => ({
  type: SUCCESSFUL_FETCH_ID,
  company
})
export const failedFetch = error => ({ type: FAILED_FETCH, error })

export const START_CREATE = `${NAMESPACE}__START_CREATE`
export const SUCCESSFUL_CREATE = `${NAMESPACE}__SUCCESSFUL_CREATE`
export const FAILED_CREATE = `${NAMESPACE}__FAILED_CREATE`

export const startCreate = () => ({ type: START_CREATE })
export const successfulCreate = companies => ({
  type: SUCCESSFUL_CREATE,
  companies
})
export const failedCreate = error => ({ type: FAILED_CREATE, error })

export const START_UPDATE = `${NAMESPACE}__START_UPDATE`
export const SUCCESSFUL_UPDATE = `${NAMESPACE}__SUCCESSFUL_UPDATE`
export const FAILED_UPDATE = `${NAMESPACE}__FAILED_UPDATE`

export const startUpdate = () => ({ type: START_UPDATE })
export const successfulUpdate = user => ({ type: SUCCESSFUL_UPDATE, user })
export const failedUpdate = error => ({ type: FAILED_UPDATE, error })

export const START_DELETE = `${NAMESPACE}__START_DELETE`
export const SUCCESSFUL_DELETE = `${NAMESPACE}__SUCCESSFUL_DELETE`
export const FAILED_DELETE = `${NAMESPACE}__FAILED_DELETE`

export const startDelete = () => ({ type: START_DELETE })
export const successfulDelete = companies => ({
  type: SUCCESSFUL_DELETE,
  companies
})
export const failedDelete = error => ({ type: FAILED_DELETE, error })

const formatAddress = address => {
  return address
    .replace('null', ' ')
    .replace(/\s+/g, ' ')
    .replace(/^\s+|\s+$/, '')
}

export function updatePageNumber(pageNumber){
  return dispatch => {
    dispatch({
      type:UPDATE_PAGE_NUMBER,
      page:pageNumber
    });
  }
}

export function updateFilter(filter){
  return dispatch => {
    return dispatch({
      type:UPDATE_FILTER,
      filter
    })
  }
}

export function fetchCompanies(filterStr, callback) {
  return async dispatch => {
    dispatch(startFetch())
    if (callback) {
      callback()
    }
    try {
      const companies = await CompaniesApi.getCompanies(filterStr)
      const parsedCompanies = {
        ...companies,
        companies: companies.companies.map(company => ({
          ...company,
          address: formatAddress(
            `${company.unitNo} ${company.streetName} ${company.postCode}`
          )
        }))
      }
      dispatch(successfulFetch(parsedCompanies))
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)))
    }
  }
}

export function fetchCompanyById(id, callback) {
  return async dispatch => {
    dispatch(startFetch())
    try {
      const company = await CompaniesApi.getCompanyById(id)
      const parsedCompany = {
        ...company,
        company: Object.assign(
          {},
          {
            ...company.company,
            address: formatAddress(
              `${company.company.unitNo} ${company.company.streetName} ${
                company.company.postCode
              }`
            )
          }
        )
      }

      if (callback) {
        callback(parsedCompany)
      }
      dispatch(successfulFetchById(parsedCompany))
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)))
    }
  }
}

export function clearErrorMsgs() {
  return async dispatch => {
    dispatch({ type: CLEAR_ERROR_MSGS })
  }
}

export function clearCustMsgs() {
  return async dispatch => {
    dispatch({ type: CLEAR_MSGS })
  }
}

export function createCompany(company, callback) {
  return async dispatch => {
    dispatch({
      type: START_CREATE
    })

    try {
      const data = await CompaniesApi.createCompany(company)

      dispatch({
        type: SUCCESSFUL_CREATE
      })

      callback()
    } catch (res) {
      return dispatch({
        type: FAILED_CREATE,
        error: res.response.data.error
      })
    }
  }
}

export function updateCompany(id, company, callback) {
  return async dispatch => {
    dispatch({
      type: START_UPDATE
    })

    try {
      const data = await CompaniesApi.updateCompany(id, company)

      dispatch({
        type: SUCCESSFUL_UPDATE
      })

      callback(data)
    } catch (res) {
      return dispatch({
        type: FAILED_UPDATE,
        error: res.response.data.error
      })
    }
  }
}

export function downloadStatusLog(url, s3CustomerKey, s3CustomerAlgorithm, s3CustomerKeyMD5){
  return async (dispatch) => {
    try {
      const file = await CompaniesApi.downloadCSVS3(url, s3CustomerKey, s3CustomerAlgorithm, s3CustomerKeyMD5);
      //extract data from invoice and send request to S3
    } catch (err) {
    }
  };
}

export const initialState = {
  companies: [],
  isFetching: false,
  error: undefined,
  page:1,
  filter:''
}

export default function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCH:
    case START_CREATE:
    case START_UPDATE:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      }

    case SUCCESSFUL_FETCH:
      let mapped_companies
      if (action.companies) {
        const format_companies = action.companies.companies
        //mapped_companies = _.mapKeys(format_companies, val => val.id)
        mapped_companies = format_companies.map((company) => company);
      } else {
        const format_companies = { [action.companies.id]: action.companies }
        //mapped_companies = _.mapKeys(format_companies, val => val.id)
        mapped_companies = format_companies.map((company) => company);
      }
      return {
        ...state,
        isFetching: false,
        companies: {
          ...mapped_companies
        },
        meta: action.companies.meta
      }

    case SUCCESSFUL_CREATE:
      return {
        ...state,
        isFetching: false,
        error: undefined
      }

    case SUCCESSFUL_UPDATE:
      return {
        ...state,
        companies: [],
        isFetching: false,
        error: undefined
      }

    case SUCCESSFUL_FETCH_ID:
      return {
        ...state,
        companies: {
          ...state.companies,
          [action.company.company.id]: action.company.company
        }
      }
    case FAILED_UPDATE:
    case FAILED_FETCH:
    case FAILED_CREATE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case CLEAR_ERROR_MSGS:
      return {
        ...state,
        error: null
      }
    case CLEAR_MSGS:
      return {
        ...state,
        msg: null
      }
    case UPDATE_PAGE_NUMBER:
      return{
        ...state,
        page:action.page
      }
    case UPDATE_FILTER:
      return{
        ...state,
        filter:action.filter
      }
    default:
      return state
  }
}
