import _ from 'lodash';
import * as StaffApi from '../../apis/StaffApi';
import * as UsersApi from '../../apis/UsersApi';
import { errorHandler } from '../../utils/errorHandler';

export const NAMESPACE = 'STAFF';

export const CLEAR_ERROR_MSGS = "CLEAR_ERROR_MSGS";
export const CLEAR_MSGS = "CLEAR_MSGS";

export const START_FETCH = `${NAMESPACE}__START_FETCH`;
export const SUCCESSFUL_FETCH = `${NAMESPACE}__SUCCESSFUL_FETCH`;
export const SUCCESSFUL_FETCH_ID = `${NAMESPACE}__SUCCESSFUL_FETCH_ID`;
export const FAILED_FETCH = `${NAMESPACE}__FAILED_FETCH`;

export const startFetch = () => ({ type: START_FETCH });
export const successfulFetch = (staff) => ({ type: SUCCESSFUL_FETCH, staff });
export const successfulFetchById = (staff) => ({ type: SUCCESSFUL_FETCH_ID, staff });
export const failedFetch = (error) => ({ type: FAILED_FETCH, error });

export const START_CREATE = `${NAMESPACE}__START_CREATE`;
export const SUCCESSFUL_CREATE = `${NAMESPACE}__SUCCESSFUL_CREATE`;
export const FAILED_CREATE = `${NAMESPACE}__FAILED_CREATE`;

export const startCreate = () => ({ type: START_CREATE });
export const successfulCreate = (staff) => ({ type: SUCCESSFUL_CREATE, staff });
export const failedCreate = (error) => ({ type: FAILED_CREATE, error });

export const START_UPDATE = `${NAMESPACE}__START_UPDATE`;
export const SUCCESSFUL_UPDATE = `${NAMESPACE}__SUCCESSFUL_UPDATE`;
export const FAILED_UPDATE = `${NAMESPACE}__FAILED_UPDATE`;

export const startUpdate = () => ({ type: START_UPDATE });
export const successfulUpdate = (staff) => ({ type: SUCCESSFUL_UPDATE, staff });
export const failedUpdate = (error) => ({ type: FAILED_UPDATE, error });

export const START_DELETE = `${NAMESPACE}__START_DELETE`;
export const SUCCESSFUL_DELETE = `${NAMESPACE}__SUCCESSFUL_DELETE`;
export const FAILED_DELETE = `${NAMESPACE}__FAILED_DELETE`;

export const startDelete = () => ({ type: START_DELETE });
export const successfulDelete = (staff) => ({ type: SUCCESSFUL_DELETE, staff });
export const failedDelete = (error) => ({ type: FAILED_DELETE, error });

export function fetchStaff(filterStr, callback) {
  return async (dispatch) => {
    dispatch(startFetch());
    try {
      const staff = await StaffApi.getStaff(filterStr);
      if(callback){callback();}
      dispatch(successfulFetch(staff));
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)));
    }
  };
}

export function fetchStaffById(id, callback){
  return async (dispatch) => {
    dispatch(startFetch());
    try {
      const staff = await UsersApi.getUserById(id);
      if(callback){callback();}
      dispatch(successfulFetchById(staff));
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)));
    }
  };
}

export function createStaff(data, callback){
  return async (dispatch) => {
    dispatch(startCreate());
    try {
      const staff = await UsersApi.createUser(data);
      if(callback){callback(true);}
      dispatch(successfulCreate(staff));
    } catch (err) {
      if(callback){callback(false);}
      dispatch(failedCreate(errorHandler(err)));
    }
  }
}

export function updateStaff(data, id, callback){
  return async (dispatch) => {
    dispatch(startUpdate());
    try {
      const staff = await UsersApi.updateUser(data, id);
      if(callback){callback(true);}
      dispatch(successfulUpdate(staff));
    } catch (err) {
      if(callback){callback(false);}
      dispatch(failedUpdate(errorHandler(err)));
    }
  }
}



export function clearErrorMsgs(){
  return async (dispatch) => {
    dispatch({type:CLEAR_ERROR_MSGS});
  };
}

export function clearStaffMsgs(){
  return async (dispatch) => {
    dispatch({type:CLEAR_MSGS});
  };
}

export const initialState = {
  staff: [],
  isFetching: false,
  error: undefined
};

export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCH:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      };
    case SUCCESSFUL_FETCH:
      let mapped_staff;
      if(action.staff){
        mapped_staff = _.mapKeys(action.staff.staff,(val) => val.id);
      }
      else{
        const format_staff = {[action.staff.id]:action.staff};
        mapped_staff = _.mapKeys(format_staff,(val) => val.id);
      }
      return {
        ...state,
        isFetching: false,
        staff: {
          ...mapped_staff
        },
        meta:action.staff.meta
      };
    case SUCCESSFUL_FETCH_ID:
      return{
        ...state,
        staff:{...state.staff,[action.staff.user.id]:action.staff.user}
      }
    case FAILED_FETCH:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case SUCCESSFUL_CREATE:
    case SUCCESSFUL_UPDATE:{
      return {
        ...state,
        staff:{...state.staff,[action.staff.user.id]:action.staff.user},
        msg:'Success: User Saved!'
      }
    }
    case FAILED_UPDATE:
    case FAILED_CREATE:{
      return {
        ...state,
        error:action.error
      }
    }
    case CLEAR_ERROR_MSGS:
      return{
        ...state,
        error:null
      }
    case CLEAR_MSGS:
      return{
        ...state,
        msg:null
      }
    default:
      return state;
  }
}
