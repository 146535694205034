import '../../../_base.scss';
import '../Modals.scss';
import './UploadFiles.scss';
import { GlobalStyle, Error } from '../../../global-style';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-modal';
import _ from 'lodash';
import DropZone from "react-dropzone";
import {androidDelete} from 'react-icons-kit/ionicons/androidDelete';
import { Icon } from 'react-icons-kit';
import { uploadFile, clearFiles } from '../../../redux/FilesRedux/FilesRedux';

export class UploadFiles extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showFileLengthError:false,
      showFileSizeError:false
    };
  }

  onSelectFile = (e) => {
    this.props.showLoading();
    this.props.actions.uploadFile(e.target.files, this.props.type, () => {
      this.props.hideLoading();
    });
  }

  capitalize = (str) => {
    const newStr = (str.substring(0,1)).toUpperCase() + str.substring(1,str.length);
    return newStr;
  }

  clearAndClose = () => {
    this.props.actions.clearFiles();
    this.props.closeModal();
  }

  render(){
    const {log, files} = this.props.files;
    const success_count = files ? _.size(_.filter(files, (file) => (file.status==="success"))) : 0;
    const failed_count = files ? _.size(_.filter(files, (file) => (file.status==="failed"))) : 0;
    const uploading_count = files ? _.size(_.filter(files, (file) => (file.status==="uploading"))) : 0;
    return(
      <Modal isOpen={this.props.showModal} className={((_.isEmpty(this.props.files.files)) ? "common-modal upload-files-modal" : "common-modal upload-files-list-modal")}>
        <div className="upload-section">
          {_.isEmpty(this.props.files.files) &&
          <div>
            <div className="title">{(this.props.type==='invoice') ? 'UPLOAD INVOICE' : 'UPLOAD ACCOUNT STATEMENT'}</div>
            {this.props.error && <Error>{this.props.error}</Error>}
            <div className="content">
              <p>You can choose one or multiple files to upload.</p>
              <p>File names must follow this format.</p>
              <p>{(this.props.type==='invoice') ? '[company_code]_[agreement_no]_[invoice_no]_[vehicle_no]_[ddmmyyyy].pdf' : '[company code]_[GBCRSOA]_[ddmmyyyy].pdf'}</p>
            </div>
          </div>}
          {(!(_.isEmpty(this.props.files.files))) &&
          <div>
            <div className="title">UPLOAD INVOICE</div>
            <div className="content">
              <div className="headers-section clearfix">
                <div>Total Selected {_.size(files)} files</div>
                <div>
                  <span className="uploading">Uploading {uploading_count} &nbsp;&nbsp;</span>
                  <span className="success">Success {success_count} &nbsp;&nbsp;</span>
                  <span className="failed">Failed {failed_count} &nbsp;&nbsp;</span>
                </div>
              </div>
              <div className="table-section">
                <table>
                {files && _.map(files,(file) => {
                  return(
                    <tr>
                      <td>{file.filename}</td>
                      <td><span className={file.status}>{this.capitalize(file.status)}:{file.error}</span></td>
                    </tr>
                  );
                })}

                </table>
              </div>
            </div>
          </div>}

        </div>
        {_.isEmpty(this.props.files.files) &&
        <div className="action-buttons clearfix">
          <button id="cancel-button" type="button" onClick={this.clearAndClose}><span>Cancel</span></button>
          <span className="btn btn-file">
              <div className="label">Choose Files</div> <input type="file" multiple onChange={this.onSelectFile} />
          </span>
        </div>}
        {(!_.isEmpty(files)) &&
          <div className="action-buttons clearfix">
            <span className="btn btn-file">
                <div className="label">Add more files</div> <input type="file" accept="application/pdf" multiple onChange={this.onSelectFile} />
            </span>
            <button id="done-button" type="button" onClick={this.clearAndClose}><span>Done</span></button>
            <button id="cancel-button" type="button" onClick={() => {this.props.downloadLog(log)}}><span>Download Status Log</span></button>

          </div>
        }
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ uploadFile, clearFiles }, dispatch),
    };
}

export default connect(null, mapDispatchToProps)(UploadFiles);
