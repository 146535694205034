export default function promiseMiddleware() {
  return next => async action => {
    const { promise, types, ...others } = action
    if (!promise) {
      return next(action)
    }
    const [REQUEST, SUCCESS, FAILURE] = types
    next({ ...others, type: REQUEST })
    try {
      const payload = await promise
      next({ ...others, type: SUCCESS, payload })
      return payload
    } catch (error) {
      next({ ...others, type: FAILURE, error })
      return false
    }
  }
}
