import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { forgotPassword, clearErrMsgs, clearAuthMsgs, getTokenData, firstLogin } from '../../redux/AuthRedux/AuthRedux';
import { GlobalStyle } from '../../global-style';
import {
  LoginDiv, InnerDiv, LoginForm, Input, Button,
  TitleOne, TitleTwo, ForgotLink,
  WebsiteLink, PlainText, LoginFooter,
  FooterImgLeft, FooterImgRight, Error
} from '../LoginContainer/style';
import { EmailHeader, CountryCode, AreaCode, PhoneNumber, Label, Text } from './style.js';
import { Icon } from 'react-icons-kit';
import {close} from 'react-icons-kit/fa/close';
import footerLogo1 from '../../assets/footer-logo.png';
import footerLogo2 from '../../assets/footer-logo2.png';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip'
import {checkCircleO} from 'react-icons-kit/fa/checkCircleO'
import {checkCircle} from 'react-icons-kit/fa/checkCircle'

export class Setup extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      email:'',
      password:''
    }
  }

  componentDidMount = () => {
    const { auth } = this.props;
    if(auth.loggedIn){
      this.props.history.push('/invoices');
    }
    const token = window.location.search.match(/^\?token=(.*)$/);
    if(token && token.length>1){
      this.props.actions.getTokenData(token[1]);
    }
  }

  componentWillUnmount(){
    this.props.actions.clearErrMsgs();
    this.props.actions.clearAuthMsgs();
  }

  clearErrors = () => {
    this.props.actions.clearErrMsgs();
  }

  onChangeHandler = (e) => {
    this.setState({email:e.target.value});
  }

  handleSubmit = (values) => {
    const data = {
      email:values.email,
      password:values.password,
      confirmPassword:values.confirmPassword,
      phone:`${values.countryCode}${values.areaCode}${values.phoneNumber}`,
      resetToken:this.props.auth.token.resetToken
    };
    this.props.actions.firstLogin(data, (success) => {
      if(success){
        this.props.history.push('/');
      }
    });
  }

  render(){
    const {handleSubmit, auth} = this.props;
    const {fields} = this.state;
    return(
      <div id="setup-container">
      <LoginDiv>
      <GlobalStyle />
        <InnerDiv>
          <Formik
          initialValues={{
            password: "",
            confirmPassword:"",
            countryCode:"",
            areaCode:"",
            phoneNumber:""
           }}
          validate={values => {
            let errors = {};
            // REGEX
            let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            // VALIDATION
            if(!values.password){
              errors.password = "Password is required";
            }
            if(!values.confirmPassword){
              errors.confirmPassword = "Confirm password is required";
            }
            if(values.password && values.confirmPassword && !(values.password === values.confirmPassword)){
              errors.password = 'Passwords do not match.';
              errors.confirmPassword = 'Passwords do not match.';
            }
            if(values.phoneNumber && !(/^\+?\d{1,14}$/i.test(values.phoneNumber))){
              errors.phoneNumber = "Invalid phone number."
            }
            return errors;
          }}
          onSubmit={values => {
            this.handleSubmit(values);
          }}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return(
            <LoginForm onSubmit={handleSubmit}>
            <TitleOne>Welcome to</TitleOne>
            <TitleTwo isError={auth.token}>GOLDBELL</TitleTwo>
              {auth.token && <EmailHeader>{_.truncate(auth.token.email, {length:35})}</EmailHeader>}
              {auth.error && <Error id="setup-container-error" color="red">{auth.error} <span onClick={this.clearErrors} style={{ color: '#a94442' }}><Icon icon={close} /></span></Error>}
              <Label>
                <CountryCode
                  id="setup-country-code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.countryCode}
                  border={touched.countryCode && errors.countryCode && "1px solid red"}
                  type="text"
                  name="countryCode"
                  placeholder="+65"
                />
                {touched.countryCode &&
                  errors.countryCode && <Text color="red">{errors.countryCode}</Text>}
              </Label>
              <Label>
                <AreaCode
                  id="setup-area-code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.areaCode}
                  border={touched.areaCode && errors.areaCode && "1px solid red"}
                  type="text"
                  name="areaCode"
                  placeholder="Area Code"
                />
                {touched.areaCode &&
                  errors.areaCode && <Text color="red">{errors.areaCode}</Text>}
              </Label>
              <Label>
                <PhoneNumber
                  id="setup-phone-number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                  border={touched.phoneNumber && errors.phoneNumber && "1px solid red"}
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                />
                {touched.phoneNumber &&
                  errors.phoneNumber && <Text isPhone={true} color="red">{errors.phoneNumber}</Text>}
              </Label>
              <Label>

                <Input
                  id="setup-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  border={touched.password && errors.password && "1px solid red"}
                  type="password"
                  name="password"
                  placeholder="Password"
                  data-tip="info"
                  data-event="focus"
                  data-event-off="blur"
                  data-for="password-tooltip"
                />
                {touched.password &&
                  errors.password && <Text color="red">{errors.password}</Text>}
                  <ReactTooltip id="password-tooltip" place="left" type="dark" effect="float">
                  {((values.password).match(/^(.{6,})$/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 6 characters<br/>
                  {((values.password).match(/([A-Z]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 upper case letter<br/>
                  {((values.password).match(/([0-9]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 number
                  </ReactTooltip>
              </Label>
              <Label>
                <Input
                  id="setup-confirm-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  border={touched.confirmPassword && errors.confirmPassword && "1px solid red"}
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  data-tip="info"
                  data-event="focus"
                  data-event-off="blur"
                  data-for="confirmPassword-tooltip"
                />
                {touched.confirmPassword &&
                  errors.confirmPassword && <Text color="red">{errors.confirmPassword}</Text>}
                  <ReactTooltip id="confirmPassword-tooltip" place="left" type="dark" effect="float">
                  {((values.confirmPassword).match(/^(.{6,})$/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 6 characters<br/>
                  {((values.confirmPassword).match(/([A-Z]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 upper case letter<br/>
                  {((values.confirmPassword).match(/([0-9]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 number
                  </ReactTooltip>
              </Label>

              <Button id="setup-submit-button" type="submit">Setup</Button>
              <ForgotLink><Link id="setup-login-link" to="/login">Login</Link></ForgotLink>
              <PlainText>Visit our website <WebsiteLink><a id="setup-website-link" href="http://www.goldbellgroup.com">www.goldbellgroup.com</a></WebsiteLink></PlainText>
            </LoginForm>
          )}}
        />
        </InnerDiv>
      </LoginDiv>
      <LoginFooter>
        <FooterImgRight src={footerLogo1} />
        <FooterImgLeft src={footerLogo2} />
      </LoginFooter>
      </div>
    );
  }
}

function mapStateToProps({auth}){
  return({
    auth: auth
  });
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ forgotPassword, clearErrMsgs, getTokenData, firstLogin, clearAuthMsgs }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
