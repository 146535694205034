import React from 'react';
import _ from 'lodash';
import './Form.scss';
import 'input-moment/dist/input-moment.css';
import Select from 'react-select';

export default function MultiSelectField(
  {
  input,
  label,
  type,
  placeHolder,
  values,
  options,
  onChangeHandle,
  isSingle,
}){
  return(
    <div className="multiselect-field form-group">
    <Select
      name="form-field-name"
      onChange={onChangeHandle}
      isMulti={isSingle ? false: true}
      isSearchable={true}
      options={options}
      placeholder={placeHolder}
      value={values}
      theme={(theme) => ({
      ...theme,
      borderRadius: 4,
      colors: {
      ...theme.colors,
        text: '#fcb72d',
        ...colors,
      },
    })}
    />
    </div>
  );
}

const colors = {
  primary: '#fff3da',
  primary75: '#fff3da',
  primary50: '#fff3da',
  primary25: '#fff3da',
  neutral10:'#fff3da',
  neutral50:'#fcb72d',
  neutral60:'#fcb72d',
  neutral70:'#fcb72d',
  neutral80:'#fcb72d',
  neutral90:'#fcb72d',

};
