import React from 'react'
import _ from 'lodash'
import LoadingContainer from '../../Loading/components/LoadingContainer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import MultiSelectField from '../../common/FormComponent/MultiSelectField'
import {
  createCustomer,
  updateCustomer,
  fetchCustomerById,
  clearErrorMsgs,
  clearCustMsgs
} from '../../redux/CustomersRedux/CustomersRedux'
import { fetchCompanies } from '../../redux/CompaniesRedux/CompaniesRedux'
import {
  fetchAgreements,
  clearAgreements
} from '../../redux/AgreementsRedux/AgreementsRedux'
import { Formik, Field } from 'formik'
import { RadioButton, RadioButtonGroup } from '../../common/Buttons'
import { GlobalStyle, ContentContainer, WidthContent, Message } from '../../global-style'
import {
  UserFormContainer,
  UserForm,
  InnerDiv,
  Title,
  BackLink,
  Button,
  Text,
  Label,
  Input,
  FormItem,
  ButtonContainer,
  Error,
} from './style'
import { Icon } from 'react-icons-kit'
import { chevronLeft } from 'react-icons-kit/fa/chevronLeft'
import { close } from 'react-icons-kit/fa/close'

const OPT_IN = 'OPT_IN'
const OPT_OUT = 'OPT_OUT'

export class ManageCustomer extends React.Component {
  constructor(props) {
    super(props)
    let isCreate = true
    let currentId = null
    let showLoading = true
    let companyId = null;
    let submitBtnText = 'Create New Customer User'
    let goBackUserDetail = window.location.search.includes('userDetail');
    const matchCompanyId = window.location.search.match(/\?companyId=(.*)/);
    if(matchCompanyId && matchCompanyId.length>1){
      companyId = matchCompanyId[1];
    }
    const path = window.location.pathname.match(/\/customers\/edit\/(.*)/);
    if (path && path.length > 1) {
      isCreate = false
      currentId = path[1]
      submitBtnText = 'Save'
    } else {
      showLoading = false
    }
    this.state = {
      current:null,
      showLoading,
      isCreate,
      currentId,
      submitBtnText,
      companies: [],
      agreements: [],
      companyError: false,
      updateCompanies: false,
      fetchFinish: false,
      companyId,
      goBackUserDetail
    }
  }

  componentDidMount = () => {
    this.props.actions.clearErrorMsgs();
    this.props.actions.clearCustMsgs();
    if (this.state.isCreate) {
      this.props.actions.clearAgreements()
    }
    this.props.actions.fetchCompanies('?perPage=1000')
    if (!this.state.isCreate) {
      this.props.actions.fetchCustomerById(this.state.currentId, () => {
        this.setState({ showLoading: false, fetchFinish: true })
      })
    }
  }

  componentWillReceiveProps({ customers, companies, agreements }) {
    let { current, isCreate, updateCompanies, fetchFinish } = this.state

    if (!isCreate && (!current) && fetchFinish) {
      let current = customers.customers[this.state.currentId]
      if(!current){return}
      current.companyIds = _.map(current.companies, company => {
        return { label: company.code, value: company.id }
      })
      let filterStr = '?companyIds='
      _.forEach(current.companyIds, item => {
        filterStr = `${filterStr}${item.value},`
      });
      filterStr = filterStr.substring(0, filterStr.length - 1);
      this.props.actions.fetchAgreements(filterStr);

        current.agreementIds = _.map(current.agreements, ag => {
          return { label: ag.code, value: ag.id, company:ag.company }
        })
      this.setState({current});
    }
    const format_companies = _.map(companies.companies, company => {
      if(this.state.companyId && this.state.isCreate && (company.id == this.state.companyId)){
        let {current} = this.state;
        if(!current)current = {};
        current.companyIds = [{ label:company.name, value:company.id}];
        this.setState({current});
      }
      return {
        label: company.name,
        value: company.id
      }
    });
    this.setState({ companies: format_companies });
    const format_agreements = _.map(agreements.agreements, ag => {
      if (ag) {
        return { label: ag.code, value: ag.id, company:ag.company }
      }
      return {}
    });
    this.setState({ agreements: format_agreements })
  }

  componentWillUnmount(){
    this.props.actions.clearErrorMsgs();
    this.props.actions.clearCustMsgs();
  }

  goToUsers = () => {
    this.props.history.push('/users?isBack=true')
  }

  goToCompany = () => {
    this.props.history.push(`/companies/${this.state.companyId}`);
  }

  goBack = () => {
    if(this.state.companyId){
      this.goToCompany();
    }
    else{
      if(this.state.goBackUserDetail){
          this.props.history.push(`/users/customers/${this.state.currentId}`)
      }
      else{
        this.goToUsers()
      }
    }
  }

  handleSubmit = values => {

    if (!this.state.current || !this.state.current.companyIds || _.isEmpty(this.state.current.companyIds)) {
      this.setState({ companyError: true })
      return
    } else {
      this.setState({ companyError: false, showLoading: true })
    }

    const { companyIds, agreementIds } = this.state.current;

    let data = values;
    data.role = 'Customer';
    data.name = data.name;
    data.email = values.email;
    if(values.phone){
      data.phone = values.phone;
    }
    if(this.state.isCreate){
      data.isOptIn = (values.isOptIn === OPT_IN) ? true : false;
    }
    let companies = []
    _.forEach(companyIds, company => {
      companies.push(company.value)
    })
    data.companies = companies
    if (agreementIds.length) {
      let agreements = []
      _.forEach(agreementIds, ag => {
        agreements.push(ag.value)
      })
      data.agreements = agreements
    }
    data = _.omit(data, 'companyIds')
    data = _.omit(data, 'agreementIds')
    if (this.state.isCreate) {
      this.props.actions.createCustomer(data, success => {
        this.setState({ showLoading: false })
        if (success) {
          this.goBack();
        } else {
          let { current } = this.state
          current.isOptIn = OPT_IN
          this.setState({ current })
        }
      });
    }
    else{
      let editData = {
        name: data.name,
        companies:data.companies,
        agreements:data.agreements,
        role:data.role,
        email:data.email,
      }
      if(data.phone){
        editData.phone = data.phone;
      }
      this.props.actions.updateCustomer(
        editData,
        this.state.currentId,
        success => {
          this.setState({ showLoading: false })
          if (success) {
            this.goBack()
          }
        }
      )
    }
  }

  formatForEdit = user => {
    if (!user) {
      return {}
    }
    user.name = user.name
    user.confirmEmail = user.email
    user.companyIds = _.map(user.companies, company => {
      return { label: company.code, value: company.id }
    })
    user.agreementIds = _.map(user.agreements, ag => {
      return { label: ag.code, value: ag.id }
    })
    return user
  }

  onChangeHandle = (param, e) => {
    let { current, companyIds, isCreate } = this.state;
    if(!current){ current = {}}
    if(param === 'companyIds' || param === 'agreementIds'){
      if(param === 'companyIds'){
        current[param] = e;
        this.props.actions.clearAgreements();
        let new_companies = _.mapKeys(e,(comp) => comp.value);
        let agreements = _.filter(current.agreementIds,(ag) => {
          if(new_companies[ag.company]){
            return true;
          }
          else{
            return false;
          }
        });
        current.agreementIds = agreements;
        if (e.length) {
          this.setState({ companyError: false })
        } else {
          this.setState({ companyError: true })
        }
      }
      else{
        current[param] = e;
      }
    }
    this.setState({ current }, () => {
      const { companyIds } = this.state.current
      if (companyIds.length) {
        let filterStr = '?companyIds='
        _.forEach(companyIds, item => {
          filterStr = `${filterStr}${item.value},`
        })
        filterStr = filterStr.substring(0, filterStr.length - 1)
        this.props.actions.fetchAgreements(filterStr, () => {

        })
      }
    })
  }

  clearMsgs = () => {
    this.props.actions.clearCustMsgs()
  }

  clearErrors = () => {
    this.props.actions.clearErrorMsgs()
  }

  removeAgreements = (companyIds, agreementIds) => {
    const { companies, agreements } = this.props;
  }

  render(){
    let {current} = this.state;
    if(this.state.isCreate){
      if(!current)current={};
      current.isOptIn = OPT_IN;
    }
    if(!this.state.isCreate && !current){return null}
    return(
      <div id="manage-customer-container">
      <ContentContainer>
      <GlobalStyle />
        <LoadingContainer showLoading={this.state.showLoading} />
        <BackLink onClick={this.state.companyId ? this.goToCompany : this.goToUsers}><span style={{ color: '#a9aaac' }}><Icon icon={chevronLeft} /></span>{this.state.companyId ? 'Back to Company Details' : 'Back to All Users'}</BackLink>
        <Title>{this.state.isCreate ? 'CREATE NEW CUSTOMER USER' : 'EDIT CUSTOMER USER'}</Title>
        <InnerDiv>
          <WidthContent>
          <Formik
          initialValues={{
            ...current
          }}
          validate={values => {
            let errors = {};
            // REGEX
            let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            // VALIDATION
            if(!values.name){
              errors.name = "Name is required."
            }
            if(!values.email){
              errors.email = "Email is required.";
            }
            else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))){
              errors.email = "Invalid email address.";
            }
            if(values.phone && !(/^\+?\d{1,14}$/i.test(values.phone))){
              errors.phone = "Invalid phone number."
            }
            if(this.state.isCreate){
              if(!values.confirmEmail){
                errors.confirmEmail = "Confirmation Email is required.";
              }
              else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.confirmEmail))){
                errors.confirmEmail = "Invalid email address.";
              }
              if((values.email && values.confirmEmail) && !(values.email === values.confirmEmail)){
                errors.confirmEmail = "Confirmation email and email do not match.";
              }
            }
            return errors;
          }}
          onSubmit={(values,actions) => {
            if(!(values.name || values.email || values.confirmEmail)){
              actions.setTouched({ fields: {'name':true, 'phone':true, 'email':true, 'confirmEmail':true}});
            }
            else{
              this.handleSubmit(values);
            }
          }}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            status
          }) => {
            return(
            <UserForm onSubmit={handleSubmit}>
                {this.props.customers.msg && <Message id="edit-profile-message">{this.props.customers.msg}<span onClick={this.clearMsgs} style={{ color: '#3c763d' }}><Icon icon={close} /></span></Message>}
                {this.props.customers.error && <Error id="manage-customer-error">{this.props.customers.error}<span onClick={this.clearErrors} style={{ color: '#a94442' }}><Icon icon={close} /></span></Error>}
              <FormItem secondRow={true} firstItem={this.state.isCreate ? false:true}>
                <Label>
                  NAME
                </Label>
                <Input
                  id="manage-customer-name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  border={touched.name && errors.name && "1px solid red"}
                  type="text"
                  name="name"
                />
                <Text color="red" display={errors.name}>{errors.name}</Text>
              </FormItem>
              <FormItem secondRow={true} secondColumn={this.state.isCreate ? true : false} firstItem={this.state.isCreate ? false:false}>
                <Label>
                  PHONE NUMBER
                </Label>
                <Input
                  id="manage-customer-phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  border={touched.phone && errors.phone && "1px solid red"}
                  type="text"
                  name="phone"
                />
                <Text color="red" display={errors.phone}>{errors.phone}</Text>
              </FormItem>
              <FormItem secondRow={true} firstItem={this.state.isCreate ? false:false} secondColumn={this.state.isCreate ? false:true}>
                <Label>
                EMAIL
                </Label>
                <Input
                  id="manage-customer-email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  border={
                    touched.email && errors.email && "1px solid red"
                  }
                  type='text'
                  name='email'
                />
                <Text
                  color='red'
                  display={errors.email}
                >
                  {errors.email}
                </Text>
                      </FormItem>
                      {this.state.isCreate && (
                        <FormItem secondColumn={true} secondRow={true}>
                          <Label>CONFIRM EMAIL</Label>
                          <Input
                            id='manage-customer-confirm-email'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmEmail}
                            border={
                              touched.confirmEmail &&
                              errors.confirmEmail &&
                              '1px solid red'
                            }
                            type='text'
                            name='confirmEmail'
                          />
                          <Text
                            display={errors.confirmEmail
                            }
                            color='red'
                          >
                            {errors.confirmEmail}
                          </Text>
                        </FormItem>
                      )}
                      <FormItem
                        secondRow={true}
                        className='companies'
                        isEmpty={current ? _.isEmpty(current.companyIds) : true}
                      >
                        <Label>COMPANY</Label>
                        <Field
                          id='manage-customer-companies'
                          component={MultiSelectField}
                          name='companyIds'
                          values={this.state.current ? this.state.current.companyIds : []}
                          type='text'
                          options={this.state.companies}
                          onChangeHandle={e => {
                            this.onChangeHandle('companyIds', e)
                          }}
                        />
                        <Text display={this.state.companyError} color='red'>
                          Company is required.
                        </Text>
                      </FormItem>
                      <FormItem
                        secondColumn={true}
                        secondRow={true}
                        isEmpty={current ? _.isEmpty(current.agreementIds) : true}
                      >
                        <Label>AGREEMENT NO (optional)</Label>
                        <MultiSelectField
                          id='manage-customer-agreements'
                          name='agreementIds'
                          values={this.state.current ? this.state.current.agreementIds : []}
                          type='text'
                          options={this.state.agreements}
                          onChangeHandle={e => {
                            this.onChangeHandle('agreementIds', e)
                          }}
                        />
                        {errors.agreementIds && (
                          <Text color='red'>{errors.agreementIds}</Text>
                        )}
                      </FormItem>
                      {this.state.isCreate && (
                        <FormItem secondRow={true} firstItem={true}>
                          <RadioButtonGroup
                            id='isOptIn'
                            label='EMAIL NOTIFICATION SETTINGS'
                            value={values.isOptIn}
                            error={errors.isOptIn}
                            touched={touched.isOptIn}
                          >
                            <Field
                              component={RadioButton}
                              name='isOptIn'
                              id={OPT_IN}
                              label='Opt-In'
                            />
                            <Field
                              component={RadioButton}
                              name='isOptIn'
                              id={OPT_OUT}
                              label='Opt-Out'
                            />
                          </RadioButtonGroup>
                          <Text
                            color='red'
                            display={values.isOptIn === OPT_OUT}
                          >
                            <span>Note: </span>This customer will not recieve
                            email for the invoice notification.
                          </Text>
                        </FormItem>
                      )}
                      <ButtonContainer>
                        <Button
                          id='manage-customer-cancel-button'
                          type='button'
                          onClick={() => {this.goBack()}}
                        >
                          Cancel
                        </Button>
                        <Button
                          id='manage-customer-submit-button'
                          type='submit'
                        >
                          {this.state.submitBtnText}
                        </Button>
                      </ButtonContainer>
                    </UserForm>
                  )
                }}
              />
            </WidthContent>
          </InnerDiv>
        </ContentContainer>
      </div>
    )
  }
}

function mapStateToProps({ customers, companies, agreements }) {
  return {
    customers,
    companies,
    agreements
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchCustomerById,
        createCustomer,
        updateCustomer,
        clearErrorMsgs,
        fetchCompanies,
        fetchAgreements,
        clearAgreements,
        clearCustMsgs
      },
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCustomer)
