import React from 'react'
import downloadIcon from '../../assets/dl-ic-btn.png'
import './DownloadButton.scss'

const DownloadButton = ({ onClick, text, className }) => {
  return (
    <button
      className={className ? `download-btn ${className}` : 'download-btn'}
      onClick={onClick}
    >
      <img className='icon' alt='download-icon' src={downloadIcon} />
      {text}
    </button>
  )
}

export default DownloadButton
