import './Pagination.scss'
import React from 'react'
import _ from 'lodash'
import ReactPaginate from 'react-paginate'

class Pagination extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { data } = this.props
    const startCount = (data.currentPage - 1) * data.perPage + 1
    const endCount = Math.min(data.currentPage * data.perPage, data.totalCount)

    return (
      <div className='paginate-holder'>
        <div className='showing-text'>
          Showing {startCount} - {endCount} of {data.totalCount}
        </div>
        <ReactPaginate
          previousLabel='Prev'
          nextLabel='Next'
          breakClassName={'break-me'}
          pageCount={data.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={this.props.handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeclassname={'active-page'}
          forcePage={data.currentPage-1}
        />
      </div>
    )
  }
}

export default Pagination
