export const authMiddleware = store => next => action => {
  if (action.type === 'AUTH__SUCCESSFUL_LOGIN') {

    localStorage.setItem('GOLDBELL-CMS:USER', JSON.stringify({...getAuth(),...action.auth}));
  }
  if (action.type === 'AUTH__SUCCESSFUL_LOGOUT' || action.type === 'AUTH__LOGOUT_FAILURE') {
    localStorage.setItem('GOLDBELL-CMS:USER', null);
  }
  return next(action);
}

function getAuth(){
  try{
    const auth = JSON.parse(localStorage.getItem('GOLDBELL-CMS:USER'));
    return auth;
  }
  catch(err){
    return {};
  }
}
