import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'

import authReducer from './redux/AuthRedux'
import invoicesReducer from './redux/InvoiceRedux'
import statementsReducer from './redux/SOARedux'
import filesReducer from './redux/FilesRedux'
import customersReducer from './redux/CustomersRedux'
import staffReducer from './redux/StaffRedux'
import companiesReducer from './redux/CompaniesRedux'
import agreementsReducer from './redux/AgreementsRedux'
import auditReducer from './redux/AuditRedux'
import companyDetailReducer from './redux/CompaniesRedux/CompanyDetailRedux'

export default combineReducers({
  router: routerReducer,
  auth: authReducer,
  invoices: invoicesReducer,
  files: filesReducer,
  customers: customersReducer,
  staff: staffReducer,
  statements: statementsReducer,
  companies: companiesReducer,
  agreements: agreementsReducer,
  audit: auditReducer,
  companyDetail: companyDetailReducer
})
