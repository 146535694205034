import React from 'react';
import _ from 'lodash';
import LoadingContainer from '../../Loading/components/LoadingContainer';
import OptionModal from '../../common/Modals/OptionModal/OptionModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserDetails, clearErrMsgs } from '../../redux/AuthRedux/AuthRedux';
import { updateCustomerEmailSettings, clearErrorMsgs, clearCustMsgs } from '../../redux/CustomersRedux/CustomersRedux';
import { GlobalStyle, ContentContainer, WidthContent } from '../../global-style';
import {
  UserFormContainer,
  InnerDiv,
  Title,
  SubTitle,
  BackLink,
  Table,
  TableLabel,
  TableValue,
  OptDiv,
  SubSection,
  AgreementNoDiv,
  Label,
  Option,
  LinkText,
  Note,
  TableParent,
  Username
} from './style';
import { Icon } from 'react-icons-kit';
import {check} from 'react-icons-kit/fa/check'
import {chevronLeft} from 'react-icons-kit/fa/chevronLeft';

const OPT_IN = 'OPT_IN';
const OPT_OUT = 'OPT_OUT';

export class ProfileDetail extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showLoading:true,
      showOptionModal:false
    }
  }

  componentDidMount = () => {
    const {user} = this.props.auth.auth;
    if(user){
      this.props.actions.getUserDetails(user.id,() => {
        this.setState({showLoading:false});
      })
    }
  }

  openOptionModal = (company) => {
    this.setState({company},() => {
      this.setState({showOptionModal:true});
    });
  }

  getCompanyName = (nameStr) => {
    if(!nameStr){return ''}
    if(nameStr.length > 30){
      return {
        name: _.truncate(nameStr,{length:30}),
        title: nameStr
      }
    }
    else{
      return nameStr;
    }
  }

  saveOption = (value, notification) => {
    this.setState({showOptionModal:false, showLoading:true});
    const { company, currentId } = this.state;
      const data = {
        isEmailOptedIn:value,
        emailNotification:notification
      };
      const {user} = this.props.auth.auth;
      this.props.actions.updateCustomerEmailSettings(user.id, company.id, data, () => {
        this.props.actions.getUserDetails(user.id,() => {
          this.setState({showLoading:false});
        });
      });
  }

  editUser = () => {
    this.props.history.push('/profile/edit');
  }

  cancelOption = () => {
    this.setState({showOptionModal:false});
  }

  goToCompany = (company) => {
    this.props.history.push(`/companies/${company.id}`);
  }

  render(){
    const user = this.props.auth.auth.user;
    if(!user){return null;}
    let isAdmin = false;
    if(user.role ==='Admin' || user.role === 'SuperAdmin'){
      isAdmin = true;
    }
    return(
      <div id="profile-detail-container">
      <ContentContainer>
      <GlobalStyle />
        <LoadingContainer showLoading={this.state.showLoading} />
        <OptionModal showModal={this.state.showOptionModal} company={this.state.company} yes={this.saveOption} no={this.cancelOption} />
          {user && <Title><Username>{user.name ? user.name : `${user.firstName} ${user.lastName}`}<LinkText onClick={this.editUser}>Edit Info</LinkText></Username></Title>}
          {user &&
            <InnerDiv>
              <TableParent>
              <Table>
                <tbody>
                <tr>
                  <TableLabel>EMAIL</TableLabel>
                  <TableValue id="profile-detail-email">{user.email}</TableValue>
                </tr>
                <tr>
                  <TableLabel>PHONE NUMBER</TableLabel>
                  <TableValue id="profile-detail-phone">{user.phone}</TableValue>
                </tr>
                {!isAdmin && <tr>
                  <TableLabel>COMPANIES</TableLabel>
                  <TableValue id="profile-detail-companies" isLink={false}>{_.join(_.map(user.companies,(company) => company.name), ', ')}</TableValue>
                </tr>}
                </tbody>
              </Table>
              </TableParent>
              <WidthContent>
            {!isAdmin && <SubTitle>Email Settings</SubTitle>}
            {!isAdmin && <SubSection>
              {_.map(user.companies,(company) => {
                const companyName = this.getCompanyName(company.name);
                return(
                  <OptDiv>
                    <Label title={companyName.title ? companyName.title : ''}>{companyName.name ? companyName.name : companyName}</Label>
                    <Option id="profile-detail-company-settings">
                      <span>{(company.isEmailOptedIn && company.emailSetting && company.emailSetting.isEmailOptedIn) ? 'Opt-In' : 'Opt-Out'}</span>
                      {!isAdmin && <span style={{fontSize:'12px'}}>  (Contact GBCR admin to change.)</span>}
                      {(company.isEmailOptedIn && isAdmin) && <LinkText id="profile-detail-option-change" onClick={() => {this.openOptionModal(company)}}>Change</LinkText>}

                      <Note visible={company.isEmailOptedIn && company.emailSetting && company.emailSetting.isEmailOptedIn}>
                        <Icon className="icon" icon={check} />
                        <div className="note">{(company.emailSetting.emailNotification === 'individual') ? 'Notify when individual invoice is ready.' : 'Notify when all invoices are ready.'}</div>
                      </Note>
                    </Option>
                  </OptDiv>
                );
              })}
            </SubSection>}
            </WidthContent>
          </InnerDiv>
        }

      </ContentContainer>
      </div>
    );
  }
}

function mapStateToProps({auth, customers}){
  return({
    auth,
    customers
  });
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ clearErrorMsgs, updateCustomerEmailSettings, clearCustMsgs, getUserDetails, clearErrMsgs }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetail);
