import _ from 'lodash';

export function errorHandler(err){
  if(err.response && err.response.data){
    const {data} = err.response;
    if(data.details){
      let errStr = '';
      const errors = _.mapKeys(data.details,(val,key) => {
        errStr = errStr + ' ' + val;
        //console.log('val:', val);
        //console.log('key: ', key);
        return key;
      });

      return errStr;
    }
    else if(data.error){
      return data.error;
    }
    else if(data.message){
      return data.message;
    }
    else{
      return data.toString();
    }
  }
  else{
    return err.toString();
  }
  //check if refresh error
  //check if error detail is available
  //just sent the error message as is
}
