import axios from 'axios';
import { axiosInstance } from '../../redux/AuthRedux/axiosInterceptors';

const baseUrl = `${process.env.REACT_APP_CMS_API_URL}auth/`;
export const REFRESH_FAILURE = 'REFRESH_FAILURE';

export async function login(auth) {
  const response = await axios.post(`${baseUrl}login`,auth);
  return response.data;
  /*const expected_response = {
    "accessToken": "ewfwef890w8ef9ew8few9f8ew9f89ef8ef8e90wf",
    "refreshToken": "wefwef8ew9f8ew90f8ew08f0wef809ewf"
  };
  return expected_response;*/
}

export async function firstSignIn(data){
  const response = await axios.post(`${baseUrl}setup`,data);
  return response.data;
}

export async function getDetails(id){
  const url = `${process.env.REACT_APP_CMS_API_URL}users/${id}`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function getTokenInfo(token){
  const response = await axios.get(`${baseUrl}token-info?resetToken=${token}`);
  return response.data;
}

export async function forgotPassword(data){
  const response = await axios.post(`${baseUrl}forget-password`,data);
  return response.data;
}

export async function resetPassword(data){
  const url = `${baseUrl}reset-password`;
  const response = await axios.post(url,data);
  return response.data;
}

export async function logout() {
  const response = await axiosInstance.post(`${baseUrl}logout`,{});
  return response.data;
}
