import styled from 'styled-components';
import homeImg from '../assets/home.png';

export const LoginDiv = styled.div`
  background-image: url(${homeImg}) !important;
  background-size: cover !important;
  background: no-repeat;
  height: 960px;
  overflow: hidden;
`;

export const InnerDiv = styled.div`
  width: 83.3333%;
  margin: auto;
`;

export const LoginForm = styled.form`
    background-color: #fcb72d;
    padding: 40px 50px;
    position: absolute;
    width: 400px;
    height:auto;
    margin-top: 100px;
    margin-right:12%;
    right:0;
    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      border-width: 15px 15px 0 0;
      border-style: solid;
      border-color: #fcb72d transparent;
      display: block;
      width: 0;
    }
`;

export const Message = styled.div`
background-color:#dff0d8;
padding:15px;
color:#3c763d;
border-radius:4px;
font-family: VistaSansOT-Reg;
font-size: 14px;
& .close{
  position:relative;
  top:0px;
  right:0px;
  cursor:pointer;
}
& .message{
  width:250px;
}
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #777;
  font-size: 0.8em;
  margin: 0.5em 0;
  position: relative;
`;

export const TitleOne = styled.div`
font-family: VistaSansOT-Reg;
font-size: 16px;
text-align: center;
color: #2d2926;
`;
export const TitleTwo = styled.div`
font-family: VistaSansOT;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d2926;
  margin-bottom:40px;
`;

export const Text = styled.p`
  color: ${props => props.color || '#4d4d4d'}
`;

export const Input = styled.input`
  width: 300px;
  height: 35px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius:4px;
  padding-left:10px;
`;

export const Button = styled.button`
width: 300px;
height: 36px;
border-radius: 4px;
box-shadow: 0 4px 0 0 #000000;
background-color: #2d2926;
font-family: VistaSansOT;
font-size: 14px;
font-weight: bold;
color: #fcb72d;
border-style:none;
margin-top:15px;
cursor:pointer;
`;

export const ForgotLink = styled.div`
  width:100%;
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d2926;
  margin-top:36px;
  text-decoration:underline;
  cursor:pointer;
  & a{
    color:#2d2926;
    text-decoration:none;
  }
  & a:hover{
    color:#2d2926;
    text-decoration:none;
  }
`;

export const Error = styled.div`
background-color:#f2dede;
padding:15px;
color:#a94442;
margin-bottom: 20px;
border-radius:4px;
font-family: VistaSansOT-Reg;
font-size: 14px;
 & span{
   float:right;
   cursor:pointer;
 }
`;

export const ErrorMsg = styled.div`
width:90%;
`;

export const PlainText = styled.div`
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position:relative;
  top:113px;
`;

export const WebsiteLink = styled.span`
font-family: VistaSansOT-Reg;
font-size: 14px;
font-weight: 800;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #fcb72d;
cursor:pointer;
& a{
  color:inherit;
  text-decoration:none;
}
`;

export const LoginFooter = styled.div`
  width: 100%;
  height: 68px;
  background-color: #f1f1f1;
`;

export const FooterImgLeft = styled.img`
  float:left;
  padding-top:25px;
  padding-left:45px;
`;

export const FooterImgRight = styled.img`
  float:right;
  padding-top:15px;
  padding-right:45px;
`;
