import _ from 'lodash';
import * as InvoiceApi from '../../apis/InvoiceApi';
import { errorHandler } from '../../utils/errorHandler';
import { formatInvoices } from '../../utils/invoices';

export const NAMESPACE = 'INVOICE';

export const CLEAR_ERROR_MSGS = "CLEAR_ERROR_MSGS";
export const FAILED_DOWNLOAD = `${NAMESPACE}__FAILED_DOWNLOAD`;

export const START_FETCH = `${NAMESPACE}__START_FETCH`;
export const SUCCESSFUL_FETCH = `${NAMESPACE}__SUCCESSFUL_FETCH`;
export const FAILED_FETCH = `${NAMESPACE}__FAILED_FETCH`;

export const startFetch = () => ({ type: START_FETCH });
export const successfulFetch = (invoices) => ({ type: SUCCESSFUL_FETCH, invoices });
export const failedFetch = (error) => ({ type: FAILED_FETCH, error });

export const START_CREATE = `${NAMESPACE}__START_CREATE`;
export const SUCCESSFUL_CREATE = `${NAMESPACE}__SUCCESSFUL_CREATE`;
export const FAILED_CREATE = `${NAMESPACE}__FAILED_CREATE`;

export const startCreate = () => ({ type: START_CREATE });
export const successfulCreate = (invoices) => ({ type: SUCCESSFUL_CREATE, invoices });
export const failedCreate = (error) => ({ type: FAILED_CREATE, error });

export const START_UPDATE = `${NAMESPACE}__START_UPDATE`;
export const SUCCESSFUL_UPDATE = `${NAMESPACE}__SUCCESSFUL_UPDATE`;
export const FAILED_UPDATE = `${NAMESPACE}__FAILED_UPDATE`;

export const startUpdate = () => ({ type: START_UPDATE });
export const successfulUpdate = (invoices) => ({ type: SUCCESSFUL_UPDATE, invoices });
export const failedUpdate = (error) => ({ type: FAILED_UPDATE, error });

export const START_DELETE = `${NAMESPACE}__START_DELETE`;
export const SUCCESSFUL_DELETE = `${NAMESPACE}__SUCCESSFUL_DELETE`;
export const FAILED_DELETE = `${NAMESPACE}__FAILED_DELETE`;

export const startDelete = () => ({ type: START_DELETE });
export const successfulDelete = (invoices) => ({ type: SUCCESSFUL_DELETE, invoices });
export const failedDelete = (error) => ({ type: FAILED_DELETE, error });

export const failedDownload = (error) => ({type:FAILED_DOWNLOAD, error});

export function fetchInvoices(filterStr, callback) {
  return async (dispatch) => {
    dispatch(startFetch());
    if(callback){callback();}
    try {
      const invoices = await InvoiceApi.getInvoices(filterStr);
      dispatch(successfulFetch(invoices));
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)));
    }
  };
}

export function downloadInvoice(link){
  return async (dispatch) => {
    try {
      const data = await InvoiceApi.downloadInvoice(link);
      const url = data.file.url;
      const s3CustomerKey = data.file.customerKey;
      const s3CustomerAlgorithm = data.file.customerAlgorithm;
      const s3customerKeyMD5 = data.file.customerKeyMD5;
      const file = await InvoiceApi.downloadS3(url, data.file.name, s3CustomerKey, s3CustomerAlgorithm, s3customerKeyMD5);
      //extract data from invoice and send request to S3
    } catch (err) {
      dispatch(failedDownload(errorHandler(err)));
    }
  };
}

export function downloadStatusLog(url, s3CustomerKey, s3CustomerAlgorithm, s3CustomerKeyMD5){
  return async (dispatch) => {
    try {
      const file = await InvoiceApi.downloadCSVS3(url, s3CustomerKey, s3CustomerAlgorithm, s3CustomerKeyMD5);
      //extract data from invoice and send request to S3
    } catch (err) {
    }
  };
}

export function clearErrorMsgs(){
  return async (dispatch) => {
    dispatch({type:CLEAR_ERROR_MSGS});
  };
}

export const initialState = {
  invoices: [],
  isFetching: false,
  error: undefined
};

export default function invoicesReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCH:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      };
    case SUCCESSFUL_FETCH:
      let mapped_invoices;
      if(action.invoices){
        const format_invoices = formatInvoices(action.invoices.invoices);
        mapped_invoices = format_invoices;
      }
      else{
        const format_invoices = {[action.invoices.id]:action.invoices};
        mapped_invoices = _.mapKeys(format_invoices,(val) => val.id);
      }
      return {
        ...state,
        isFetching: false,
        invoices: {
          ...mapped_invoices
        },
        meta:action.invoices.meta
      };
    case FAILED_DOWNLOAD:
    case FAILED_FETCH:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case CLEAR_ERROR_MSGS:
      return{
        ...state,
        error:null
      }
    default:
      return state;
  }
}
