import '../../../_base.scss';
import '../Modals.scss';
import './ConfirmModal.scss';
import React from 'react';
import Modal from 'react-modal';
import _ from 'lodash';


export class ConfirmModal extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <Modal isOpen={this.props.showModal} className="common-modal confirm-modal">
        <div className="message-container">{this.props.msg}</div>
          <button className="button yes-btn" onClick={this.props.yes}>Yes</button>
          <button className="button no-btn" onClick={this.props.no}>No</button>
      </Modal>
    );
  }
}

export default ConfirmModal;
