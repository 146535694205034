import '../../../_base.scss'
import '../Modals.scss'
import './UploadCSV.scss'
import { GlobalStyle } from '../../../global-style'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Modal from 'react-modal'
import _ from 'lodash'
import DropZone from 'react-dropzone'
import { androidDelete } from 'react-icons-kit/ionicons/androidDelete'
import { Icon } from 'react-icons-kit'
import { uploadCSV, clearFiles } from '../../../redux/FilesRedux/FilesRedux'

export class UploadCSV extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadError: false
    }
  }

  onSelectFile = e => {
    this.props.showLoading()
    this.props.actions.uploadCSV(e.target.files, this.props.type, err => {
      if (err) {
        this.setState(() => ({
          uploadError: true
        }))
      }
      this.props.hideLoading()
    })
  }

  capitalize = str => {
    if (!str) {
      return ''
    }
    const newStr =
      str.substring(0, 1).toUpperCase() + str.substring(1, str.length)
    return newStr
  }

  handleCloseModal = () => {
    this.setState(() => ({
      uploadError: false
    }))
    this.props.closeModal()
  }

  clearAndClose = () => {
    this.props.actions.clearFiles()
    this.props.closeModal()
  }

  render() {
    const { log, files } = this.props.files
    const success_count = files
      ? _.size(_.filter(files, file => file.status === 'success'))
      : 0
    const failed_count = files
      ? _.size(_.filter(files, file => file.status === 'failed'))
      : 0
    const uploading_count = files
      ? _.size(_.filter(files, file => file.status === 'uploading'))
      : 0

    return (
      <Modal
        isOpen={this.props.showModal}
        className={
          _.isEmpty(this.props.files.files)
            ? 'common-modal upload-csv-modal'
            : 'common-modal upload-csv-list-modal'
        }
        ariaHideApp={false}
      >
        <div className='upload-section'>
          {_.isEmpty(this.props.files.files) && (
            <div>
              <div className='title'>{this.props.title}</div>
              <div className='content'>
                {!this.state.uploadError ? (
                  <p>Please select CSV.</p>
                ) : (
                  <p className='error-content'>
                    Files is invalid, please try again
                  </p>
                )}
              </div>
            </div>
          )}
          {!_.isEmpty(this.props.files.files) && (
            <div>
              <div className='title'>{this.props.title}</div>
              <div className='content'>
                <div className='table-section'>
                  <table>
                    {files &&
                      _.map(files, file => {
                        return (
                          <tr>
                            <td>{file.filename}</td>
                            <td>
                              <span className={file.status}>
                                {this.capitalize(file.status)}: refer to the log below
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        {_.isEmpty(this.props.files.files) && (
          <div className='action-buttons clearfix'>
            <button
              id='cancel-button'
              type='button'
              onClick={this.handleCloseModal}
            >
              <span>Cancel</span>
            </button>
            <span className='btn btn-file'>
              <div className='label'>Choose Files</div>{' '}
              <input
                type='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                multiple={this.props.multiple}
                onChange={this.onSelectFile}
              />
            </span>
          </div>
        )}
        {!_.isEmpty(files) && (
          <div className='action-buttons clearfix'>
            <button id='done-button' type='button' onClick={this.clearAndClose}>
              <span>Done</span>
            </button>
            <button
              id='cancel-button'
              type='button'
              onClick={() => {
                this.props.downloadLog(log)
              }}
            >
              <span>Download Status Log</span>
            </button>
          </div>
        )}
      </Modal>
    )
  }
}

UploadCSV.defaultProps = {
  title: 'UPLOAD CUSTOMER CSV',
  multiple: true
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ uploadCSV, clearFiles }, dispatch)
  }
}

export default connect(
  null,
  mapDispatchToProps
)(UploadCSV)
