import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Route, Switch } from 'react-router'
import { BrowserRouter, Redirect } from 'react-router-dom'
import { updateAppPath } from './redux/AuthRedux/AuthRedux'
import LoginContainer from './Auth/LoginContainer/LoginContainer'
import ForgotPassword from './Auth/ForgotPassword/ForgotPassword'
import Setup from './Auth/Setup/Setup'
import ResetPassword from './Auth/ResetPassword/ResetPassword'
import InvoiceContainer from './Invoice/InvoiceContainer'
import UsersContainer from './Users/UsersHome/UsersHome'
import ManageStaff from './Users/ManageStaff/ManageStaff'
import ManageCustomer from './Users/ManageCustomer/ManageCustomer'
import CustomerDetail from './Users/CustomerDetail/CustomerDetail'
import ProfileDetail from './Auth/ProfileDetail/ProfileDetail'
import EditProfile from './Auth/EditProfile/EditProfile'
import Header from './common/Header/Header'
import AuditContainer from './Audit/AuditContainer'
import ContactUs from './ContactUs/ContactUs'
import { NewAgreement, EditAgreement } from './Agreement'
import { Company, CompanyDetail, NewCompany, EditCompany } from './Company'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Http404Page from './Http404Page'
import './global-style.js'

const ProtectedRoute = ({
  component: Component,
  allowAll,
  isAdmin,
  loggedIn,
  updatePath,
  ...rest
}) => {
  updatePath()
  return (
    <Route
      {...rest}
      render={props =>
        loggedIn === true && (isAdmin || allowAll) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  )
}

export class App extends React.Component {
  onUpdatePath = () => {
    this.props.actions.updateAppPath(window.location.pathname)
  }

  render() {
    const { isAdmin } = this.props
    return (
      <div className='App clearfix'>
        <ToastContainer hideProgressBar={true} autoClose={1500} />
        <BrowserRouter>
          <div className='contentParentDiv clearfix'>
            <Header loggedIn={this.props.loggedIn} goToRoute={this.goToPath} />
            <div id='content' className='clearfix'>
              <Switch>
                <ProtectedRoute
                  allowAll={true}
                  loggedIn={this.props.loggedIn}
                  path='/auditlogs'
                  component={AuditContainer}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  allowAll={true}
                  loggedIn={this.props.loggedIn}
                  path='/invoices'
                  component={InvoiceContainer}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  allowAll={true}
                  loggedIn={this.props.loggedIn}
                  path='/profile/edit'
                  component={EditProfile}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  allowAll={true}
                  loggedIn={this.props.loggedIn}
                  path='/profile'
                  component={ProfileDetail}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/users/customers/edit/:id'
                  component={ManageCustomer}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/users/customers/new'
                  component={ManageCustomer}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/users/customers/:id'
                  component={CustomerDetail}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/users/admins/edit/:id'
                  component={ManageStaff}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/users/admins/new'
                  component={ManageStaff}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/users'
                  component={UsersContainer}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  exact={true}
                  path='/companies'
                  component={Company}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  exact={true}
                  path='/companies/new'
                  component={NewCompany}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/companies/edit/:id'
                  component={EditCompany}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/companies/:id/agreements/new'
                  component={NewAgreement}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/companies/:id/agreements/edit/:agreementId'
                  component={EditAgreement}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  isAdmin={isAdmin}
                  loggedIn={this.props.loggedIn}
                  path='/companies/:id'
                  component={CompanyDetail}
                  updatePath={this.onUpdatePath}
                />
                <Route
                  path='/setup'
                  loggedIn={this.props.loggedIn}
                  component={Setup}
                  updatePath={this.onUpdatePath}
                />
                <Route
                  path='/forgot'
                  loggedIn={this.props.loggedIn}
                  component={ForgotPassword}
                  updatePath={this.onUpdatePath}
                />
                <Route
                  path='/reset'
                  loggedIn={this.props.loggedIn}
                  component={ResetPassword}
                  updatePath={this.onUpdatePath}
                />
                <ProtectedRoute
                  allowAll={true}
                  path='/contactus'
                  loggedIn={this.props.loggedIn}
                  component={ContactUs}
                  updatePath={this.onUpdatePath}
                />
                <Route
                  path='/'
                  loggedIn={this.props.loggedIn}
                  component={LoginContainer}
                  updatePath={this.onUpdatePath}
                />
                <Route component={Http404Page} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateAppPath }, dispatch)
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    loggedIn: auth.loggedIn,
    isSuper: auth.isSuper,
    isAdmin:
      auth.auth &&
      auth.auth.user &&
      (auth.auth.user.role == 'Admin' || auth.auth.user.role == 'SuperAdmin')
        ? true
        : false
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
