import React from 'react'
import CompanyForm from '../CompanyForm'
import {
  createCompany,
  clearErrorMsgs
} from '../../redux/CompaniesRedux/CompaniesRedux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './styles.scss'

const NewCompany = ({ history, error, createCompany, clearError }) => {
  const _handleCancel = () => history.push('/companies')
  const _handleSubmit = data => {
    createCompany(data, () => {
      setTimeout(() => history.push('/companies'), 1000)
    })
  }

  return (
    <div id='new-company'>
      <CompanyForm
        error={error}
        onSubmit={_handleSubmit}
        onCancel={_handleCancel}
        clearError={clearError}
        title='Create New Company'
      />
    </div>
  )
}

export default connect(
  state => ({
    error: state.companies.error
  }),
  dispatch => ({
    createCompany: bindActionCreators(createCompany, dispatch),
    clearError: bindActionCreators(clearErrorMsgs, dispatch)
  })
)(NewCompany)
