import './UsersHome.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  GlobalStyle,
  ContentContainer,
  SectionTitle,
  WidthContent
} from '../../global-style'
import {
  fetchCustomers,
  clearErrorMsgs,
  deactivateUser,
  activateUser,
  deleteUser,
  clearCustMsgs,
  updatePageNumber,
  updateFilter,
  downloadStatusLog
} from '../../redux/CustomersRedux/CustomersRedux'
import MessageModal from '../../common/Modals/MessageModal/MessageModal'
import UploadCSV from '../../common/Modals/UploadCSV/UploadCSV'
import LoadingContainer from '../../Loading/components/LoadingContainer'
import ConfirmModal from '../../common/Modals/ConfirmModal/ConfirmModal'
import TableComponent from '../../common/TableComponent/TableComponent'
import Submenu from '../../common/Submenu/Submenu'
import Filter from '../../common/Filter/Filter'
import moment from 'moment'
import _ from 'lodash'
import classNames from 'classnames'

export class UsersContainer extends React.Component{
  constructor(props){
    super(props);
    let userType = 'Customer';
    const user_type = window.location.search.match(/^\?type=(.*)$/);
    if(user_type && user_type.length > 1){
      userType = user_type[1];
    }
    this.state = {
      menuItems: {
        Customer: {
          name: 'Customer',
          action: e => {
            this.onClickSubmenu(e, 'Customer')
          },
          selected: true
        },
        Admin: {
          name: 'Staff',
          action: e => {
            this.onClickSubmenu(e, 'Admin')
          },
          selected: false
        }
      },
      menuButtons: {
        Customer: [
          {
            name: '+ Add new customer',
            actionType: 'create',
            action: this.createNewCustomer,
            isDropdown: true,
            dropdownItems: [
              {
                label: 'Create Manually',
                action: () => {
                  this.createNewUser('customer', 'manual')
                }
              },
              {
                label: 'Upload.csv',
                action: () => {
                  this.createNewUser('customer', 'upload')
                }
              }
            ]
          }
        ],
        Admin: [
          {
            name: '+ Add new staff',
            actionType: 'create',
            action: () => {
              this.createNewUser('admin', 'manual')
            },
            isDropdown: false
          }
        ]
      },
      columns: {
        createdAt: {
          name: 'CREATED ON',
          attr: 'createdDate',
          title: 'createdAt',
          type: 'text',
          isSort: true,
          sortable: true
        },
        name: {
          name: 'NAME',
          attr: 'nameStr',
          title: 'name',
          type: 'text',
          bold: true,
          isSort: true,
          sortable: true,
          onClickHandle: id => this.viewCustomer(id)
        },
        email: {
          name: 'EMAIL',
          attr: 'emailStr',
          title: 'email',
          type: 'text',
          isSort: true,
          sortable: true,
        },
        company: {
          name: 'COMPANY',
          attr: 'companyArr',
          title: 'company',
          type: 'arrayLink',
          onClickHandle: id => this.viewCompany(id)
        },
        lastLogin: {
          name: 'Last Login',
          attr: 'lastLoginStr',
          title: 'lastLogin',
          type: 'text',
          isSort: true,
          sortable: true,
        },
        action: {
          name: 'ACTION',
          attr: 'action',
          type: 'arrayLink',
          action: true,
          values: [
            {
              label: 'Edit',
              onClickHandle: user => {
                this.editUser(user, 'edit')
              },
              color: '#fcb72d'
            },
            {
              label: 'Deactivate',
              onClickHandle: (user, action) => {
                this.editUser(user, action)
              },
              color: '#a9aaac'
            },
            {
              label: 'Delete',
              onClickHandle: user => {
                this.editUser(user, 'delete')
              },
              color: '#d0021b'
            }
          ]
        }
        /*,
        attachments:{name: 'ATTACHMENTS', attr:'attachments', title:'attachments', type:'imgText'}*/
      },
      customerColumns:{
        createdAt: {
          name: 'CREATED ON',
          attr:'createdDate',
          title:"createdAt",
          type:'text',
          isSort:true,
          sortable:true
        },
        name: {
          name: 'NAME',
          attr:'nameStr',
          title:'name',
          type:'text',
          bold:true,
          isSort:true,
          sortable:false,
          onClickHandle:(id) => this.viewCustomer(id)
        },
        email: {
          name: 'EMAIL',
          attr:'emailStr',
          title:'email',
          type:'text',
          isSort:true,
          sortable: true,
        },
        company: {
          name: 'COMPANY',
          attr:'companyArr',
          title:'company',
          type:'arrayLink',
          onClickHandle:(id) => this.viewCompany(id)
        },
        lastLogin: {
          name: 'Last Login',
          attr:'lastLoginStr',
          title:'lastLogin',
          type:'text',
          isSort:true,
          sortable: true,
        },
        action: {
          name: 'ACTION',
          attr:'action',
          type:'arrayLink',
          action:true,
          values:[
            {label:"Edit", onClickHandle:(user) => {this.editUser(user,'edit')}, color:'#fcb72d'},
            {label:"Deactivate", onClickHandle:(user, action) => {this.editUser(user,action)}, color:'#a9aaac'},
            {label:"Delete", onClickHandle:(user) => {this.editUser(user,'delete')}, color:'#d0021b'}
          ]},
        /*,
        attachments:{name: 'ATTACHMENTS', attr:'attachments', title:'attachments', type:'imgText'}*/
      },
      staffColumns:{
        createdAt: {name: 'CREATED ON', attr:'createdDate', title:"createdAt", type:'text', isSort:true, sortable:true },
        name: {name: 'NAME', attr:'nameStr', title:'name', type:'text', bold:true, isSort:true, sortable:true, onClickHandle:(id) => this.viewCustomer(id)},
        email: {name: 'EMAIL', attr:'emailStr', title:'email', type:'text', isSort:true, sortable: true},
        phone: {name: 'PHONE', attr:'phone', title:'phone', type:'text', isSort:false, sortable:true },
        lastLogin: {name: 'Last Login', attr:'lastLoginStr', title:'lastLogin', type:'text', isSort:true, sortable: true},
        action: {
          name: 'ACTION',
          attr:'action',
          type:'arrayLink',
          action:true,
          values:[
            {label:"Edit", onClickHandle:(user) => {this.editUser(user,'edit')}, color:'#fcb72d'},
            {label:"Deactivate", onClickHandle:(user, action) => {this.editUser(user,action)}, color:'#a9aaac'},
            {label:"Delete", onClickHandle:(user) => {this.editUser(user,'delete')}, color:'#d0021b'}
          ]},
        /*,
        attachments:{name: 'ATTACHMENTS', attr:'attachments', title:'attachments', type:'imgText'}*/
      },
      filterItems:{
        search: {label:'search', placeHolder:'Search by Name, Company', type:'text', value:'', withButton:true, onChangeHandle:(e) => {this.onSearchChange(e,'search','text')}},
        /*status: {label:'status', placeHolder:'Status', type:'dropdown',  options: [{value:'all',label:'All'},{value:'active',label:'Active'},{value:'deactivated',label:'Deactivated'}], value:null, onChangeHandle:(e) => {this.onSearchChange(e,'status','text');this.onFilter()}}*/
      },
      customerFilterItems:{
        search: {label:'search', placeHolder:'Search by Name, Company', type:'text', value:'', withButton:true, onChangeHandle:(e) => {this.onSearchChange(e,'search','text')}},
        /*status: {label:'status', placeHolder:'Status', type:'dropdown',  options: [{value:'all',label:'All'},{value:'active',label:'Active'},{value:'deactivated',label:'Deactivated'}], value:null, onChangeHandle:(e) => {this.onSearchChange(e,'status','text');this.onFilter()}}*/
      },
      staffFilterItems:{
        search: {label:'search', placeHolder:'Search by Name', type:'text', value:'', withButton:true, onChangeHandle:(e) => {this.onSearchChange(e,'search','text')}},
        /*status: {label:'status', placeHolder:'Status', type:'dropdown',  options: [{value:'all',label:'All'},{value:'active',label:'Active'},{value:'deactivated',label:'Deactivated'}], value:null, onChangeHandle:(e) => {this.onSearchChange(e,'status','text');this.onFilter()}}*/
      },
      filterButtons: {
        isSearch: false
      },
      showLoading:true,
      filterStr:'&orderBy=updatedAt&order=desc',
      showLastLogin:false,
      type:userType,
      showConfirmModal:false,
      confirm : {
        activate:{
          msg:'Are you sure you would like to activate this user?',
          action:'activate',
          id:''
        },
        deactivate: {
          msg: 'Are you sure you would like to deactivate this user?',
          action: 'deactivate',
          id: ''
        },
        delete: {
          msg: 'Are you sure you would like to delete this user?',
          action: 'delete',
          id: ''
        }
      },
      confirmAction: ''
    }
  }

  componentDidMount(){
    const { customers } = this.props;
    this.props.actions.fetchCustomers(`?type=${this.state.type}&${(window.location.search).includes('isBack') ? customers.filter : '&order=desc&orderBy=updatedAt'}&page=${(window.location.search).includes('isBack') ? customers.page : 1}`,() => {
      this.setState({showLoading:false});
    });
    const { type, menuItems } = this.state;
    if(type){
      _.forEach(menuItems,(item, key) => {
        if(key === type){
          item.selected = true;
        }
        else{
          item.selected = false;
        }
      });
      this.setState({menuItems});
    }
    if((window.location.search).includes('isBack')){
      const matches = (customers.filter).match(/.*q=([^&]*)/);
      if(type === 'Customer'){
        let filterItems = this.state.customerFilterItems;
        filterItems['search'].value = (matches && matches.length>1) ? matches[1] : '';
        this.setState({filterItems, columns: this.state.customerColumns});
      }
      else{
        let filterItems = this.state.staffFilterItems;
        filterItems['search'].value = (matches && matches.length>1) ? matches[1] : '';
        this.setState({filterItems, columns:this.state.staffColumns});
      }
    }
    this.props.actions.updateFilter(this.state.filterStr);
  }

  fetchUsers = filterStr => {
    const { type } = this.state
    if (type === 'Customer') {
      this.props.actions.fetchCustomers(filterStr)
    } else {
      this.props.actions.fetchStaff(filterStr)
    }
  }

  createNewUser = (type, method) => {
    if (type === 'customer' && method === 'upload') {
      this.setState({ showUploadModal: true })
      //show upload modal
    } else if (type === 'customer') {
      this.props.history.push('/users/customers/new')
    } else if (type === 'admin') {
      this.props.history.push('/users/admins/new')
    }
  }

  editUser = (user, action) => {
    const path = this.state.type === 'Customer' ? 'customers' : 'admins'
    if (action === 'edit') {
      this.props.history.push(`/users/${path}/edit/${user.id}`)
    } else {
      let { confirm } = this.state
      confirm[action.toLowerCase()].id = user.id
      this.setState({
        confirmAction: action.toLowerCase(),
        showConfirmModal: true,
        confirm
      })
    }
  }

  viewCustomer = user => {
    if (this.state.type === 'Customer') {
      this.props.history.push(`/users/customers/${user.id}`)
    }
  }

  handleConfirm = () => {
    this.setState({showLoading:true, showConfirmModal:false});
    let { confirm, confirmAction } = this.state;
    let currentAction = confirm[confirmAction] ? confirm[confirmAction] : null;
    if(currentAction.action && currentAction.id){
      this.props.actions[`${currentAction.action}User`](currentAction.id,() => {
        currentAction.id='';
        confirm = {...confirm,[this.state.confirmAction]:currentAction};
        if(currentAction.action === 'delete'){
          this.onFilter();
        }
        this.setState({showLoading:false, confirmAction:'', confirm});
      });
    }
    else{
      this.setState({showLoading:false, confirmAction:''});
    }
  }

  cancelConfirm = () => {
    this.setState({ showConfirmModal: false, confirmAction: '' })
  }

  onClickSubmenu = (e, type) => {
    let { menuItems, filterItems, columns } = this.state;
    filterItems['search'].value = '';
    filterItems['search'].placeHolder = (type === 'Customer') ? 'Search by Name, Company' : 'Search by Name';
    _.forEach(menuItems,(item) => {
      item.selected = false;
    });
    menuItems[type].selected = true;
    if(type === 'Customer'){
      columns = this.state.customerColumns;
    }
    else{
      columns = this.state.staffColumns;
    }
    this.setState({type, menuItems, columns, filterItems, filterStr:'&orderBy=updatedAt&order=desc', showLoading:true},() => {
      this.props.actions.updateFilter(this.state.filterStr);
      this.props.actions.updatePageNumber(1);
      this.props.actions.fetchCustomers(`?type=${type}${this.state.filterStr}&page=1`, () => {
        this.setState({showLoading:false});
      });
    });
  }

  handlePageClick = data => {
    this.setState({ showLoading: true });
    const { filterItems } = this.state;
    let filterValue = filterItems.search.value ? `&q=${filterItems.search.value}` : '';
    if(this.state.filterStr.includes('q=')){
      filterValue = '';
    }
    this.props.actions.updatePageNumber(data.selected+1);
    this.props.actions.fetchCustomers(
      `?type=${this.state.type}${this.state.filterStr}${filterValue}&page=${data.selected +
        1}`,
      () => {
        this.setState({ showLoading: false })
      }
    )
  }

  onFilter = () => {
    this.setState({showLoading:true});
    const selected_column = _.find(this.state.columns, (col) => (col.isSort && (col.isSortAsc || col.isSortDesc)));
    let filterStr;
    if(selected_column){
      filterStr = `&order=${selected_column.isSortAsc ? 'asc' : 'desc'}&orderBy=${selected_column.title}`;
    }
    else{
      filterStr = '&orderBy=updatedAt&order=desc';
    }
    _.forEach(this.state.filterItems,(item,key) => {
      if(key === 'search' && item.value){
        filterStr = filterStr + `&q=${item.value}`;
      }
      else if(key=== 'status' && item.value){
        filterStr = filterStr + `&status=${item.value}`;
      }
    })
    this.props.actions.fetchCustomers(
      `?type=${this.state.type}${filterStr}`,
      () => {
        this.setState({ showLoading: false })
      }
    )

    this.props.actions.updateFilter(filterStr);
    this.setState({ filterStr })
  }

  sort = (colName, order) => {
    let filterStr = ''
    let { columns } = this.state
    let selected_column = _.find(this.state.columns, col => col.title === colName);
    if(((order == 'desc') && selected_column.isSortDesc) || ((order == 'asc' && selected_column.isSortAsc))){
      filterStr = '&order=desc&orderBy=updatedAt';
      selected_column.isSortAsc = false
      selected_column.isSortDesc = false
      _.forEach(columns, col => {
        if (col.sortable) {
          col.isSortAsc = false
          col.isSortDesc = false
        }
      })
    }
    else{
      _.forEach(columns, col => {
        if (col.sortable) {
          col.isSortAsc = false
          col.isSortDesc = false
        }
      })
      if (order) {
        if (order === 'asc') {
          columns[colName].isSortAsc = true
          columns[colName].isSortDesc = false
          filterStr =
            filterStr +
            `&order=${order}&orderBy=${
              colName === 'createdDate' ? 'createdAt' : colName
            }`
        } else if (order === 'desc') {
          columns[colName].isSortAsc = false
          columns[colName].isSortDesc = true
          filterStr =
            filterStr +
            `&order=${order}&orderBy=${
              colName === 'createdDate' ? 'createdAt' : colName
            }`
        }
      } else {
        columns[colName].isSortAsc = false
        columns[colName].isSortDesc = false
      }
    }

    this.setState({ showLoading: true })
    this.props.actions.updatePageNumber(1);
    const { filterItems } = this.state;
    const filterValue = filterItems.search.value ? `&q=${filterItems.search.value}` : '';
    this.props.actions.fetchCustomers(
      `?type=${this.state.type}${filterValue}` + filterStr + '&page=1',
      () => {
        this.setState({ showLoading: false })
      }
    )
    this.props.actions.updateFilter(filterStr);
    this.setState({ columns, filterStr })
  }

  onUpload = () => {
    this.setState({ showUploadModal: true })
  }

  onSearchChange = (e, param, type, selectedMonth, selectedYear) => {
    let { filterItems } = this.state
    if (type === 'text') {
      filterItems[param].value = e.target.value
    }
    if (type === 'status') {
      filterItems[param].value = e.target.value
    }
    this.setState({ filterItems })
  }

  downloadLog = log => {
    const { url, customerKey, customerAlgorithm, customerKeyMD5 } = log
    this.props.actions.downloadStatusLog(
      url,
      customerKey,
      customerAlgorithm,
      customerKeyMD5
    )
    //console.log('log: ', log);
  }

  viewCompany = userInfo => {
    if (!userInfo.companies) return null
    const companyId = userInfo.companies[0].id
    this.props.history.push(`/companies/${companyId}`)
  }

  render() {
    const confirmMsg =
      this.state.confirmAction && this.state.confirm[this.state.confirmAction]
        ? this.state.confirm[this.state.confirmAction].msg
        : ''
    return (
      <ContentContainer id="users-container"
        className={classNames({'customers-container': this.state.type === 'Customer',
                                'staffs-container': this.state.type === 'Admin'})}>
        <LoadingContainer showLoading={this.state.showLoading} />
        <ConfirmModal
          showModal={this.state.showConfirmModal}
          msg={confirmMsg}
          yes={this.handleConfirm}
          no={this.cancelConfirm}
        />
        <MessageModal
          showModal={this.state.showLastLogin}
          msg={
            <div>
              Welcome to Goldbell Portal. <br /> Your last login date and time
              is : <br />
              <b>{this.state.lastLoginMsg} SGT</b>
            </div>
          }
          ok={() => this.setState({ showLastLogin: false })}
        />
        <SectionTitle>
          <div>Users</div>
        </SectionTitle>
        <Submenu
          items={this.state.menuItems}
          buttons={this.state.menuButtons[this.state.type]}
        />
        <WidthContent>
          <Filter
            id="users-filter"
            filters={this.state.filterItems}
            buttons={this.state.filterButtons}
            onFilter={this.onFilter}
            onUpload={this.onUpload}
            title={'Upload Invoices'}
          />
          <TableComponent
            id="users-table"
            columns={this.state.columns}
            data={this.props.customers.customers}
            meta={this.props.customers.meta}
            handlePageClick={this.handlePageClick}
            sort={this.sort}
          />
          <UploadCSV
            id="upload-csv-window"
            type="customer"
            showModal={this.state.showUploadModal}
            files={this.props.files}
            downloadLog={this.downloadLog}
            showLoading={() => {
              this.setState({ showLoading: true })
            }}
            hideLoading={() => {
              this.setState({ showLoading: false })
            }}
            closeModal={() => {
              this.setState({ showUploadModal: false })
            }}
          />
        </WidthContent>
      </ContentContainer>
    )
  }
}

function mapStateToProps({ auth, customers, files, staff }) {
  return {
    auth: auth,
    customers: customers,
    files,
    staff
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchCustomers,
        clearErrorMsgs,
        clearCustMsgs,
        activateUser,
        deactivateUser,
        deleteUser,
        downloadStatusLog,
        updatePageNumber,
        updateFilter,
      },
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersContainer)
