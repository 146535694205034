import './Filter.scss'
import React from 'react'
import { DownloadButton } from '../Buttons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-month-picker-input/dist/react-month-picker-input.css'
import MultiSelectField from '../FormComponent/MultiSelectField'
import MonthPickerInput from 'react-month-picker-input'
import uploadImg from '../../assets/upload-icon.png'
import { Icon } from 'react-icons-kit'
import { calendar } from 'react-icons-kit/fa/calendar'
import _ from 'lodash'
import moment from 'moment'

class Filter extends React.Component {
  constructor(props) {
    super(props)
  }

  renderTextFilter = (filter, index) => {
    return (
      <div key={index} className='filter-holder text-filter'>
        {/*  <Throttle time="500" handler="onChange">}*/}
        <input
          type='text'
          placeholder={filter.placeHolder}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              this.props.onFilter()
            }
          }}
          onChange={e => {
            filter.onChangeHandle(e)
          }}
          value={filter.value}
        />
        {filter.withButton && (
          <button
            className='search-button'
            onClick={() => this.props.onFilter()}
          >
            Search
          </button>
        )}
        {/*}  </Throttle>*/}
      </div>
    )
  }

  renderCheckboxFilter = (filter, index) => {
    return (
      <div key={index} className='filter-holder checkbox-filter'>
        <input type='checkbox' onClick={filter.onClickHandle} />
        {filter.label}
      </div>
    )
  }

  renderDropdownFilter = (filter, index) => {
    return (
      <div key={index} className='filter-holder dropdown-filter'>
        <select onChange={filter.onChangeHandle}>
          {_.map(filter.options, ({ value, label }, index) => {
            return (
              <option key={index} value={value}>
                {label}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  renderDateFilter(filter, index) {
    const val = filter.selected || filter.value

    return (
      <div id={index} className='filter-holder date-filter'>
        {filter.name && <label>{filter.name}</label>}
        <DatePicker
          isClearable={filter.isClearable}
          placeholderText={filter.placeholderText}
          dateFormat={filter.dateFormat}
          onChange={filter.onChangeHandle}
          selected={val}
        />
      </div>
    )
  }

  renderMonthPicker(filter, index) {
    return (
      <div id={index} className='filter-holder month-filter'>
        <label>
          <MonthPickerInput
            year={moment(filter.value, 'DD/MM/YYYY').year()}
            month={moment(filter.value, 'DD/MM/YYYY').month()}
            onChange={(value, selectedYear, selectedMonth) => {
              filter.onChangeHandle(value, selectedYear, selectedMonth)
            }}
          />
          <div className='calendar-icon'>
            <Icon className='calendar' icon={calendar} />
          </div>
        </label>
      </div>
    )
  }

  renderMultiselectField(filter, index) {
    let input = {
      label: filter.label
    }
    return (
      <div key={index} className='filter-holder multiselect-filter'>
        <MultiSelectField
          placeHolder={filter.placeHolder}
          onChangeHandle={filter.onChangeHandle}
          options={filter.options}
          value={filter.value}
          input
        />
      </div>
    )
  }

  //props => menu items => {name:'text name', actionCall: 'function to call'}, action buttons:{name: 'button name', actionCall:{funcion to call on click}}
  render() {
    const { buttons, className } = this.props
    return (
      <div className={className ? `filter ${className}` : 'filter'}>
        {_.map(this.props.filters, (filter, index) => {
          if (filter.type === 'text') {
            return this.renderTextFilter(filter, index)
          } else if (filter.type === 'checkbox') {
            return this.renderCheckboxFilter(filter, index)
          } else if (filter.type === 'dropdown') {
            return this.renderDropdownFilter(filter, index)
          } else if (filter.type === 'month') {
            // return this.renderDateFilter(filter, index)
            return this.renderMonthPicker(filter, index)
          } else if (filter.type === 'multiselect') {
            return this.renderMultiselectField(filter, index)
          } else if (filter.type === 'date') {
            return this.renderDateFilter(filter, index)
          }
        })}
        {buttons.isSearch && (
          <button
            className='search-button'
            onClick={() => this.props.onFilter()}
          >
            {buttons.isSearch.text}
          </button>
        )}
        {buttons.isUpload && (
          <button
            className='upload-button'
            onClick={() => this.props.onUpload()}
          >
            <img src={uploadImg} />
            {buttons.isUpload.text}
          </button>
        )}
        {buttons.isDownload && (
          <DownloadButton
            className='float-right'
            text={buttons.isDownload.text}
            onClick={this.props.onDownload}
          />
        )}
      </div>
    )
  }
}

export default Filter
