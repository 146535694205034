import '../../../_base.scss';
import '../Modals.scss';
import './OptionModal.scss';
import { GlobalStyle } from '../../../global-style';
import React from 'react';
import Modal from 'react-modal';
import _ from 'lodash';


export class OptionModal extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      optIn:props.company && props.company.isEmailOptedIn && props.company.emailSetting.isEmailOptedIn,
      notification:(props.company && (props.company.emailSetting.emailNotification === 'individual')) ? 'individual' : 'multiple'
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const notification = (nextProps.company && (nextProps.company.emailSetting.emailNotification === 'individual')) ? 'individual' : 'multiple';
    const optIn = (nextProps.company && nextProps.company.isEmailOptedIn && nextProps.company.emailSetting.isEmailOptedIn) ? true: false;
    this.setState({optIn, notification});
  }

  onClickRadio = (val, type) => {
    this.setState({[`${type}`]:val});
  }

  render(){
    return(
      <Modal isOpen={this.props.showModal} className="common-modal option-modal">
        <div className="message-container">
          <div className="title">Email Notification Settings</div>
          <div className="option-container">
            <div className="option-item">
              <input onChange={()=>this.onClickRadio(true, 'optIn')} type="radio" id="optin" name="option" value="optin"
                     checked={this.state.optIn} />
              <label for="optin">Opt-In</label>
            </div>
            <div className="option-item">
              <input type="radio" onChange={()=>this.onClickRadio(false, 'optIn')} id="optout" name="option" value="optout" checked={!this.state.optIn} />
              <label for="optout">Opt-Out</label>
            </div>
          </div>

          {!this.state.optIn &&
            <div className="option-detail-container">
            <p>* By choosing to opt-out, you will NOT be alerted when your invoice is ready.</p>
            <p>You are hereby acknowledging that you will soley be responsible for payment of invoices to GoldBell on time.</p>
            </div>
          }
          {this.state.optIn &&
            <div className="option-detail-container">
              <p>Notification Option</p>
              <div className="email-notification-box">
                <div className="notification-item">
                  <input onChange={()=>this.onClickRadio('individual', 'notification')} type="radio" id="individual" name="notification" value="individual"
                         checked={(this.state.notification === 'individual')} />
                  <label for="individual">Notify individually with attachment</label>
                </div>
                <div className="notification-item">
                  <input type="radio" onChange={()=>this.onClickRadio('multiple', 'notification')} id="multiple" name="notification" value="multiple" checked={(this.state.notification === 'multiple')} />
                  <label for="multiple">Notify with all invoices and attachments</label>
                </div>
              </div>
            </div>
          }

        </div>
        <div className="button-container">
          <button className="button no-btn" onClick={this.props.no}>Cancel</button>
          <button className="button yes-btn" onClick={() => this.props.yes(this.state.optIn, this.state.notification)}>Save</button>
        </div>
      </Modal>
    );
  }
}

export default OptionModal;
