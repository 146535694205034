import React, { useState } from 'react'
import MultiSelectField from '../common/FormComponent/MultiSelectField'
import { Formik } from 'formik'
import {
  ContentContainer,
  WidthContent,
  Form,
  Label,
  BackLink,
  Title,
  Error,
  Input,
  Text,
  FormItem,
  InnerDiv,
  ButtonContainer
} from '../global-style'
import { Icon } from 'react-icons-kit'
import { chevronLeft } from 'react-icons-kit/fa/chevronLeft'
import { close } from 'react-icons-kit/fa/close'

const entityList = [
  {
    value: 'S',
    label: 'STVE'
  },
  {
    value: 'A',
    label: 'AEL'
  },
  {
    value: 'C',
    label: 'GBCR'
  },
  {
    value: 'E',
    label: 'GBE'
  },
  {
    value: 'M',
    label: 'MV'
  }
]

const productList = [
  {
    value: 'CV',
    label: 'Commercial Vehicle'
  },
  {
    value: 'STVE',
    label: 'Material Handling Equipment'
  },
  {
    value: 'AEL',
    label: 'Ground Support Equipment'
  },
  {
    value: 'GBCR',
    label: 'Passenger Vehicle'
  },
  {
    value: 'ALL',
    label: 'All service packages (e.g BMP,MCP)'
  }
]

const termList = [
  {
    value: 'L',
    label: 'Long Term'
  },
  {
    value: 'S',
    label: 'Short Term'
  }
]

const AgreementForm = ({
  title,
  initValues,
  error,
  onCancel,
  onSubmit,
  clearError,
  userList
}) => {
  const [fields, setFields] = useState(
    initValues
      ? {
          code: initValues.code,
          users: initValues.users
        }
      : {
          entity: '',
          product: '',
          term: '',
          number: '',
          users: []
        }
  )
  const validate = values => {
    let errors = {}

    if (!initValues) {
      if (!values.entity) {
        errors.entity = 'Entity is required.'
      }
      if (!values.product) {
        errors.product = 'Product is required.'
      }
      if (!values.term) {
        errors.term = 'Term is required.'
      }
      if (!values.number) {
        errors.number = 'Number is required.'
      }
    } else {
      if (!values.code) {
        errors.code = 'Code is required.'
      }
    }

    if (!values.users || !values.users.length) {
      errors.users = 'Users is required.'
    }

    return errors
  }
  const onChangeHandle = (param, e) => {
    setFields({
      ...fields,
      [param]: param === 'number' || param === 'code' ? e.target.value : e
    })
  }
  const _handleSubmit = values => {
    onSubmit && onSubmit(values)
  }

  return (
    <ContentContainer>
      <BackLink onClick={onCancel}>
        <span style={{ color: '#a9aaac' }}>
          <Icon icon={chevronLeft} />
        </span>
        Back to Company Details
      </BackLink>
      <Title>{title}</Title>
      <InnerDiv>
        <WidthContent>
          <Formik
            initialValues={{ ...fields }}
            validate={validate}
            onSubmit={_handleSubmit}
            render={({ touched, errors, values, handleBlur, handleSubmit, handleChange, status }) => {
              values.users = fields.users;
              values.entity = fields.entity;
              values.product = fields.product;
              values.term = fields.term;
              return (
                <Form onSubmit={handleSubmit}>
                  {error && (
                    <Error>
                      {error}
                      <span style={{ color: '#a94442' }}>
                        <Icon icon={close} onClick={clearError} />
                      </span>
                    </Error>
                  )}
                  {initValues ? (
                    <div className='row'>
                      <div className='col-md-12 pb-3'>
                        <FormItem>
                          <Label>Agreement No.</Label>
                          <Input
                            placeholder=''
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.code}
                            border={
                              touched.code && errors.code && '1px solid red'
                            }
                            type='text'
                            name='code'
                          />
                          <Text
                            color='red'
                            display={!!(touched.code && errors.code)}
                          >
                            {errors.code}
                          </Text>
                        </FormItem>
                      </div>
                    </div>
                  ) : (
                    <div className='row'>
                      <div className='col-md-3'>
                        <FormItem firstItem={true}>
                          <Label>Entity</Label>
                          <MultiSelectField
                            name='entity'
                            values={values.entity}
                            type='text'
                            isSingle={true}
                            options={entityList}
                            onChangeHandle={(e) => onChangeHandle('entity',e)}
                            placeHolder=''
                          />
                          <Text
                            color='red'
                            display={!!(touched.entity && errors.entity)}
                          >
                            {errors.entity}
                          </Text>
                        </FormItem>
                      </div>
                      <div className='col-md-3'>
                        <FormItem firstItem={true}>
                          <Label>Product</Label>
                          <MultiSelectField
                            name='product'
                            values={values.product}
                            type='text'
                            isSingle={true}
                            options={productList}
                            onChangeHandle={(e) => onChangeHandle('product',e)}
                            placeHolder=''
                          />
                          <Text
                            color='red'
                            display={!!(touched.product && errors.product)}
                          >
                            {errors.product}
                          </Text>
                        </FormItem>
                      </div>
                      <div className='col-md-3'>
                        <FormItem firstItem={true}>
                          <Label>Term</Label>
                          <MultiSelectField
                            name='term'
                            values={values.term}
                            type='text'
                            isSingle={true}
                            options={termList}
                            onChangeHandle={(e) => onChangeHandle('term',e)}
                            placeHolder=''
                          />
                          <Text
                            color='red'
                            display={!!(touched.term && errors.term)}
                          >
                            {errors.term}
                          </Text>
                        </FormItem>
                      </div>
                      <div className='col-md-3'>
                        <FormItem firstItem={true}>
                          <Label>Number</Label>
                          <Input
                            placeholder='XXXXXXXXX'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.number}
                            border={
                              touched.number && errors.number && '1px solid red'
                            }
                            type='text'
                            name='number'
                          />
                          <Text
                            color='red'
                            display={!!(touched.number && errors.number)}
                          >
                            {errors.number}
                          </Text>
                        </FormItem>
                      </div>
                    </div>
                  )}
                  <div id="second-row" className='row'>
                    <div className='col-md-12'>
                      <FormItem firstItem={true}>
                        <Label>Users</Label>
                        <MultiSelectField
                          name='users'
                          values={values.users}
                          type='text'
                          options={userList}
                          onChangeHandle={(e) => onChangeHandle('users',e)}
                          placeHolder=''
                        />
                        <Text
                          color='red'
                          display={!!(touched.users && errors.users)}
                        >
                          {errors.users}
                        </Text>
                      </FormItem>
                    </div>
                  </div>
                  <ButtonContainer>
                    <button type='button' onClick={onCancel}>
                      Cancel
                    </button>
                    <button type='submit' className='submit'>
                      {initValues ? 'Save' : title}
                    </button>
                  </ButtonContainer>
                </Form>
              )
            }}
          />
        </WidthContent>
      </InnerDiv>
    </ContentContainer>
  )
}

export default AgreementForm
