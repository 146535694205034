import React, { useState, useEffect } from 'react'
import MultiSelectField from '../common/FormComponent/MultiSelectField'
import { Formik, Field } from 'formik'
import { RadioButton, RadioButtonGroup } from '../common/Buttons'
import {
  ContentContainer,
  WidthContent,
  Form,
  Label,
  BackLink,
  Title,
  Error,
  Input,
  Text,
  FormItem,
  InnerDiv,
  ButtonContainer
} from '../global-style'
import { Icon } from 'react-icons-kit'
import { chevronLeft } from 'react-icons-kit/fa/chevronLeft'
import { close } from 'react-icons-kit/fa/close'

const validate = values => {
  let errors = {}
  if (!values.name) {
    errors.name = 'Name is required.'
  }
  if (!values.code) {
    errors.code = 'Code is required.'
  }
  if (!values.street) {
    errors.street = 'Street name is required.'
  }
  // if (!values.postalcode) {
  //   errors.postalcode = 'Postal Code is required.'
  // }
  if(values.postalcode && !values.postalcode.match(/(\d)+/)){
    errors.postalcode = 'Please enter numbers.'
  }
  if (!values.country) {
    errors.country = 'Country is required.'
  }

  return errors
}

const listCountries = [
  {
    value: 'AUS',
    label: 'Australia'
  },
  {
    value: 'CHN',
    label: 'China'
  },
  {
    value: 'MYS',
    label: 'Malaysia'
  },
  {
    value: 'SGP',
    label: 'Singapore'
  },
  {
    value: 'GBR',
    label: 'United Kingdom'
  },
  {
    value: 'USA',
    label: 'United States'
  },
  {
    value: 'VNM',
    label: 'Viet Nam'
  },
  {
    value: 'OTHERS',
    label: 'Others'
  }
]

const CompanyForm = ({
  title,
  initValues,
  error,
  onCancel,
  onSubmit,
  clearError
}) => {
  const [fields, setFields] = useState({
      name: '',
      code: '',
      country: '',
      street: '',
      isOptIn: 'OPT_IN'
    }
  )

  useEffect(() => {
    if (initValues) {
      setFields({
        name: initValues.name,
          code: initValues.code,
        street: initValues.streetName,
        postalcode: initValues.postCode,
        country: listCountries.find(o => o.value === initValues.country),
        isOptIn: initValues.isEmailOptedIn ? 'OPT_IN' : 'OPT_OUT'
      })
    }
  }, [initValues]);


  const onChangeHandle = (param, e) => {
    setFields({
      ...fields,
      [param]: param === 'country' ? e : e.target.value
    })
  }
  const _handleSubmit = values => {
    onSubmit &&
      onSubmit({
        name: values.name,
        code: values.code,
        streetName: values.street,
        postCode: values.postalcode,
        country: values.country && values.country.value,
        isEmailOptedIn: values.isOptIn === 'OPT_IN'
      })
  }
  return (
    <ContentContainer>
      <BackLink onClick={onCancel}>
        <span style={{ color: '#a9aaac' }}>
          <Icon icon={chevronLeft} />
        </span>
        {initValues ? 'Back to Company Details' : 'Back to All Companies'}
      </BackLink>
      <Title>{title}</Title>

      <InnerDiv>
        <WidthContent>
          <Formik
            enableReinitialize={true}
            initialValues={{ ...fields }}
            validate={validate}
            onSubmit={_handleSubmit}
            render={({ touched, errors, values, handleBlur, handleSubmit, handleChange, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  {error && (
                    <Error>
                      {error}
                      <span style={{ color: '#a94442' }}>
                        <Icon icon={close} onClick={clearError} />
                      </span>
                    </Error>
                  )}
                  <FormItem>
                    <Label>Company Name</Label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      border={touched.name && errors.name && '1px solid red'}
                      type='text'
                      name='name'
                    />
                    <Text color='red' display={touched.name && errors.name}>
                      {errors.name}
                    </Text>
                  </FormItem>
                  <FormItem secondColumn={true}>
                    <Label>Company Code</Label>
                    <Input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                      border={touched.code && errors.code && '1px solid red'}
                      type='text'
                      name='code'
                    />
                    <Text color='red' display={touched.code && errors.code}>
                      {errors.code}
                    </Text>
                  </FormItem>
                  <FormItem secondRow={true}>
                    <Label>Address</Label>
                    <Input
                      placeholder='Street name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.street}
                      border={
                        touched.street && errors.street && '1px solid red'
                      }
                      type='text'
                      name='street'
                    />
                    <Text color='red' display={touched.street && errors.street}>
                      {errors.street}
                    </Text>
                  </FormItem>
                  <FormItem secondRow={true} secondColumn={true}>
                    <Label />
                    <Input
                      placeholder='Postal code'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalcode}
                      border={
                        touched.postalcode &&
                        errors.postalcode &&
                        '1px solid red'
                      }
                      type='text'
                      name='postalcode'
                    />
                    <Text
                      color='red'
                      display={touched.postalcode && errors.postalcode}
                    >
                      {errors.postalcode}
                    </Text>
                  </FormItem>
                  <FormItem secondRow={true}>
                    <MultiSelectField
                      name='country'
                      values={values.country}
                      type='text'
                      isSingle={true}
                      options={listCountries}
                      //onChangeHandle={(e) => onChangeHandle('country',e) }
                      onChangeHandle={(e) => {
                        setFieldValue('country', e);
                      }}
                      placeHolder='Country'
                    />
                    {touched.country && errors.country && (
                      <Text display={true} color='red'>{errors.country}</Text>
                    )}
                  </FormItem>
                  {!initValues && (
                    <FormItem secondRow={true} firstItem={true}>
                      <RadioButtonGroup
                        id='isOptIn'
                        label='EMAIL NOTIFICATION SETTINGS'
                        value={values.isOptIn}
                        error={errors.isOptIn}
                        touched={touched.isOptIn}
                      >
                        <Field
                          component={RadioButton}
                          name='isOptIn'
                          id='OPT_IN'
                          label='Opt-In'
                        />
                        <Field
                          component={RadioButton}
                          name='isOptIn'
                          id='OPT_OUT'
                          label='Opt-Out'
                        />
                      </RadioButtonGroup>
                      <Text color='red' display={values.isOptIn === 'OPT_OUT'}>
                        <span>Note: </span>This customer will not recieve email
                        for the invoice notification.
                      </Text>
                    </FormItem>
                  )}
                  <ButtonContainer>
                    <button type='button' onClick={onCancel}>
                      Cancel
                    </button>
                    <button className='submit' type="submit">
                      {initValues ? 'Save' : title}
                    </button>
                  </ButtonContainer>
                </Form>
              )
            }}
          />
        </WidthContent>
      </InnerDiv>
    </ContentContainer>
  )
}

export default CompanyForm
