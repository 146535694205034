import _ from 'lodash'
import * as AgreementsApi from '../../apis/AgreementsApi'
import { errorHandler } from '../../utils/errorHandler'

export const NAMESPACE = 'AGREEMENTS'

export const CLEAR_ERROR_MSGS = 'CLEAR_ERROR_MSGS'
export const CLEAR_MSGS = 'CLEAR_MSGS'

export const START_FETCH = `${NAMESPACE}__START_FETCH`
export const SUCCESSFUL_FETCH = `${NAMESPACE}__SUCCESSFUL_FETCH`
export const SUCCESSFUL_FETCH_ID = `${NAMESPACE}__SUCCESSFUL_FETCH_ID`
export const FAILED_FETCH = `${NAMESPACE}__FAILED_FETCH`

const START_UPDATE = `${NAMESPACE}__START_UPDATE`
const SUCCESSFUL_UPDATE = `${NAMESPACE}__SUCCESSFUL_UPDATE`
const FAILED_UPDATE = `${NAMESPACE}__FAILED_UPDATE`

export const START_CREATE = `${NAMESPACE}__START_CREATE`
export const SUCCESSFUL_CREATE = `${NAMESPACE}__SUCCESSFUL_CREATE`
export const FAILED_CREATE = `${NAMESPACE}__FAILED_CREATE`

export const CLEAR_AGREEMENTS = `${NAMESPACE}__CLEAR_AGREEMENTS`

export const startFetch = () => ({ type: START_FETCH })
export const successfulFetch = agreements => ({
  type: SUCCESSFUL_FETCH,
  agreements
})
export const successfulFetchById = agreement => ({
  type: SUCCESSFUL_FETCH_ID,
  agreement
})
export const failedFetch = error => ({ type: FAILED_FETCH, error })

export function fetchAgreements(filterStr, callback) {
  return async dispatch => {
    dispatch(startFetch())
    if (callback) {
      callback()
    }
    try {
      const agreements = await AgreementsApi.getAgreements(filterStr)
      dispatch(successfulFetch(agreements))
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)))
    }
  }
}

export function getAgreement(id) {
  return async (dispatch, getState) => {
    const state = getState()

    if (state.agreements.isFetching) {
      return null
    }
    dispatch(startFetch())

    try {
      const agreement = await AgreementsApi.getAgreement(id)
      dispatch(successfulFetchById(agreement))
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)))
    }
  }
}

export function updateAgreement(id, agreement, callback) {
  return async dispatch => {
    dispatch({
      type: START_UPDATE
    })

    try {
      const data = await AgreementsApi.updateAgreement(id, agreement)

      dispatch({
        type: SUCCESSFUL_UPDATE
      })

      callback()
    } catch (res) {
      return dispatch({
        type: FAILED_UPDATE,
        error: res.response.data.error
      })
    }
  }
}

export function createAgreement(agreement, callback) {
  return async dispatch => {
    dispatch({
      type: START_CREATE
    })

    try {
      const data = await AgreementsApi.createAgreement(agreement)

      dispatch({
        type: SUCCESSFUL_CREATE,
        payload: data
      })

      callback()
    } catch (res) {
      return dispatch({
        type: FAILED_CREATE,
        error: res.response.data.error
      })
    }
  }
}

export function clearAgreements() {
  return async dispatch => {
    dispatch({
      type: CLEAR_AGREEMENTS
    })
  }
}

export function clearErrorMsgs() {
  return async dispatch => {
    dispatch({ type: CLEAR_ERROR_MSGS })
  }
}

export function clearCustMsgs() {
  return async dispatch => {
    dispatch({ type: CLEAR_MSGS })
  }
}

export const initialState = {
  agreements: [],
  isFetching: false,
  error: undefined
}

export default function agreementsReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCH:
    case START_CREATE:
    case START_UPDATE:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      }

    case SUCCESSFUL_CREATE:
      return {
        ...state,
        isFetching: false,
        error: undefined
      }

    case SUCCESSFUL_FETCH_ID:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        agreement: action.agreement
      }

    case SUCCESSFUL_UPDATE:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        agreement: null
      }

    case SUCCESSFUL_FETCH:
      let mapped_agreements = _.mapKeys(
        action.agreements.agreements,
        ag => ag.id
      )
      return {
        ...state,
        isFetching: false,
        agreements: {
          ...mapped_agreements
        },
        meta: action.agreements.meta
      }

    case FAILED_FETCH:
    case FAILED_CREATE:
    case FAILED_UPDATE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case CLEAR_ERROR_MSGS:
      return {
        ...state,
        error: null
      }
    case CLEAR_MSGS:
      return {
        ...state,
        msg: null
      }
    case CLEAR_AGREEMENTS: {
      return {
        ...state,
        agreements: {}
      }
    }
    default:
      return state
  }
}
