import axios from 'axios'
import { axiosInstance } from '../../redux/AuthRedux/axiosInterceptors'
import moment from "moment/moment";

const baseUrl = `${process.env.REACT_APP_CMS_API_URL}companies`
export const REFRESH_FAILURE = 'REFRESH_FAILURE'

export async function getCompanies(filterStr) {
  const response = await axiosInstance.get(
    `${baseUrl}${filterStr ? filterStr : ''}`
  )
  return response.data
  /*const expected_response = {
    "accessToken": "ewfwef890w8ef9ew8few9f8ew9f89ef8ef8e90wf",
    "refreshToken": "wefwef8ew9f8ew90f8ew08f0wef809ewf"
  };
  return expected_response;*/
}

export async function createCompany(data) {
  const response = await axiosInstance.post(baseUrl, data)

  return response.data
}

export async function updateCompany(id, data) {
  const response = await axiosInstance.put(`${baseUrl}/${id}`, data)

  return response.data
}

export async function getCompanyById(id) {
  const response = await axiosInstance.get(`${baseUrl}/${id}`)
  return response.data
}

export async function getUsersByCompany(id, queryString = '') {
  const response = await axiosInstance.get(
    `${baseUrl}/${id}/users` + queryString
  )
  return response.data
}

export async function getAgreementsByCompany(id, queryString = '') {
  const response = await axiosInstance.get(
    `${baseUrl}/${id}/agreements` + queryString
  )
  return response.data
}

export async function downloadCSVS3(link, s3CustomerKey, s3CustomerAlgorithm, s3customerKeyMD5){
  const response = await axios.get(link, {
    headers:{
      'x-amz-server-side-encryption-customer-key' : s3CustomerKey,
      'x-amz-server-side-encryption-customer-algorithm':s3CustomerAlgorithm,
      'x-amz-server-side-encryption-customer-key-MD5':s3customerKeyMD5
    }});
  var data = '"Company Code","Status","Error"';
  response.data.forEach((file) => {
    data += '\r\n';
    data += `"${file.companyCode}","${file.status}","${file.error ? file.error : ''}"`;
  });
  downloadFile(`StatusLog_${moment().format('DDMMYYYY')}.csv`, 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data));
  return 'success!';
}

function downloadFile(fileName, urlData) {

  var aLink = document.createElement('a');
  aLink.download = fileName;
  aLink.href = urlData;

  var event = new MouseEvent('click');
  aLink.dispatchEvent(event);
}
