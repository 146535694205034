import './LoadingContainer.scss';
import React from 'react';
import loadingImg from '../assets/loading1.gif';

class LoadingContainer extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    return(<div className={this.props.showLoading ? "loading-div" : "loading-div hide-div"}><img className="loading-img" src={loadingImg} /></div>);
  }
}

export default LoadingContainer;
