import styled from 'styled-components'

export const TableContainer = styled.div`
  padding-bottom: 50px;
`

export const Table = styled.table`
  background-color: #ffffff;

  thead {
    border-top: none;
  }
`

export const FloatLeft = styled.div`
  float: left;
`

export const SortAscImg = styled.img`
  cursor: pointer;
  position: relative;
  top: 2px;
  display: block;
  float: right;
  opacity: ${props => (props.isSortAsc ? 1 : 0.4)};
`
//.${props => (props.isSortAsc ? 'block' : 'none')};
export const SortDescImg = styled.img`
  cursor: pointer;
  position: relative;
  top: 9px;
  display: block;
  float: right;
  left: 12px;
  opacity: ${props => (props.isSortDesc ? 1 : 0.4)};
`
//:${props => (props.isSortDesc ? 'block' : 'none')};
export const TableHead = styled.th`
  padding: 20px;
  font-family: VistaSansOT;
  font-size: 12px;
  font-weight: bold;
  color: #2d2926;
  text-align: center;
  border-bottom: 2px solid #fcb72d !important;
  border-top: none !important;
  &:hover ${SortAscImg} {
    display: block;
  }
  &:hover ${SortDescImg} {
    display: block;
  }
`
export const TableCell = styled.td`
  padding: 20px;
  width: ${props => (props.userAction ? '183px' : 'auto')};
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  color: #2d2926;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  cursor: ${props => (props.clickable ? 'pointer' : 'unset')};
  text-align: left;
  vertical-align: middle !important;

  & label {
    margin-right: 10px;
  }
  & label:last-child {
    margin-right: 0;
  }
`

export const Label = styled.label`
  color: ${props => (props.color ? props.color : 'inherit')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-family: ${props => (props.bold ? 'VistaSansOT' : 'VistaSansOT-Reg')};
  font-size: 14px;
  cursor: pointer;
`

export const LinkImg = styled.img`
  cursor: pointer;
`
