import styled, { createGlobalStyle } from 'styled-components'

import VistaSansOTReg from './assets/29df5bb8d97307eae7f595d7636e9845.ttf'
import VistaSansOT from './assets/059c3056cca560f88a6bf8af861752b4.ttf'
export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'VistaSansOT';
    src: url(${VistaSansOT}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
	@font-face {
    font-family: 'VistaSansOT-Reg';
    src: url(${VistaSansOTReg}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  height: autp;
  margin: auto;
  background-color: #ffffff;
  margin-bottom: 50px;
`

export const WidthContent = styled.div`
  width: 1023px;
  margin: auto;
`

const SectionCommon = styled.div`
  font-family: VistaSansOT;
  font-size: 20px;
  font-weight: bold;
  color: #2d2926;
  padding-bottom: 20px;
  width: 100%;
  background-color: #fafafa;
  padding-top: 27px;
`
export const SectionTitle = styled(SectionCommon)`
  & div {
    width: 1023px;
    margin: auto;
  }
`

export const SectionTitleWithSubAction = styled(SectionCommon)`
  .content-wrapper {
    width: 1023px;
    display: flex;
    margin: auto;
    justify-content: space-between;
  }
`

export const Error = styled.div`
  background-color: #f2dede;
  padding: 15px;
  width: 100%;
  margin-bottom: 12px;
  color: #a94442;
  border-radius: 4px;
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  & span {
    float: right;
    cursor: pointer;
  }
`

export const Message = styled.div`
  background-color: #dff0d8;
  padding: 15px;
  width: 100%;
  color: #3c763d;
  border-radius: 4px;
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  & span {
    float: right;
    cursor: pointer;
  }
`

export const BackLink = styled.div`
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  color: #2d2926;
  padding-bottom: 17px;
  cursor: pointer;
  width: 1023px;
  margin: auto;
  margin-top: 17px;
  > span {
    color: #a9aaac;
    margin-right: 5px;
  }
`

export const Form = styled.form`
  width: 945px;
  height: auto;
  border-radius: 4px;
  border: solid 1px #dcdcdc;
  background-color: #f9f9f9;
  padding-left: 74px;
  padding-right: 74px;
  padding-top: 33px;
  padding-bottom: 30px;
  margin: auto;
`

export const Label = styled.label`
  font-size: 12px;
  font-weight: bold;
  color: #53565a;
  width: 100%;
  min-height: 18px;
  text-transform: uppercase;
`

export const Title = styled.div`
  font-family: VistaSansOT;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #53565a;
  padding-bottom: 36px;
`

export const FormItem = styled.div`
  width: ${props => (props.firstItem ? '100%' : '385px')};
  float: left;
  padding-left: ${props => (props.secondColumn ? '22px' : '0px')};
  padding-top: ${props => (props.secondRow ? '32px' : '0px')};

  & .radio-option {
    width: 120px;
    float: left;
    & label {
      padding-left: 12px;
      font-family: VistaSansOT;
      font-size: 14px;
      color: #2d2926;
    }
  }

  & .multiselect-field > div > div:first-child > div:first-child > div {
    border: ${props =>
      props.isEmpty ? 'none ! important' : 'solid 1px #fcb72d ! important'};
    background-color: ${props =>
      props.isEmpty ? 'transparent ! important' : '#fff3da ! important'};
    font-family: VistaSansOT !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: #fcb72d !important;
  }
  &
    .multiselect-field
    > div
    > div:first-child
    > div:first-child
    > div
    > div:first-child {
    font-family: VistaSansOT !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: #fcb72d !important;
  }
  & .multiselect-field > div > div > div:first-child > div:last-child {
    border: none !important;
    background-color: transparent !important;
  }
  &.multiselect-field {
    & svg {
      color: #fcb72d !important;
    }
  }
`

export const Text = styled.div`
  color: ${props => props.color || '#4d4d4d'}
  margin-bottom:-24px;
  height:24px;
  font-size:13px;
  display:${props => (props.display ? 'block' : 'none')}
  & span{
    font-weight:bold;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 35px;
  padding-left: 10px;
  border-radius: 4px;
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
`

export const ButtonContainer = styled.div`
  width: 470px;
  margin: auto;
  padding-top: 63px;
  display: inline-block;
  margin-left: 159px;
  padding-bottom: 37px;

  button {
    width: 225px;
    height: 36px;
    border-radius: 4px;
    box-shadow: 0 3px 0 0 #929292;
    background-color: #a9aaac;
    cursor: pointer;
    margin-left: 0px;
    color: #ffffff;
    font-family: VistaSansOT;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    &.submit {
      box-shadow: 0 3px 0 0 #d0931a;
      background-color: #fcb72d;
      margin-left: 20px;
    }
  }
`

export const InnerDiv = styled.div`
  width: 100%;
`
