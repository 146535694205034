import _ from 'lodash';
import * as UsersApi from '../../apis/UsersApi';
import { errorHandler } from '../../utils/errorHandler';
import { formatCustomers } from '../../utils/users';

export const NAMESPACE = 'CUSTOMERS';

export const CLEAR_ERROR_MSGS = "CLEAR_ERROR_MSGS";
export const CLEAR_MSGS = 'CLEAR_MSGS';

export const UPDATE_PAGE_NUMBER = `${NAMESPACE}__UPDATE_PAGE_NUMBER`;
export const UPDATE_FILTER = `${NAMESPACE}__UPDATE_FILTER`;


export const START_FETCH = `${NAMESPACE}__START_FETCH`;
export const SUCCESSFUL_FETCH = `${NAMESPACE}__SUCCESSFUL_FETCH`;
export const SUCCESSFUL_FETCH_ID = `${NAMESPACE}__SUCCESSFUL_FETCH_ID`;
export const FAILED_FETCH = `${NAMESPACE}__FAILED_FETCH`;

export const startFetch = () => ({ type: START_FETCH });
export const successfulFetch = (customers) => ({ type: SUCCESSFUL_FETCH, customers });
export const successfulFetchById = (customer) => ({ type: SUCCESSFUL_FETCH_ID, customer });
export const failedFetch = (error) => ({ type: FAILED_FETCH, error });

export const START_CREATE = `${NAMESPACE}__START_CREATE`;
export const SUCCESSFUL_CREATE = `${NAMESPACE}__SUCCESSFUL_CREATE`;
export const FAILED_CREATE = `${NAMESPACE}__FAILED_CREATE`;

export const startCreate = () => ({ type: START_CREATE });
export const successfulCreate = (customers) => ({ type: SUCCESSFUL_CREATE, customers });
export const failedCreate = (error) => ({ type: FAILED_CREATE, error });

export const START_UPDATE = `${NAMESPACE}__START_UPDATE`;
export const SUCCESSFUL_UPDATE = `${NAMESPACE}__SUCCESSFUL_UPDATE`;
export const SUCCESSFUL_UPDATE_EMAIL_SETTINGS = `${NAMESPACE}__SUCCESSFUL_UPDATE_EMAIL_SETTINGS`;
export const FAILED_UPDATE = `${NAMESPACE}__FAILED_UPDATE`;

export const startUpdate = () => ({ type: START_UPDATE });
export const successfulUpdate = (user) => ({ type: SUCCESSFUL_UPDATE, user });
export const successfulUpdateEmailSettings = (data) => ({type:SUCCESSFUL_UPDATE_EMAIL_SETTINGS, data});
export const failedUpdate = (error) => ({ type: FAILED_UPDATE, error });

export const START_DELETE = `${NAMESPACE}__START_DELETE`;
export const SUCCESSFUL_DELETE = `${NAMESPACE}__SUCCESSFUL_DELETE`;
export const FAILED_DELETE = `${NAMESPACE}__FAILED_DELETE`;

export const startDelete = () => ({ type: START_DELETE });
export const successfulDelete = (customers) => ({ type: SUCCESSFUL_DELETE, customers });
export const failedDelete = (error) => ({ type: FAILED_DELETE, error });

export function updatePageNumber(pageNumber){
  return (dispatch) => {
    return dispatch({
      type:UPDATE_PAGE_NUMBER,
      page:pageNumber
    })
  }
}

export function updateFilter(filter){
  return (dispatch) => {
    return dispatch({
      type:UPDATE_FILTER,
      filter
    })
  }
}

export function fetchCustomers(filterStr, callback) {
  return async (dispatch) => {
    dispatch(startFetch());
    if(callback){callback();}
    try {
      const customers = await UsersApi.getUsers(filterStr);
      dispatch(successfulFetch(customers));
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)));
    }
  };
}

export function fetchCustomerById(id, callback){
  return async (dispatch) => {
    dispatch(startFetch());
    try {
      const staff = await UsersApi.getUserById(id);
      if(callback){callback();}
      dispatch(successfulFetchById(staff));
    } catch (err) {
      dispatch(failedFetch(errorHandler(err)));
    }
  };
}

export function createCustomer(data, callback){
  return async (dispatch) => {
    dispatch(startCreate());
    try {
      const staff = await UsersApi.createUser(data);
      if(callback){callback(true);}
      dispatch(successfulCreate(staff));
    } catch (err) {
      if(callback){callback(false);}
      dispatch(failedCreate(errorHandler(err)));
    }
  }
}

export function updateCustomer(data, id, callback){
  return async (dispatch) => {
    dispatch(startUpdate());
    try {
      const staff = await UsersApi.updateUser(data, id);
      if(callback){callback(true);}
      dispatch(successfulUpdate(staff));
    } catch (err) {
      if(callback){callback(false);}
      dispatch(failedUpdate(errorHandler(err)));
    }
  }
}

export function updateCustomerEmailSettings(userId, companyId, data, callback){
  return async (dispatch) => {
    dispatch(startUpdate());
    try {
      const response = await UsersApi.updateEmailSettings(data, userId, companyId);
      response.companyId = companyId;
      response.userId = userId;
      if(callback){callback(true);}
      dispatch(successfulUpdateEmailSettings(response));
    }
    catch(err){
      if(callback){callback(false);}
      dispatch(failedUpdate(errorHandler(err)));
    }
  }
}

export function deactivateUser(id, callback){
  return async (dispatch) => {
    try {
      const user = await UsersApi.deactivateUser(id);
      if(callback){callback();}
      dispatch(successfulUpdate(user));
    } catch (err) {
      if(callback){callback();}
      dispatch(failedUpdate(errorHandler(err)));
    }
  }
}

export function activateUser(id, callback){
  return async (dispatch) => {
    try {
      const user = await UsersApi.activateUser(id);
      if(callback){callback();}
      dispatch(successfulUpdate(user));
    } catch (err) {
      if(callback){callback();}
      dispatch(failedUpdate(errorHandler(err)));
    }
  }
}

export function deleteUser(id, callback){
  return async (dispatch) => {
    try {
      const user = await UsersApi.deleteUser(id);
      if(callback){callback();}
      dispatch(successfulDelete(user));
    } catch (err) {
      if(callback){callback();}
      dispatch(failedDelete(errorHandler(err)));
    }
  }
}

export function downloadStatusLog(url, s3CustomerKey, s3CustomerAlgorithm, s3CustomerKeyMD5){
  return async (dispatch) => {
    try {
      const file = await UsersApi.downloadCSVS3(url, s3CustomerKey, s3CustomerAlgorithm, s3CustomerKeyMD5);
      //extract data from invoice and send request to S3
    } catch (err) {
    }
  };
}

export function clearErrorMsgs(){
  return async (dispatch) => {
    dispatch({type:CLEAR_ERROR_MSGS});
  };
}

export function clearCustMsgs(){
  return async (dispatch) => {
    dispatch({type:CLEAR_MSGS});
  };
}

export const initialState = {
  customers: [],
  isFetching: false,
  error: undefined,
  page:1,
  filter:'&orderBy=createdAt&order=desc'
};

export default function customersReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCH:
      return {
        ...state,
        isFetching: true,
        error: initialState.error
      };

    case SUCCESSFUL_FETCH:
      let mapped_customers;
      if(action.customers){
        const format_customers = formatCustomers(action.customers.users);
        mapped_customers = format_customers//_.mapKeys(format_customers,(val) => val.id);
      }
      else{
        const format_customers = {[action.customers.id]:action.customers};
        mapped_customers = _.mapKeys(format_customers,(val) => val.id);
      }
      return {
        ...state,
        isFetching: false,
        customers: {
          ...mapped_customers
        },
        meta:action.customers.meta
      };
    case SUCCESSFUL_UPDATE:{
      let state_customers = state.customers;
      let found = false;
      state_customers = _.map(state_customers,(cust) => {
        if(cust.id === action.user.user.id){
          let customer = {...cust, ...action.user.user};
          found = true;
          return customer;
        }
        else{
          return cust;
        }
      })
      if(!found){
        state_customers.push(action.user.user);
      }
      const customers = formatCustomers(state_customers);
      mapped_customers = customers//_.mapKeys(customers,(val) => val.id);
      return {
        ...state,
        customers:mapped_customers,
        msg:'Success: User Saved!'
      }
    }
    case SUCCESSFUL_FETCH_ID:
      return{
        ...state,
        customers:{...state.customers,[action.customer.user.id]:action.customer.user}
      }
    case FAILED_CREATE:
    case FAILED_UPDATE:
    case FAILED_FETCH:
    case FAILED_DELETE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case CLEAR_ERROR_MSGS:
      return{
        ...state,
        error:null
      }
    case CLEAR_MSGS:
      return{
        ...state,
        msg:null
      }
    case UPDATE_PAGE_NUMBER:
      return{
        ...state,
        page:action.page
      }
    case UPDATE_FILTER:
      return{
        ...state,
        filter:action.filter
      }
    default:
      return state;
  }
}
