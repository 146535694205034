import styled from 'styled-components';

export const UserFormContainer = styled.div`
  width:100%;
  margin: 40px;
`;

export const InnerDiv = styled.div`
  width: 100%;
`;


export const Label = styled.label`
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: bold;
  color: #2d2926;
  width:100%;
  margin-bottom:0px;
`;

export const SmallLabel = styled.label`
  font-family: VistaSansOT;
  font-size: 12px;
  font-weight: bold;
  color:#f5a623;
  width:100%;
  margin-bottom:0px;
`;

export const SmallValue = styled.label`
  font-family: VistaSansOT-Reg;
  font-size: 12px;
  font-weight: normal;
  color: #2d2926;
  width:100%;
  margin-bottom:0.1rem;
  position:relative;
  top:-5px;
`;


export const Title = styled.div`
font-family: VistaSansOT;
font-size: 30px;
font-weight: bold;
padding-bottom:36px;
width:100%;
padding-top:20px;
background-color:#fafafa;
`;

export const Username = styled.div`
  margin:auto;
  width:1023px;
`;

export const SubTitle = styled.div`
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: bold;
  padding-bottom:15px;
  padding-top:${props => (props.first) ? '55px' : '20px'};
`;

export const SubColumn = styled.div`
width:50%;
float:left;
margin-bottom:50px;
`;

export const SubSection = styled.div`
width:100%;
height:auto;
margin-top:${props => (props.first) ? '0px' : '20px'};
`;
