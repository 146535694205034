import React from 'react';
import _ from 'lodash';
import LoadingContainer from '../../Loading/components/LoadingContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MultiSelectField from '../../common/FormComponent/MultiSelectField';
import { createCustomer, updateCustomer, fetchCustomerById, clearErrorMsgs } from '../../redux/CustomersRedux/CustomersRedux';
import { fetchCompanies } from '../../redux/CompaniesRedux/CompaniesRedux';
import { fetchAgreements } from '../../redux/AgreementsRedux/AgreementsRedux';
import { getUserDetails, clearAuthMsgs, clearErrMsgs, resetUserPassword, updateProfile } from '../../redux/AuthRedux/AuthRedux';
import { Formik, Field } from "formik";
import { GlobalStyle, ContentContainer, Error, Message, WidthContent } from '../../global-style';
import { UserFormContainer, UserForm, InnerDiv, Title, BackLink, Button, Text, Label, Input, FormItem, ButtonContainer } from './style';
import { Icon } from 'react-icons-kit';
import {chevronLeft} from 'react-icons-kit/fa/chevronLeft';
import {close} from 'react-icons-kit/fa/close';
import ReactTooltip from 'react-tooltip'
import {checkCircleO} from 'react-icons-kit/fa/checkCircleO'
import {checkCircle} from 'react-icons-kit/fa/checkCircle'

const OPT_IN = 'OPT_IN';
const OPT_OUT = 'OPT_OUT';

export class EditProfile extends React.Component{
  constructor(props){
    super(props);
    let submitBtnText = 'Update Profile';
    this.state = {
      current:null,
      showLoading:true,
      isCreate:false,
      submitBtnText,
      companies:[],
      agreements:[],
      companyError:false,
      updated:false,
      fetchFinish:false
    }
  }

  componentDidMount = () => {
    const { auth } = this.props;
    const current = auth.auth && auth.auth.user;
    if(!current){
      this.props.actions.getUserDetails(auth.auth.user.id, () => {
        this.setState({showLoading:false, fetchFinish:true});
      });
    }
    else{
      this.setState({showLoading:false, current});
    }
  }

  componentWillReceiveProps({auth}){
    let { current, updated, fetchFinish } = this.state;
    const user = auth.auth && auth.auth.user;
    if(!current && fetchFinish){
      this.setState({current:user});
    }
  }

  componentWillUnmount(){
    this.props.actions.clearErrMsgs();
    this.props.actions.clearAuthMsgs();
  }

  goToProfile = () => {
    this.props.history.push('/profile');
  }

  handleSubmit = (values) => {
    this.setState({showLoading:true});
    const {auth} = this.props;
    let data = (values.password && values.confirmPassword && (values.password && values.confirmPassword)) ?
    {
      email: values.email,
      phone: values.phone,
      name:values.name,
      password:values.password
    } :
    {
      email: values.email,
      phone: values.phone,
      name:values.name
    };
    data.role = auth.auth.user.role;
    this.props.actions.updateProfile(data, auth.auth.user.id, () => {
      this.setState({showLoading:false});
      this.goToProfile();
    });
  }

  formatForEdit = (user) => {
    if(!user){return {}}
    if(user.firstName && user.lastName){
      user.name = `${user.firstName} ${user.lastName}`;
      user.confirmEmail = user.email;
      user.companyIds = _.map(user.companies,(company) => {
        return { label:company.code, value:company.id };
      });
      user.agreementIds = _.map(user.agreements,(ag) => {
        return { label:ag.code, value:ag.id };
      });
      user.isOptIn = OPT_IN;
    }
    return user;
  }

  onChangeHandle = (param, e) => {
    let { fields } = this.state;
    fields[param] = e.target.value;
    this.setState({fields});
  }

  clearErrors = () => this.props.actions.clearErrMsgs();
  clearMsgs = () => this.props.actions.clearAuthMsgs();

  render(){
    let {current} = this.state;
    if(!current){return null}
    return(
      <div id="edit-profile-container">
      <ContentContainer>
      <GlobalStyle />
        <LoadingContainer showLoading={this.state.showLoading} />
        <BackLink onClick={this.goToProfile}><span style={{ color: '#a9aaac' }}><Icon icon={chevronLeft} /></span>Back to My Profile</BackLink>
        <Title>UPDATE PROFILE</Title>
        <WidthContent>
        <InnerDiv>
          <Formik
          initialValues={{
            ...current
          }}
          validate={values => {
            let errors = {};
            // REGEX
            let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            // VALIDATION
            if(!values.name){
              errors.name = "Name is required."
            }
            if(!values.email){
              errors.email = "Email is required.";
            }
            else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))){
              errors.email = "Invalid email address.";
            }
            if(values.password && !((values.password).match(/^(.{6,})$/) || (values.password).match(/([A-Z]+)/) || (values.password).match(/([0-9]+)/))){
              errors.password = 'Please conform to the password requirements.';
            }
            if(values.confirmPassword && !((values.confirmPassword).match(/^(.{6,})$/) || (values.confirmPassword).match(/([A-Z]+)/) || (values.confirmPassword).match(/([0-9]+)/))){
              errors.confirmPassword = 'Please conform to the password requirements.';
            }
            if(values.password && values.confirmPassword && !(values.password === values.confirmPassword)){
              errors.confirmPassword = "Passwords do not match.";
              errors.password = "Passwords do not match.";
            }
            if(values.phone && !(/^\+?\d{1,14}$/i.test(values.phone))){
              errors.phone = "Invalid phone number."
            }
            return errors;
          }}
          onSubmit={values => {
            this.handleSubmit(values);
          }}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return(
            <UserForm onSubmit={handleSubmit}>

              <FormItem firstItem={true}>
                {this.props.auth.error && <Error id="edit-profile-error">{this.props.auth.error}<span onClick={this.clearErrors} style={{ color: '#a94442' }}><Icon icon={close} /></span></Error>}
                <Label>
                  NAME
                </Label>
                <Input
                  id="edit-profile-name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  border={touched.name && errors.name && "1px solid red"}
                  type="text"
                  name="name"
                />
                <Text color="red" display={touched.name && errors.name}>{errors.name}</Text>
              </FormItem>
              <FormItem secondRow={true}>
                <Label>
                EMAIL
                </Label>
                <Input
                  id="edit-profile-email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  border={
                    touched.email && errors.email && "1px solid red"
                  }
                  type="text"
                  name="email"
                />
                <Text color="red" display={touched.email && errors.email}>{errors.email}</Text>
              </FormItem>
              <FormItem secondColumn={true} secondRow={true}>
                <Label>
                PHONE NUMBER
                </Label>
                <Input
                  id="edit-profile-phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  border={
                    touched.phone && errors.phone && "1px solid red"
                  }
                  type="text"
                  name="phone"
                />
                <Text display={touched.phone && errors.phone} color="red">{errors.phone}</Text>
              </FormItem>
              <FormItem secondRow={true}>
                <Label>
                PASSWORD
                </Label>
                <Input
                  id="edit-profile-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  border={
                    touched.password && errors.password && "1px solid red"
                  }
                  type="password"
                  name="password"
                  data-tip="info"
                  data-event="focus"
                  data-event-off="blur"
                  data-for="password-tooltip"
                />
                <Text display={touched.password && errors.password} color="red">{errors.password}</Text>
                <ReactTooltip id="password-tooltip" place="left" type="dark" effect="float">
                {((values && values.password) && (values.password).match(/^(.{6,})$/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 6 characters<br/>
                {((values && values.password) && (values.password).match(/([A-Z]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 upper case letter<br/>
                {((values && values.password) && (values.password).match(/([0-9]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 number
                </ReactTooltip>
              </FormItem>
              <FormItem secondColumn={true} secondRow={true}>
                <Label>
                CONFIRM PASSWORD
                </Label>
                <Input
                  id="edit-profile-confirm-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  border={
                    touched.confirmPassword && errors.confirmPassword && "1px solid red"
                  }
                  type="password"
                  name="confirmPassword"
                  data-tip="info"
                  data-event="focus"
                  data-event-off="blur"
                  data-for="confirmPassword-tooltip"
                />
                <Text display={touched.confirmPassword && errors.confirmPassword} color="red">{errors.confirmPassword}</Text>
                <ReactTooltip id="confirmPassword-tooltip" place="left" type="dark" effect="float">
                {((values && values.confirmPassword) && (values.confirmPassword).match(/^(.{6,})$/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 6 characters<br/>
                {((values && values.confirmPassword) && (values.confirmPassword).match(/([A-Z]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 upper case letter<br/>
                {((values && values.confirmPassword) && (values.confirmPassword).match(/([0-9]+)/)) ? <Icon style={{color:'#38D15C'}} icon={checkCircle} /> : <Icon style={{color:'#F21E1E'}} icon={checkCircleO} />} &nbsp;At least 1 number
                </ReactTooltip>
              </FormItem>
              {/*<FormItem secondRow={true} className="companies" isEmpty={_.isEmpty(current.companyIds)}>
                <Label>
                COMPANY
                </Label>
                <Field
                  component={MultiSelectField}
                  name="companyIds"
                  values={values.companyIds}
                  type="text"
                  options={this.state.companies}
                  onChangeHandle={(e) => {this.onChangeHandle('companyIds',e)}}
                />
                <Text display={this.state.companyError} color="red">Please select a company.</Text>
              </FormItem>
              <FormItem secondColumn={true} secondRow={true} isEmpty={_.isEmpty(current.agreementIds)}>
                <Label>
                AGREEMENT NO (optional)
                </Label>
                <MultiSelectField name="agreementIds" values={values.agreementIds} type="text" options={this.state.agreements} onChangeHandle={(e) => {this.onChangeHandle('agreementIds',e)}} />
                {errors.agreementIds && <Text color="red">{errors.agreementIds}</Text>}
              </FormItem>*/}
              <ButtonContainer>
                <Button id="edit-profile-cancel-button" type="button" onClick={this.goToProfile}>Cancel</Button>
                <Button id="edit-profile-submit-button" type="submit">{this.state.submitBtnText}</Button>
              </ButtonContainer>
            </UserForm>
          )}}
        />
        </InnerDiv>
        </WidthContent>
      </ContentContainer>
      </div>
    );
  }
}

function mapStateToProps({customers, companies, agreements, auth}){

  return({
    customers,
    companies,
    agreements,
    auth
  });
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
          fetchCustomerById,
          createCustomer,
          updateCustomer,
          clearErrorMsgs,
          fetchCompanies,
          fetchAgreements,
          getUserDetails,
          clearAuthMsgs,
          clearErrMsgs,
          resetUserPassword,
          updateProfile
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
