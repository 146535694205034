import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Formik } from "formik";
import { login, clearErrMsgs, clearAuthMsgs } from '../../redux/AuthRedux/AuthRedux';
import { GlobalStyle } from '../../global-style';
import {
  LoginDiv, InnerDiv, LoginForm,
  Label, Input, Button, Text,
  TitleOne, TitleTwo, ForgotLink,
  WebsiteLink, PlainText, LoginFooter,
  FooterImgLeft, FooterImgRight, Error,
  Message, ErrorMsg
 } from './style';
import { Icon } from 'react-icons-kit';
import {close} from 'react-icons-kit/fa/close';
import footerLogo1 from '../../assets/footer-logo.png';
import footerLogo2 from '../../assets/footer-logo2.png';

export class LoginContainer extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      fields:{
        username:'',
        password:'',
        errorMsg:null
      }
    }
  }

  componentDidMount(){
    if(this.props.auth.loggedIn){
      this.props.history.push('/invoices');
    }
  }

  componentWillReceiveProps({auth}){
    if(auth.loggedIn){
      this.props.history.push('/invoices');
    }
  }


  componentWillUnmount(){
    this.props.actions.clearErrMsgs();
    this.props.actions.clearAuthMsgs();
  }

  clearErrors = () => {
    this.props.actions.clearErrMsgs();
  }

  clearMsgs = () => {
    this.props.actions.clearAuthMsgs();
  }

  onChangeHandler = (field, e) => {
    const my_fields = this.state.fields;
    my_fields[field] = e.target.value;
    this.setState({fields: my_fields});
  }

  handleSubmit = (values) => {
    this.props.actions.login(values,() => {
      this.props.history.push('/invoices');
    });
  }

  render(){
    const {handleSubmit, auth} = this.props;
    const {fields} = this.state;
    return(
      <div id="login-container">
      <LoginDiv>
      <GlobalStyle />
        <InnerDiv>

          <Formik
          initialValues={{ email: "", password: "" }}
          validate={values => {
            let errors = {};
            // REGEX
            let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            // VALIDATION
            if(!values.email){
              errors.email = "Please enter an email.";
            }
            else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))){
              errors.email = "Invalid email address";
            }

            if(!values.password){
              errors.password = "Please enter your password.";
            }
            else if(values.password.length < 6){
              errors.password = "Password must be 6 characters";
            }
            return errors;
          }}
          onSubmit={values => {
            this.props.actions.login(values,() => {
              this.props.history.push('/invoices');
            });
          }}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return(
            <LoginForm onSubmit={handleSubmit}>
            <TitleOne>Welcome to</TitleOne>
            <TitleTwo isError={this.props.auth.error}>GOLDBELL CAR RENTAL</TitleTwo>
            {this.props.auth.msg &&
              <Message id="reset-password-message">
                <span className="close" onClick={this.clearMsgs} style={{ color: '#3c763d' }}><Icon icon={close} /></span>
                <span className="message">{this.props.auth.msg}</span>
              </Message>}
              {this.props.auth.error && <Error id="login-container-error" color="red"><span onClick={this.clearErrors} style={{ color: '#a94442' }}><Icon icon={close} /></span><ErrorMsg>{this.props.auth.error}</ErrorMsg></Error>}
              <Label>
                <Input
                  id="login-email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  border={touched.email && errors.email && "1px solid red"}
                  type="text"
                  name="email"
                  placeholder="Email Address"
                />
                {touched.email &&
                  errors.email && <Text color="red">{errors.email}</Text>}
              </Label>
              <Label>
                <Input
                  id="login-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  border={
                    touched.password && errors.password && "1px solid red"
                  }
                  type="password"
                  name="password"
                  placeholder="Password"
                />
                {touched.password &&
                  errors.password && <Text color="red">{errors.password}</Text>}
              </Label>
              <Button id="login-submit-button" type="submit">LOGIN</Button>

              <ForgotLink><Link id="login-forgot-password-link" to="/forgot">Forgot your password?</Link></ForgotLink>
              <PlainText>Visit our website <WebsiteLink><a id="login-website-link" href="https://gbcr.com.sg">gbcr.com.sg</a></WebsiteLink></PlainText>
            </LoginForm>

          )}}
        />
        </InnerDiv>
      </LoginDiv>
      <LoginFooter>
        <FooterImgRight src={footerLogo1} />
        <FooterImgLeft src={footerLogo2} />
      </LoginFooter>
      </div>
    );
  }
}

function mapStateToProps({auth}){
  return({
    auth: auth
  });
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ login, clearErrMsgs, clearAuthMsgs }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
