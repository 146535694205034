// Radio input
import React from 'react'
import classNames from 'classnames'
import { Label } from '../../global-style'

const InputFeedback = ({ error }) =>
  error ? <div className={classNames('input-feedback')}>{error}</div> : null

export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <div className='radio-option'>
      <input
        name={name}
        id={id}
        type='radio'
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames('radio-button')}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

// Radio group
export const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children
}) => {
  const classes = classNames(
    'input-field',
    {
      'is-success': value || (!error && touched), // handle prefilled or user-filled
      'is-error': !!error && touched
    },
    className
  )

  return (
    <div className={classes}>
      <fieldset>
        <Label>{label}</Label>
        {children}
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </div>
  )
}
