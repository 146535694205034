import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { uid } from 'react-uid'
import { user } from 'react-icons-kit/metrize/user'
import { Icon } from 'react-icons-kit'
import { folderOpen } from 'react-icons-kit/icomoon/folderOpen'
import { renderDropdownField } from '../FormComponent/Form'
import {
  TableContainer,
  Table,
  TableCell,
  TableHead,
  LinkImg,
  SortAscImg,
  SortDescImg,
  FloatLeft,
  Label
} from './style'
import Pagination from '../Pagination/Pagination'
import sortAsc from '../../assets/sort-asc.png'
import sortDesc from '../../assets/sort-desc.png'
import sortAscBlack from '../../assets/sortAscBlack.png'
import sortDescBlack from '../../assets/sortDescBlack.png'
import downloadImg from '../../assets/download-icon.png'

class TableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actions: [],
      dropdownOpen: {}
    }
  }

  componentDidMount() {
    _.forEach(this.props.columns, colData => {
      if (colData.action) {
        const temp_actions = [
          {
            type: colData.type,
            onClick: colData.onClickHandle,
            name: colData.name,
            value: colData.value
          }
        ]
        this.setState({ actions: temp_actions })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    _.forEach(nextProps.columns, colData => {
      if (colData.action) {
        const temp_actions = [
          {
            type: colData.type,
            onClick: colData.onClickHandle,
            name: colData.name,
            value: colData.value
          }
        ]
        this.setState({ actions: temp_actions })
      }
    })
  }

  renderRow = (col, colHead, item) => {
    if (!colHead) {
      return null
    }
    switch (colHead.type) {
      case 'text':
        return this.renderTextRow(col, colHead, item)
      case 'html':
        return this.renderHtmlRow(col, colHead, item)
      case 'image':
        return this.renderImageRow(col, colHead, item)
      case 'dropdown':
        return this.renderDropdownRow(col, colHead, item)
      case 'arrayLink':
        return this.renderArrayLinkRow(col, colHead, item)
      case 'indicator':
        return this.renderIndicatorRow(col, colHead, item)
      default:
        return null
    }
  }

  toggle = id => {
    if (this.state.dropdownOpen[id]) {
      this.setState({
        dropdownOpen: { ...this.state.dropdownOpen, [id]: false }
      })
    } else {
      this.setState({
        dropdownOpen: { ...this.state.dropdownOpen, [id]: true }
      })
    }
  }

  renderIndicatorRow = (col, colHead, item) => {
    return (
      <div className='indicator'>
        <div className='critical-div'>
          <span
            className={item.isCritical ? 'is-critical' : 'is-critical-none'}
          >
            {item.isCritical && 'C'}
          </span>
        </div>
        <div className='feature-div'>
          <span className={item.isFeature ? 'is-feature' : 'is-feature-none'}>
            {item.isFeature && 'F'}
          </span>
        </div>
      </div>
    )
  }

  renderTextRow = (col, colHead, item) => {
    if (!col) {
      return ''
    }
    if (colHead.value === 'View') {
      return <span className='text-field'>View</span>
    } else {
      return (
        <div
          title={item[`${colHead.attr}Tip`] ? item[`${colHead.attr}Tip`] : ''}
          className='text-field'
        >
          {typeof col === 'object' ? JSON.stringify(col) : col.toString()}
          {item[`${colHead.attr}NextLine`] && <br />}
          {item[`${colHead.attr}NextLine`] && item[`${colHead.attr}NextLine`]}
        </div>
      )
    }
  }

  renderHtmlRow = (col, colHead, item) => {
    if (!col) {
      return ''
    }

    return (
      <div
        className='text-field'
        dangerouslySetInnerHTML={{ __html: col.toString() }}
      />
    )
  }

  renderArrayLinkByType = (col, colHead) => {
    const type = colHead.arrayLinkType
    if (type === 'company_agreements') {
      return (
        <div>
          {_.map(col, (colitem, key) => {
            return (
              <Label
                title={colitem.code ? colitem.code : ''}
                bold={true}
                onClick={() => colHead.onClickHandle(colitem.id)}
                key={key}
              >
                {colitem.code}
                {key + 1 <= col.length - 1 ? ' ' : ''}{' '}
              </Label>
            )
          })}
        </div>
      )
    }
    return (
      <div>
        {_.map(col, (colitem, key) => {
          return (
            <Label
              title={colitem.nameTip ? colitem.nameTip : ''}
              bold={type === 'company_users' ? false : true}
              onClick={() =>
                colHead.onClickHandle && colHead.onClickHandle(colitem.id)
              }
              key={key}
            >
              {colitem.name}
              {key + 1 <= col.length - 1 ? ', ' : ''}{' '}
            </Label>
          )
        })}
      </div>
    )
  }

  renderArrayLinkRow = (col, colHead, item) => {
    return this.renderArrayLinkByType(col, colHead)
  }

  renderImageRow = (col, colHead, item) => {
    if (item.avatar && item.type === 'group') {
      return <Icon icon={folderOpen} />
    } else if (item.avatar) {
      return <img className='profile-image' src={item.avatar} />
    } else if (colHead.title === 'download') {
      return <LinkImg src={downloadImg} />
    } else {
      return <Icon size={32} className='image-field user-icon' icon={user} />
    }
  }

  renderDropdownRow = (col, colHead, item) => {
    let isEnabled = true
    if (item.status === 'archived') {
      isEnabled = false
    } else if (item.role === 'Admin' && !this.props.isSuper) {
      isEnabled = false
    }
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen[uid(item)]}
        toggle={() => {
          this.toggle(uid(item))
        }}
      >
        <DropdownToggle
          tag='span'
          onClick={this.toggle}
          data-toggle='dropdown'
          aria-expanded={this.state.dropdownOpen[uid(item)]}
        >
          ...
        </DropdownToggle>
        <DropdownMenu>
          {_.map(colHead.values, val => {
            return (
              <div
                key={uid(val)}
                className={`${
                  isEnabled ? 'item-parent' : 'item-parent-greyed'
                }`}
              >
                <div
                  className={`submenu-dropdown-item dropdown-field ${
                    isEnabled ? '' : 'item-greyed'
                  }`}
                  onClick={() => {
                    if (isEnabled) {
                      val.onClickHandle(item.id ? item.id : item)
                    }
                  }}
                >
                  {val.label}
                </div>
                <DropdownItem divider />
              </div>
            )
          })}
        </DropdownMenu>
      </Dropdown>
    )
  }

  render() {
    const colTypes = _.mapKeys(this.props.columns, col => {
      return col.attr
    })
    return (
      <TableContainer>
        <Table className='table'>
          <thead>
            <tr>
              {_.map(this.props.columns, (col, index) => {
                return (
                  <TableHead key={uid(col)} scope='col'>
                    <FloatLeft>{col.name}</FloatLeft>
                    <FloatLeft>
                      {col.isSort && (
                        <SortAscImg
                          src={sortAsc}
                          isSortAsc={col.isSortAsc}
                          onClick={() => {
                            this.props.sort(col.title, 'asc')
                          }}
                        />
                      )}
                      {col.isSort && (
                        <SortDescImg
                          src={sortDesc}
                          isSortDesc={col.isSortDesc}
                          onClick={() => {
                            this.props.sort(col.title, 'desc')
                          }}
                        />
                      )}
                    </FloatLeft>
                  </TableHead>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {_.map(this.props.data, (dataItem, index) => {
              return (
                <tr key={uid(dataItem)}>
                  {_.map(colTypes, coltype => {
                    if (coltype.action) {
                      if (
                        !(
                          coltype.type === 'text' ||
                          coltype.type === 'checkbox' ||
                          coltype.type === 'label' ||
                          coltype.type === 'arrayLink'
                        )
                      ) {
                        return null
                      } else if (coltype.type === 'label') {
                        return (
                          <TableCell bold={coltype.bold} key={uid(coltype)}>
                            <label
                              className='label-action'
                              onClick={e => {
                                coltype.onClick(e, dataItem)
                              }}
                            >
                              {coltype.value}
                            </label>
                          </TableCell>
                        )
                      } else if (coltype.type === 'arrayLink') {
                        return (
                          <TableCell userAction={true} key={uid(coltype)}>
                            {_.map(coltype.values, (action, index) => {
                              let label = action.label
                              if (action.label === 'Deactivate') {
                                if (dataItem.status === 'Deactivated') {
                                  label = 'Activate'
                                }
                              }
                              return (
                                <Label
                                  onClick={() => {
                                    action.onClickHandle(dataItem, label)
                                  }}
                                  color={action.color}
                                  key={index}
                                  bold={true}
                                >
                                  {label}
                                </Label>
                              )
                            })}
                          </TableCell>
                        )
                      } else {
                        return (
                          <TableCell bold={coltype.bold} key={uid(coltype)}>
                            <input
                              type={coltype.type}
                              onChange={e => {
                                coltype.onClick(e, dataItem)
                              }}
                              checked={dataItem.checked}
                            />
                            {coltype.name}
                          </TableCell>
                        )
                      }
                    } else {
                      return (
                        <TableCell
                          bold={coltype.bold}
                          clickable={coltype.clickable}
                          key={uid({ ...dataItem, ...coltype })}
                          onClick={
                            coltype.onClickHandle
                              ? e => {
                                  coltype.onClickHandle(dataItem)
                                }
                              : null
                          }
                        >
                          {this.renderRow(
                            dataItem[coltype.attr],
                            coltype,
                            dataItem
                          )}
                        </TableCell>
                      )
                    }
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
        {this.props.meta && this.props.meta.paginate && (
          <Pagination
            data={this.props.meta.paginate}
            handlePageClick={this.props.handlePageClick}
          />
        )}
      </TableContainer>
    )
  }
}

export default TableComponent
