import * as AuditApi from '../../apis/AuditApi'
import { errorHandler } from '../../utils/errorHandler';
import moment from 'moment';

const GET_AUDIT_REQUEST = 'GET_AUDIT_REQUEST'
const GET_AUDIT_SUCCESS = 'GET_AUDIT_SUCCESS'
const GET_AUDIT_FAILURE = 'GET_AUDIT_FAILURE'
const RESET_AUDIT = 'RESET_AUDIT'

const defaultState = {
  isLoading: false,
  error: null,
  id: null,
  data: null,
  meta: null
}

const formatHeaderItems = raw => {
  let headerItems = {
    encryptionCustomerKey:'',
    encryptionCustomerAlgo:'',
    encryptionCustomerMd5:'',
    link:''
  };
  let matches = raw.match(/x-amz-server-side-encryption-customer-key=([^&]*)/);
  if(matches && matches.length>1){
    headerItems.encryptionCustomerKey = decodeURIComponent(matches[1]);
  }
  matches = raw.match(/x-amz-server-side-encryption-customer-algorithm=([^&]*)/);
  if(matches && matches.length>1){
    headerItems.encryptionCustomerAlgo = decodeURIComponent(matches[1]);
  }
  matches = raw.match(/x-amz-server-side-encryption-customer-key-MD5=([^&]*)/);
  if(matches && matches.length>1){
    headerItems.encryptionCustomerMd5 = decodeURIComponent(matches[1]);
  }
  const splited = raw.split('here: ');
  if(splited && splited.length>1){
    headerItems.link = splited[1];
  }
  console.log('headerItems: ', headerItems);
  return headerItems;
}

const formatMessage = raw => {
  const splited = raw.split('here: ')
  return `${splited[0]}<a>here</a>`;
}

const parseLogs = raw => {
  return raw.map(item => {
    const needFormat = ~item.message.indexOf(
      'You can check the upload log here:'
    )
    item.createdAtStr = moment(item.createdAt).format('DD/MM/YYYY HH:mm');
    return needFormat
      ? {
          ...item,
          message: formatMessage(item.message),
          headerItems: formatHeaderItems(item.message)
        }
      : item
  })
}

export default (state = defaultState, action) => {
  const { type, payload, error, id } = action

  switch (type) {
    case GET_AUDIT_REQUEST:
      return {
        ...state,
        id,
        isLoading: true,
        error: null
      }

    case GET_AUDIT_SUCCESS:
      return {
        ...state,
        id,
        isLoading: false,
        error: null,
        data: parseLogs(payload.logs),
        meta: payload.meta
      }

    case GET_AUDIT_FAILURE:
      return {
        ...state,
        id,
        isLoading: false,
        error,
        data: null,
        meta: null
      }

    case RESET_AUDIT:
      return defaultState

    default:
      return state
  }
}

export function resetAudit() {
  return {
    type: RESET_AUDIT
  }
}

export async function downloadAudit(link) {
  try {
    let response = await AuditApi.downloadAudit(link)
    const result = new Blob([response.data], { type: 'text/csv' })

    return result
  } catch (err) {
    console.error(err)
  }
}

export function getAudit(filterStr) {
  return async dispatch => {
    try {
      dispatch({
        type: GET_AUDIT_REQUEST
      })

      let response = await AuditApi.getAuditLog(filterStr)

      return dispatch({
        type: GET_AUDIT_SUCCESS,
        payload: response
      })
    } catch (err) {
      dispatch({
        type: GET_AUDIT_FAILURE,
        error: err
      })
    }
  }
}

export function downloadUploadLog(headerItems){
  return async (dispatch) => {
    try {
      const file = await AuditApi.downloadS3(headerItems.link, headerItems.encryptionCustomerKey, headerItems.encryptionCustomerAlgo, headerItems.encryptionCustomerMd5);
      //extract data from invoice and send request to S3
    } catch (err) {
      dispatch({
        type: GET_AUDIT_FAILURE,
        error: (errorHandler(err))
      })
    }
  };
}
