import _ from 'lodash'
import * as FilesApi from '../../apis/FilesApi'
import { errorHandler } from '../../utils/errorHandler'

export const NAMESPACE = 'FILES'
export const START_UPLOAD = `${NAMESPACE}__START_UPLOAD`
export const SUCCESSFUL_UPLOAD = `${NAMESPACE}__SUCCESSFUL_UPLOAD`
export const SUCCESSFUL_UPLOAD_CSV = `${NAMESPACE}__SUCCESSFUL_UPLOAD_CSV`
export const FAILED_UPLOAD = `${NAMESPACE}__FAILED_UPLOAD`
export const FAILED_UPLOAD_CSV = 'FAILED_UPLOAD_CSV'
export const UPLOAD_FILES = `${NAMESPACE}__UPLOAD_FILES`
export const UPLOAD_FAIL = `${NAMESPACE}__UPLOAD_FAIL`

export const CLEAR_FILES = `${NAMESPACE}__CLEAR_FILES`

export const startUpload = () => ({ type: START_UPLOAD })
export const successfulUpload = data => ({ type: SUCCESSFUL_UPLOAD, data })
export const successfulUploadCSV = data => ({
  type: SUCCESSFUL_UPLOAD_CSV,
  data
})
export const failedUpload = (error, files) => ({
  type: FAILED_UPLOAD,
  error,
  files
})
export const failedUploadCSV = (error, file) => ({
  type: FAILED_UPLOAD_CSV,
  error,
  file
})

export const clearFilesInStore = () => ({ type: CLEAR_FILES })

export function uploadFile(files, type, callback) {
  return async dispatch => {
    dispatch(startUpload())
    try {
      let formData = new FormData()
      _.forEach(files, file => {
        formData.append(type, file)
      });
      //const response_files = files;
      const response_files = await FilesApi.upload(formData, type)
      if (callback) {
        callback()
      }
      dispatch(successfulUpload(response_files))
    } catch (err) {
      if (callback) {
        callback()
      }
      const error_files = _.map(files, file => {
        file.error = true
      })
      dispatch(failedUpload(errorHandler(err), error_files))
    }
  }
}

export function uploadCSV(files, type, callback) {
  return async dispatch => {
    dispatch(startUpload())
    try {
      let formData = new FormData()
      _.forEach(files, file => {
        formData.append('file', file)
      })
      const responseFile = await FilesApi.upload(formData, type)
      if (callback) {
        callback()
      }

      if (responseFile.status !== 'failed') {
        dispatch(successfulUploadCSV(responseFile))
      } else {
        dispatch(failedUploadCSV(responseFile.error, responseFile))
      }

    } catch (err) {
      if (callback) {
        callback(err)
      } else {
        const error_files = _.map(files, file => {
          file.error = true
        })
        dispatch(failedUploadCSV(errorHandler(err), error_files))
      }
    }
  }
}

export function clearFiles() {
  return async dispatch => {
    dispatch(clearFilesInStore())
  }
}

export const initialState = {
  files: {},
  isFetching: false,
  error: undefined
}

export default function filesReducer(state = initialState, action) {
  switch (action.type) {
    case START_UPLOAD:
      return {
        ...state,
        isFetching: true
      }
    case SUCCESSFUL_UPLOAD:
      return {
        ...state,
        isFetching: false,
        files: action.data.results
          ? { ...state.files, ...action.data.results.files }
          : { ...state.files },
        log: action.data.results.log
      }
    case SUCCESSFUL_UPLOAD_CSV:
      const success_result_files = action.data.files
        ? action.data.files
        : {
            '0': {
              filename: action.data.file,
              status: action.data.status,
              error: action.data.error
            }
          }
      return {
        ...state,
        isFetching: false,
        files: { ...success_result_files },
        log: action.data.log
      }
    case FAILED_UPLOAD:
      if(action.files && action.files.results){
        const result_files = action.files.results.files
          ? action.files.results.files
          : {
              '0': {
                filename: action.files.results.file,
                status: action.files.results.status,
                error: action.files.results.error
              }
            }
        return {
          ...state,
          isFetching: false,
          files: action.files.results
            ? { ...state.files, ...result_files }
            : { ...state.files },
          log: action.files.results ? action.files.results.log : {},
          error: action.error
        }
      }
      else{
        return {
          ...state,
          error: action.error
        }
      }


    case FAILED_UPLOAD_CSV:
      return {
        ...state,
        isFetching: false,
        files: action.file
          ? [
              {
                filename: action.file.file,
                status: action.file.status,
                error: action.error
              }
            ]
          : [],
        log: action.file.log ? action.file.log : {},
        error: action.error
      }
    case CLEAR_FILES:
      return {
        ...state,
        files: {},
        log: {},
        error:null
      }
    default:
      return state
  }
}
