import {
  getUsersByCompany as getUsersByCompanyAPI,
  getAgreementsByCompany as getAgreementsByCompanyAPI
} from '../../apis/CompaniesApi'

const GET_COMPANY_DETAIL_SUCCESS = 'GET_COMPANY_DETAIL_SUCCESS'
const GET_USERS_COMPANY_REQUEST = 'GET_USERS_COMPANY_REQUEST'
const GET_USERS_COMPANY_SUCCESS = 'GET_USERS_COMPANY_SUCCESS'
const GET_USERS_COMPANY_FAILURE = 'GET_USERS_COMPANY_FAILURE'
const GET_AGREEMENTS_COMPANY_REQUEST = 'GET_AGREEMENTS_COMPANY_REQUEST'
const GET_AGREEMENTS_COMPANY_SUCCESS = 'GET_AGREEMENTS_COMPANY_SUCCESS'
const GET_AGREEMENTS_COMPANY_FAILURE = 'GET_AGREEMENTS_COMPANY_FAILURE'
const UPDATE_COMPANY_DETAIL = 'UPDATE_COMPANY_DETAIL'
const RESET_COMPANY_DETAIL = 'RESET_COMPANY_DETAIL'

const defaultState = {
  companyDetail: null,
  isLoading: false,
  error: null,
  users: null,
  agreements: null
}

export default (state = defaultState, action) => {
  const { type, payload, error } = action

  switch (type) {
    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        companyDetail: payload
      }
    case GET_USERS_COMPANY_REQUEST:
    case GET_AGREEMENTS_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_USERS_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        users: payload
      }

    case GET_AGREEMENTS_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        agreements: payload
      }

    case GET_USERS_COMPANY_FAILURE:
    case GET_AGREEMENTS_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: error
      }

    case UPDATE_COMPANY_DETAIL:
      return {
        ...state,
        companyDetail: payload
      }

    case RESET_COMPANY_DETAIL:
      return defaultState

    default:
      return state
  }
}

export function resetCompanyDetail() {
  return {
    type: RESET_COMPANY_DETAIL
  }
}

export function getCompanyDetailSuccess(companyDetail) {
  return {
    type: GET_COMPANY_DETAIL_SUCCESS,
    payload: companyDetail
  }
}

export function getUsersByCompany(id, queryString) {
  return {
    types: [
      GET_USERS_COMPANY_REQUEST,
      GET_USERS_COMPANY_SUCCESS,
      GET_USERS_COMPANY_FAILURE
    ],
    promise: getUsersByCompanyAPI(id, queryString)
  }
}

export function getAgreementsByCompany(id, queryString) {
  return {
    types: [
      GET_AGREEMENTS_COMPANY_REQUEST,
      GET_AGREEMENTS_COMPANY_SUCCESS,
      GET_AGREEMENTS_COMPANY_FAILURE
    ],
    promise: getAgreementsByCompanyAPI(id, queryString)
  }
}

export function updateCompanyDetail(data) {
  return {
    type: UPDATE_COMPANY_DETAIL,
    payload: data
  }
}
