import axios from 'axios';
import _ from 'lodash';
import { logout } from './AuthRedux';

const baseUrl = `${process.env.REACT_APP_CMS_API_URL}auth/`;
export const REFRESH_FAILURE = 'REFRESH_FAILURE';

export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
    const user = JSON.parse(localStorage.getItem('GOLDBELL-CMS:USER'));
    if(!user){
      return Promise.reject(REFRESH_FAILURE);
    }
    const now = (new Date()).getTime();
    if(now > user.auth.expiry){
      return axios.post(`${baseUrl}accessToken`,{},{headers : {"Authorization" : `Bearer ${user.auth.user.resetToken}`}})
      .then((res) => {
        res.data.expiry = setExpiry();
        res.data.refreshToken = user.auth.user.resetToken;
        res.data.admin = user.auth.admin;
        localStorage.setItem('GOLDBELL-CMS:USER',JSON.stringify({auth:res.data}));
        config.headers.Authorization = `Bearer ${res.data.auth.token}`;
        return config;
      })
      .catch((res) => {
        return Promise.reject(REFRESH_FAILURE);
      })
    }
    else{
      config.headers.Authorization = `Bearer ${user.auth.token}`;
      return config;
    }
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    let msg = '';
    if(error === REFRESH_FAILURE){
      localStorage.setItem('GOLDBELL-CMS:USER', null);
      logout();
      window.location = '/';
    }
    else if(error.response && error.response.message && error.response.message === 'Token is invalid'){
      localStorage.setItem('GOLDBELL-CMS:USER', null);
      logout();
      window.location = '/';
    }
    else if(error.response && error.response.data){
      const data = error.response.data;
      if(data.detail){
        _.forEach(data.detail,(detail) => {
          msg = `${msg}${detail.key}:${detail.message}\n`;
        });
      }
      else if(data.message){
        msg = data.message;
      }
      else{
        msg = data;
      }
      return Promise.reject(error);
    }
    else if(error.message){
      msg = error.message;
      Promise.reject(error);
    }
    else{
      return Promise.reject(error);
    }
    // Do something with response error

  });

export function setExpiry(){
  const d = new Date();
  const expiry = d.setTime((new Date().getTime() + (24*60*50*1000)));
  return expiry;
}
