import React, { useState, useEffect, useRef } from 'react'
import LoadingContainer from '../Loading/components/LoadingContainer'
import TableComponent from '../common/TableComponent/TableComponent'
import Filter from '../common/Filter/Filter'
import Submenu from '../common/Submenu/Submenu'
import UploadCSV from '../common/Modals/UploadCSV/UploadCSV'
import {
  ContentContainer,
  SectionTitleWithSubAction,
  WidthContent
} from '../global-style'
import { fetchCompanies, updatePageNumber, updateFilter, downloadStatusLog } from '../redux/CompaniesRedux/CompaniesRedux'
import { connect } from 'react-redux'
import _ from 'lodash'

import './Company.scss'

function CompanyContainer({
  companies,
  fetchCompanies,
  updatePageNumber,
  updateFilter,
  files,
  history,
  ...others
}) {
  const [columnRef, setColumnRef] = useState({
    country: {
      name: 'COUNTRY',
      attr: 'country',
      type: 'text',
      isSort: true,
      title:'country'
    },
    code: {
      name: 'COMPANY CODE',
      attr: 'code',
      type: 'text',
      isSort: true,
      title:'code'
    },
    name: {
      name: 'COMPANY NAME',
      attr: 'name',
      type: 'text',
      bold: true,
      clickable: true,
      isSort: true,
      title:'name',
      onClickHandle: company => viewCompanyDetail(company)
    },
    address: {
      name: 'ADDRESS',
      attr: 'address',
      type: 'text'
    },
    action: {
      name: 'ACTION',
      attr: 'action',
      type: 'arrayLink',
      action: true,
      values: [
        {
          label: 'Edit',
          onClickHandle: item => {
            history.push(`/companies/edit/${item.id}`)
          },
          color: '#fcb72d'
        }
      ]
    }
  })

  const subActionRef = useRef({
    name: '+ Add new company',
    actionType: 'create',
    isDropdown: true,
    dropdownItems: [
      {
        label: 'Create Manually',
        action: () => {
          history.push('/companies/new')
        }
      },
      {
        label: 'Upload.csv',
        action: () => {
          uploadNewCompany()
        }
      }
    ]
  })

  const [loading, setLoading] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [filterItem, setFilterItem] = useState({
    label: 'search',
    placeHolder: 'Company name, company code',
    type: 'text',
    value: '',
    withButton: true,
    onChangeHandle: e => onSearchChange(e)
  })

  const [filterButtons, setFilterButtons] = useState({
    isSearch: false
  })

  useEffect(() => {
    setCurrentPage(((companies.page && (window.location.search).includes('isBack')) ? companies.page : 1));
    filterItem.value = (companies.filter && (window.location.search).includes('isBack')) ? companies.filter : '';
    setFilterItem(filterItem);
    fetchCompanies(`?page=${companies.page && (window.location.search).includes('isBack') ? companies.page : 1}&orderBy=updatedAt&order=desc${(filterItem.value ? '&q=' : '')}${(filterItem.value ? filterItem.value : '')}`)
  }, [])

  const handlePageClick = data => {
    setLoading(true)
    updatePageNumber(data.selected+1);
    const col = _.find(columnRef, (col) => (col.isSort && (col.isSortAsc || col.isSortDesc)));
    const sortStr = col ? (col.isSortAsc ? `orderBy=${col.title}&order=asc` : `orderBy=${col.title}&order=desc`) : 'orderBy=updatedAt&order=desc';
    const filterStr = filterItem.value ? `&q=${filterItem.value}` : '';
    fetchCompanies(`?page=${data.selected + 1}&${sortStr}${filterStr}`, () => {
      setCurrentPage(data.selected + 1)
      setLoading(false)
    })
  }

  const onSearchChange = e => {
    filterItem.value = e.target.value
    updateFilter(e.target.value);
    setFilterItem({...filterItem});
  }

  const onFilter = () => {
    setLoading(true)
    updatePageNumber(1);
    setCurrentPage(1);
    const col = _.find(columnRef, (col) => (col.isSort && (col.isSortAsc || col.isSortDesc)));
    let filterStr;
    if(col){
      filterStr = `?order=${col.isSortAsc ? 'asc' : 'desc'}&orderBy=${col.title}`;
      filterStr = filterItem.value ? `${filterStr}&q=${filterItem.value}&page=1` : `${filterStr}&page=1`;
    }
    else{
      filterStr = filterItem.value
        ? `?orderBy=updatedAt&order=desc&q=${filterItem.value}&page=1`
        : `?orderBy=updatedAt&order=desc&page=1`
    }
    fetchCompanies(filterStr, () => setLoading(false))
  }

  const uploadNewCompany = () => {
    setShowUploadModal(true)
  }

  const downloadLog = log => {
    const { url, customerKey, customerAlgorithm, customerKeyMD5 } = log
    const { downloadStatusLog } = others
    downloadStatusLog(url, customerKey, customerAlgorithm, customerKeyMD5)
  }

  const sort = (colName, order) => {
    let filterStr = ''
    let selected_column = _.find(columnRef, col => col.title === colName);
    if(((order == 'desc') && selected_column.isSortDesc) || ((order == 'asc' && selected_column.isSortAsc))){
      filterStr = '&order=desc&orderBy=updatedAt';
      selected_column.isSortAsc = false
      selected_column.isSortDesc = false
      _.forEach(columnRef, col => {
        if (col.isSort) {
          col.isSortAsc = false
          col.isSortDesc = false
        }
      })
    }
    else{
      _.forEach(columnRef, col => {
        if (col.isSort) {
          col.isSortAsc = false
          col.isSortDesc = false
        }
      })
      if (order) {
        if (order === 'asc') {
          columnRef[colName].isSortAsc = true
          columnRef[colName].isSortDesc = false
          filterStr =
            filterStr +
            `&order=${order}&orderBy=${colName}`
        } else if (order === 'desc') {
          columnRef[colName].isSortAsc = false
          columnRef[colName].isSortDesc = true
          filterStr =
            filterStr +
            `&order=${order}&orderBy=${colName}`
        }
      } else {
        columnRef[colName].isSortAsc = false
        columnRef[colName].isSortDesc = false
      }
    }
    setColumnRef(columnRef);
    setLoading(true)
    updatePageNumber(1);
    filterStr = filterItem.value ? `${filterStr}&q=${filterItem.value}` : filterStr;
    fetchCompanies(`?page=1${filterStr}`,() => setLoading(false))
  //  filterItem.value = filterStr;
  //  setFilterItem(filterItem);

  }

  const viewCompanyDetail = company => {
    history.push(`/companies/${company.id}`)
  }
  return (
    <ContentContainer id='companies-container'>
      <LoadingContainer showLoading={loading} />
      <SectionTitleWithSubAction>
        <div className='content-wrapper'>
          <div>Company</div>
          <Submenu buttons={[subActionRef.current]} className='auto-width' />
        </div>
      </SectionTitleWithSubAction>

      <WidthContent>
        <Filter
          id='users-filter'
          filters={[filterItem]}
          buttons={filterButtons}
          onFilter={onFilter}
        />
        <TableComponent
          id='companies-table'
          columns={columnRef}
          data={companies.companies}
          meta={companies.meta}
          sort={sort}
          handlePageClick={handlePageClick}
        />
        <UploadCSV
          id='upload-csv-window'
          type='company'
          title='UPLOAD COMPANY'
          multiple={false}
          showModal={showUploadModal}
          files={{ files: files.files, log: files.log }}
          downloadLog={downloadLog}
          showLoading={() => {
            setLoading(true)
          }}
          hideLoading={() => {
            setLoading(false)
          }}
          closeModal={() => {
            setShowUploadModal(false)
          }}
        />
      </WidthContent>
    </ContentContainer>
  )
}

function mapStateToProps({ companies, files }) {
  return {
    companies,
    files
  }
}

export default connect(
  mapStateToProps,
  dispatch => ({
    updatePageNumber: (pageNumber) => dispatch(updatePageNumber(pageNumber)),
    updateFilter: (filter) => dispatch(updateFilter(filter)),
    fetchCompanies: (filterStr, cb) => dispatch(fetchCompanies(filterStr, cb)),
    downloadStatusLog: (url, customerKey, customerAlgorithm, customerKeyMD5) =>
      dispatch(
        downloadStatusLog(url, customerKey, customerAlgorithm, customerKeyMD5)
      )
  })
)(CompanyContainer)
