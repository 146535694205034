import React from 'react';
import _ from 'lodash';
import LoadingContainer from '../../Loading/components/LoadingContainer';
import OptionModal from '../../common/Modals/OptionModal/OptionModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCustomerById, clearErrorMsgs, updateCustomerEmailSettings, clearCustMsgs } from '../../redux/CustomersRedux/CustomersRedux';
import { GlobalStyle, ContentContainer, WidthContent } from '../../global-style';
import {
  UserFormContainer,
  InnerDiv,
  Title,
  SubTitle,
  BackLink,
  Table,
  TableLabel,
  TableValue,
  OptDiv,
  SubSection,
  AgreementNoDiv,
  Label,
  Option,
  LinkText,
  Note,
  TableParent,
  Username
} from './style';
import { Icon } from 'react-icons-kit';
import {check} from 'react-icons-kit/fa/check'
import {chevronLeft} from 'react-icons-kit/fa/chevronLeft';

const OPT_IN = 'OPT_IN';
const OPT_OUT = 'OPT_OUT';

export class CustomerDetail extends React.Component{
  constructor(props){
    super(props);
    let currentId = null;
    let companyId = null;
    let showLoading = true;
    const matchCompanyId = window.location.search.match(/\?companyId=(.*)/);
    if(matchCompanyId && matchCompanyId.length>1){
      companyId = matchCompanyId[1];
    }
    const path = window.location.pathname.match(/\/customers\/(.*)/);
    if(path && path.length > 1){
      currentId = path[1];
    }
    else{
      showLoading = false;
    }
    this.state = {
      showLoading,
      currentId,
      showOptionModal:false,
      companyId
    }
  }

  componentDidMount = () => {
    if(!this.state.isCreate){
      this.props.actions.fetchCustomerById(this.state.currentId,() => {
        this.setState({showLoading:false});
      });
    }
  }

  goToUsers = () => {
    this.props.history.push('/users?isBack=true');
  }

  goToCompany = (companyId) => {
    this.props.history.push(`/companies/${companyId}`)
  }

  editUser = () => {
    this.props.history.push(`/users/customers/edit/${this.state.currentId}?userDetail`);
  }

  openOptionModal = (company) => {
    this.setState({company},() => {
      this.setState({showOptionModal:true});
    });
  }

  getCompanyName = (nameStr) => {
    if(!nameStr){return ''}
    if(nameStr.length > 30){
      return {
        name: _.truncate(nameStr,{length:30}),
        title: nameStr
      }
    }
    else{
      return nameStr;
    }
  }

  saveOption = (value, notification) => {
    this.setState({showOptionModal:false, showLoading:true});
    const { company, currentId } = this.state;
    //if((value && !company.isEmailOptedIn) || (!value && company.isEmailOptedIn)){
      const data = {
        isEmailOptedIn:value,
        emailNotification:notification
      };
      this.props.actions.updateCustomerEmailSettings(currentId, company.id, data, () => {
        this.props.actions.fetchCustomerById(this.state.currentId,() => {
          this.setState({showLoading:false});
        });
      });
    //}
    //else{

    //}
  }

  cancelOption = () => {
    this.setState({showOptionModal:false});
  }

  render(){
    const customer = this.props.customers.customers[this.state.currentId];
    if(!customer){return null;}
    const company_count = customer.companies.length-1;
    return(
      <div id="customer-detail-container">
      <ContentContainer>
      <GlobalStyle />
        <LoadingContainer showLoading={this.state.showLoading} />
        <OptionModal showModal={this.state.showOptionModal} company={this.state.company} yes={this.saveOption} no={this.cancelOption} msg="Hello!" />
        <BackLink onClick={this.state.companyId ? (e) => this.goToCompany(this.state.companyId) : this.goToUsers}><span style={{ color: '#a9aaac' }}><Icon icon={chevronLeft} /></span>{this.state.companyId ? 'Back to Company Details' : 'Back to All Users'}</BackLink>

        {customer && <Title><Username>{customer.name ? customer.name : `${customer.firstName} ${customer.lastName}`}<LinkText onClick={this.editUser}>Edit Info</LinkText></Username></Title>}
        {customer &&
          <InnerDiv>
            <TableParent>
            <Table>
              <tbody>
              <tr>
                <TableLabel>EMAIL</TableLabel>
                <TableValue id="customer-email">{customer.email}</TableValue>
              </tr>
              <tr>
                <TableLabel>PHONE NUMBER</TableLabel>
                <TableValue id="customer-name">{customer.phone}</TableValue>
              </tr>
              <tr>
                <TableLabel>COMPANIES</TableLabel>
                <TableValue id="customer-companies" isLink={true}>
                  {_.map(customer.companies,(company, key) => {
                    return(
                      <span onClick={() => this.goToCompany(company.id)}>{company.name}{(key < company_count) ? ', ' : ''}</span>
                    );
                  })}
                </TableValue>
              </tr>
              </tbody>
            </Table>
            </TableParent>
            <WidthContent>
            <SubTitle>Email Settings</SubTitle>
            <SubSection>
              {_.map(customer.companies,(company) => {
                const companyName = this.getCompanyName(company.name);
                return(
                  <OptDiv>
                    <Label id={`customer-${companyName.title}`} title={companyName.title ? companyName.title : ''}>{companyName.name ? companyName.name : companyName}</Label>
                    <Option id={`customer-${companyName.title}-settings`}>
                      <span>{company.isEmailOptedIn && company.emailSetting && company.emailSetting.isEmailOptedIn ? 'Opt-In' : 'Opt-Out'}</span>
                      {company.isEmailOptedIn && <LinkText id="customer-detail-option-change" onClick={() => {this.openOptionModal(company)}}>Change</LinkText>}
                      <Note visible={company.isEmailOptedIn && company.emailSetting && company.emailSetting.isEmailOptedIn}>
                        <Icon className="icon" icon={check} />
                        <div className="note">{(company.emailSetting.emailNotification === 'individual') ? 'Notify when individual invoice is ready.' : 'Notify when all invoices are ready.'}</div>
                      </Note>
                    </Option>
                  </OptDiv>
                );
              })}
            </SubSection>
            <br/>
            <SubTitle>Agreement No</SubTitle>
            <SubSection>
            {_.map(customer.agreements,(ag) => {
              return(
                <AgreementNoDiv id={`customer-detail-agreements-${ag.code}`}>{ag.code}</AgreementNoDiv>
              );
            })}
            </SubSection>
            </WidthContent>
          </InnerDiv>

        }

      </ContentContainer>
      </div>
    );
  }
}

function mapStateToProps({customers}){
  return({
    customers
  });
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ fetchCustomerById, clearErrorMsgs, updateCustomerEmailSettings, clearCustMsgs }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
