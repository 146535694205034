import './InvoiceContainer.scss'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  GlobalStyle,
  ContentContainer,
  SectionTitle,
  Error,
  WidthContent
} from '../global-style'
import { clearJustLoggedIn } from '../redux/AuthRedux/AuthRedux'
import {
  fetchInvoices,
  clearErrorMsgs,
  downloadInvoice,
  downloadStatusLog
} from '../redux/InvoiceRedux/InvoiceRedux'
import { fetchSOAs, downloadSoa } from '../redux/SOARedux/SOARedux'
import MessageModal from '../common/Modals/MessageModal/MessageModal'
import UploadFiles from '../common/Modals/UploadFiles/UploadFiles'
import LoadingContainer from '../Loading/components/LoadingContainer'
import TableComponent from '../common/TableComponent/TableComponent'
import Submenu from '../common/Submenu/Submenu'
import Filter from '../common/Filter/Filter'
import moment from 'moment'
import _ from 'lodash'

export class InvoiceContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuItems: [
        {
          name: 'Individual',
          action: e => {
            this.onClickSubmenu(e, 'Individual')
          },
          selected: true
        },
        {
          name: 'Account Statement',
          action: e => {
            this.onClickSubmenu(e, 'Account Statement')
          },
          selected: false
        }
      ],
      columns: {
        invDate: {
          name: 'DATE',
          attr: 'invDate',
          title: 'invDate',
          type: 'text',
          sortTitle: 'invDate',
          isSort: true,
          sortable: true
        },
        invoiceNo: {
          name: 'INVOICE NO',
          attr: 'invoiceNumber',
          title: 'invoiceNo',
          type: 'text',
          bold: true,
          sortTitle: 'invoiceNo',
          isSort: true,
          sortable: true
        },
        agreementNo: {
          name: 'AGREEMENT NO',
          attr: 'agreementNo',
          title: 'agreementNo',
          type: 'text',
          sortTitle: 'agreementCode',
          isSort: true,
          sortable: true
        },
        vehicleNo: {
          name: 'VEHICLE NO',
          title: 'vehicleNo',
          attr: 'vehicleNo',
          type: 'text',
          sortTitle: 'vehicleNo',
          isSort: true,
          sortable:true
        },
        customer: {
          name: 'CUSTOMER',
          attr: 'customer',
          title: 'customer',
          type: 'text',
          sortTitle: 'companyName',
          isSort: true,
          sortable: true,
          onClickHandle: invoice => {this.goToCompany(invoice)}
        },
        download: {
          name: 'DOWNLOAD',
          attr: 'download',
          title: 'download',
          type: 'image',
          onClickHandle: link => {
            this.downloadInvoice(link)
          }
        } /*,
        attachments:{name: 'ATTACHMENTS', attr:'attachments', title:'attachments', type:'imgText'}*/
      },
      filterItems: {
        search: {
          label: 'search',
          placeHolder: 'Agreement no, invoice no, vehicle no, company name',
          value: '',
          type: 'text',
          onChangeHandle: e => {
            this.onSearchChange(e, 'search', 'text')
          }
        },
        fromDate: {
          label: 'fromDate',
          placeHolder: 'DD/MM/YYYY',
          type: 'month',
          value: moment().format('DD/MM/YYYY'),
          onChangeHandle: (e, year, month) => {
            this.onSearchChange(e, 'fromDate', 'date', month, year)
          },
          rangeError: false
        },
        toDate: {
          label: 'toDate',
          placeHolder: 'DD/MM/YYYY',
          type: 'month',
          value: moment().format('DD/MM/YYYY'),
          onChangeHandle: (e, year, month) => {
            this.onSearchChange(e, 'toDate', 'date', month, year)
          }
        }
      },
      filterButtons: {
        isSearch: { text: 'Search' },
        isUpload: { text: 'Upload Invoice' }
      },
      invoiceColumns: {
        invDate: {
          name: 'DATE',
          attr: 'invDate',
          title: 'invDate',
          type: 'text',
          sortTitle: 'invDate',
          isSort: true,
          sortable: true
        },
        invoiceNo: {
          name: 'INVOICE NO',
          attr: 'no',
          title: 'invoiceNo',
          type: 'text',
          bold: true,
          sortTitle: 'invoiceNo',
          isSort: true,
          sortable: true
        },
        agreementNo: {
          name: 'AGREEMENT NO',
          attr: 'agreementNo',
          title: 'agreementNo',
          type: 'text',
          sortTitle: 'agreementCode',
          isSort: true,
          sortable: true
        },
        vehicleNo: {
          name: 'VEHICLE NO',
          title: 'vehicleNo',
          attr: 'vehicleNo',
          type: 'text',
          sortTitle: 'vehicleNo',
          isSort: true,
          sortable:true
        },
        customer: {
          name: 'CUSTOMER',
          attr: 'customer',
          title: 'customer',
          type: 'text',
          sortTitle: 'companyName',
          isSort: true,
          sortable:true,
          onClickHandle: invoice => {this.goToCompany(invoice)}
        },
        download: {
          name: 'DOWNLOAD',
          attr: 'download',
          title: 'download',
          type: 'image',
          onClickHandle: link => {
            this.downloadInvoice(link)
          }
        } /*,
        attachments:{name: 'ATTACHMENTS', attr:'attachments', title:'attachments', type:'imgText'}*/
      },
      statementColumns: {
        date: {
          name: 'DATE',
          attr: 'date',
          title: 'date',
          type: 'text',
          sortTitle: 'invDate',
          isSort: true,
          sortable: true
        },
        code: {
          name: 'STATEMENT OF ACCOUNT',
          attr: 'name',
          title: 'code',
          type: 'text',
          bold: true,
          sortTitle: 'invoiceNo',
          isSort: true,
          sortable: true
        },
        customer: {
          name: 'CUSTOMER',
          attr: 'customer',
          title: 'customer',
          type: 'text',
          sortTitle: 'companyName',
          isSort: true,
          sortable:true,
          onClickHandle: invoice => {this.goToCompany(invoice)}
        },
        download: {
          name: 'DOWNLOAD',
          attr: 'download',
          title: 'download',
          type: 'image',
          onClickHandle: link => {
            this.downloadInvoice(link)
          }
        } /*,
        attachments:{name: 'ATTACHMENTS', attr:'attachments', title:'attachments', type:'imgText'}*/
      },
      invoiceFilterItems: {
        search: {
          label: 'search',
          placeHolder: 'Agreement no, invoice no, vehicle no, company name',
          value: '',
          type: 'text',
          onChangeHandle: e => {
            this.onSearchChange(e, 'search', 'text')
          }
        },
        fromDate: {
          label: 'fromDate',
          placeHolder: 'DD/MM/YYYY',
          type: 'month',
          value: moment().format('DD/MM/YYYY'),
          onChangeHandle: (e, year, month) => {
            this.onSearchChange(e, 'fromDate', 'date', month, year)
          },
          rangeError: false
        },
        toDate: {
          label: 'toDate',
          placeHolder: 'DD/MM/YYYY',
          type: 'month',
          value: moment().format('DD/MM/YYYY'),
          onChangeHandle: (e, year, month) => {
            this.onSearchChange(e, 'toDate', 'date', month, year)
          }
        }
      },
      statementFilterItems: {
        search: {
          label: 'search',
          placeHolder: 'Search by Customer',
          type: 'text',
          value: '',
          onChangeHandle: e => {
            this.onSearchChange(e, 'search', 'text')
          }
        },
        fromDate: {
          label: 'fromDate',
          placeHolder: 'DD/MM/YYYY',
          type: 'month',
          value: moment().format('DD/MM/YYYY'),
          onChangeHandle: (e, year, month) => {
            this.onSearchChange(e, 'fromDate', 'date', month, year)
          },
          rangeError: false
        },
        toDate: {
          label: 'toDate',
          placeHolder: 'DD/MM/YYYY',
          type: 'month',
          value: moment().format('DD/MM/YYYY'),
          onChangeHandle: (e, year, month) => {
            this.onSearchChange(e, 'toDate', 'date', month, year)
          }
        }
      },
      invoiceButtons: {
        isSearch: { text: 'Search' },
        isUpload: { text: 'Upload Invoice' }
      },
      soaButtons: {
        isSearch: { text: 'Search' },
        isUpload: { text: 'Upload SOA' }
      },
      showLoading: true,
      filterStr: `?order=desc&orderBy=invDate`,
      showLastLogin: false,
      selectedMenu: 'invoices'
    }
  }

  componentDidMount() {
    this.fetchData(
      `${this.state.filterStr}&startDate=${moment()
        .startOf('month')
        .format('YYYY-MM-DD')}&endDate=${moment()
        .endOf('month')
        .format('YYYY-MM-DD')}&page=1`
    )
    this.setState({filterStr:`${this.state.filterStr}&startDate=${moment()
      .startOf('month')
      .format('YYYY-MM-DD')}&endDate=${moment()
      .endOf('month')
      .format('YYYY-MM-DD')}`})
    if (this.props.auth.justLoggedIn) {
      const lastLogin = moment(this.props.auth.auth.user.lastLogin).format(
        'DD/MM/YYYY hh:mm A'
      )
      this.setState({ lastLoginMsg: lastLogin })
      this.setState({ showLastLogin: true }, () => {
        this.props.actions.clearJustLoggedIn()
      })
    }
  }

  componentWillReceiveProps({ auth, invoices }) {
    if (!auth.loggedIn) {
      this.props.history.push('/')
      return
    }
  }

  fetchData = filterStr => {
    if (this.state.selectedMenu === 'invoices') {
      this.props.actions.fetchInvoices(filterStr, () => {
        this.setState({ showLoading: false })
      })
    } else {
      this.props.actions.fetchSOAs(filterStr, () => {
        this.setState({ showLoading: false })
      })
    }
  }

  onClickSubmenu = (e, type) => {
    this.setState({ showLoading: true })
    let filterButtons = {}
    let filterItems
    let { menuItems, columns, selectedMenu, filterStr } = this.state
    menuItems = _.map(menuItems, item => {
      if (item.name === type) {
        item.selected = true
      } else {
        item.selected = false
      }
      return item
    });
    if (type === 'Individual') {
      columns = this.state.invoiceColumns
      selectedMenu = 'invoices'
      filterStr = `?order=desc&orderBy=invDate`
      this.setState({ selectedMenu }, () => {
        this.fetchData(
          `${filterStr}&startDate=${moment()
            .startOf('month')
            .format('YYYY-MM-DD')}&endDate=${moment()
            .endOf('month')
            .format('YYYY-MM-DD')}&page=1`
        )
      })
      filterButtons = this.state.invoiceButtons
      filterItems = this.state.invoiceFilterItems
    } else {
      columns = this.state.statementColumns
      selectedMenu = 'statements'
      filterStr = `?order=desc&orderBy=updatedAt`
      this.setState({ selectedMenu, filterStr }, () => {
        this.fetchData(
          `${filterStr}&startDate=${moment()
            .startOf('month')
            .format('YYYY-MM-DD')}&endDate=${moment()
            .endOf('month')
            .format('YYYY-MM-DD')}&page=1`
        )
      });
      filterButtons = this.state.soaButtons
      filterItems = this.state.statementFilterItems
    }
    filterItems['search'].value = ''
    filterItems['fromDate'].value = moment().format('DD/MM/YYYY')
    filterItems['toDate'].value = moment().format('DD/MM/YYYY')
    this.setState({
      menuItems,
      columns,
      selectedMenu,
      filterButtons,
      filterItems
    })
  }

  handlePageClick = data => {
    this.setState({ showLoading: true });
    const searchStr = this.addFilterString(`${this.state.filterStr}&page=${data.selected+1}`);
    this.fetchData(searchStr);
  }

  onFilter = () => {
    this.setState({ showLoading: true });
    const selected_column = _.find(this.state.columns, (col) => (col.isSort && (col.isSortAsc || col.isSortDesc)));
    let filterStr;
    if(selected_column){
      filterStr = `?order=${selected_column.isSortAsc ? 'asc' : 'desc'}&orderBy=${selected_column.title}`;
    }
    else{
      filterStr =
        this.state.selectedMenu === 'invoices'
          ? `?order=desc&orderBy=invDate`
          : `?order=desc&orderBy=updatedAt`
    }
    _.forEach(this.state.filterItems, (item, key) => {
      if (key === 'search' && item.value) {
        filterStr = filterStr + `&q=${item.value}`
      } else if (key === 'fromDate' && item.value) {
        filterStr =
          filterStr +
          `&startDate=${moment(item.value, 'DD/MM/YYYY')
            .startOf('month')
            .format('YYYY-MM-DD')}`
      } else if (key === 'toDate' && item.value) {
        filterStr =
          filterStr +
          `&endDate=${moment(item.value, 'DD/MM/YYYY')
            .endOf('month')
            .format('YYYY-MM-DD')}`
      }
    })
    this.fetchData(filterStr+'&page=1');
    this.setState({ filterStr });
  }

  sort = (colName, order) => {
    let filterStr = ''
    let { columns } = this.state
    let selected_key = ''


    let selected_column = _.find(this.state.columns, col => col.title === colName);
    if(((order == 'desc') && selected_column.isSortDesc) || ((order == 'asc' && selected_column.isSortAsc))){
      filterStr = '?order=desc&orderBy=invDate';
      selected_column.isSortAsc = false
      selected_column.isSortDesc = false
      _.forEach(columns, col => {
        if (col.sortable) {
          col.isSortAsc = false
          col.isSortDesc = false
        }
      })
    }
    else {
      _.forEach(columns, col => {
        if (col.sortable) {
          col.isSortAsc = false
          col.isSortDesc = false
        }
      })
      if (order === 'asc') {
        selected_column.isSortAsc = true
        selected_column.isSortDesc = false
        filterStr = `?order=${order}&orderBy=${selected_column.sortTitle}`
      } else if (order === 'desc') {
        selected_column.isSortAsc = false
        selected_column.isSortDesc = true
        filterStr = `?order=${order}&orderBy=${selected_column.sortTitle}`
      }
      else {
        selected_column.isSortAsc = false
        selected_column.isSortDesc = false
      }
    }
    this.setState({ showLoading: true })
    columns = { ...columns, [selected_column.title]: selected_column }
    const searchStr = this.addFilterString(`${filterStr}&page=1`);
    this.fetchData(searchStr);
    this.setState({ columns, filterStr })
  }

  addFilterString = (startString) => {
    const { filterItems } = this.state;
    let searchStr = startString;
    _.forEach(filterItems, (item,key) => {
      switch (key) {
        case 'search':
          if(item.value && (!searchStr.includes('q='))){
            searchStr = searchStr + `&q=${item.value}`;
          }
        break;
        case 'toDate':
          if(item.value && (!searchStr.includes('endDate='))){
            const value = moment(item.value,'DD/MM/YYYY').endOf('month').format('YYYY-MM-DD');
            searchStr = searchStr + `&endDate=${value}`;
          }
        break;
        case 'fromDate':
          if(item.value && (!searchStr.includes('startDate='))){
            const value = moment(item.value,'DD/MM/YYYY').startOf('month').format('YYYY-MM-DD');
            searchStr = searchStr + `&startDate=${value}`;
          }
        break;
        default:
      }
    });
    return searchStr;
  }

  onUpload = () => {
    this.setState({ showUploadModal: true })
  }

  onSearchChange = (e, param, type, selectedMonth, selectedYear) => {
    let { filterItems } = this.state
    if (type === 'text') {
      filterItems[param].value = e.target.value
    }
    if (type === 'date') {
      const matches = e.match(/^(\d{2})\/(\d{2})$/)
      let val
      if (matches && matches.length) {
        val =
          param == 'fromDate'
            ? moment(`01/${matches[1]}/${selectedYear}`, 'DD/MM/YYYY').format(
                'DD/MM/YYYY'
              )
            : moment(`01/${matches[1]}/${selectedYear}`, 'DD/MM/YYYY')
                .endOf('month')
                .format('DD/MM/YYYY')
      }
      if (moment(val, 'DD/MM/YYYY').isValid()) {
        filterItems[param].value = val
      } else {
        filterItems[param].value = e
      }
    }
    this.setState({ filterItems })
  }

  downloadInvoice = item => {
    if(this.state.selectedMenu === 'invoices'){
      this.props.actions.downloadInvoice(item.id);
    }
    else{
      this.props.actions.downloadSoa(item.id);
    }

  }

  downloadLog = log => {
    const { url, customerKey, customerAlgorithm, customerKeyMD5 } = log
    this.props.actions.downloadStatusLog(
      url,
      customerKey,
      customerAlgorithm,
      customerKeyMD5
    )
  }

  goToCompany = (invoice) => {
    if(invoice &&  invoice.company){
      this.props.history.push(`/companies/${invoice.company.id}`);
    }
    else{
      this.props.history.push('/companies');
    }
  }

  render() {
    const local_data =
      this.state.selectedMenu === 'invoices'
        ? this.props.invoices.invoices
        : this.props.statements.statements
    const local_meta =
      this.state.selectedMenu === 'invoices'
        ? this.props.invoices.meta
        : this.props.statements.meta
    let { filterButtons } = this.state
    if (!this.props.isAdmin) {
      filterButtons = _.omit(filterButtons, 'isUpload')
    }
    return (
      <ContentContainer
        id={
          this.state.selectedMenu === 'invoices'
            ? 'invoice-container'
            : 'statements-container'
        }
      >
        <LoadingContainer showLoading={this.state.showLoading} />
        <MessageModal
          showModal={this.state.showLastLogin}
          msg={
            <div>
              Welcome to Goldbell Portal. <br /> Your last logged in date and
              time is : <br />
              <b>{this.state.lastLoginMsg} SGT</b>
            </div>
          }
          ok={() => this.setState({ showLastLogin: false })}
        />
        <SectionTitle>
          <div>Invoice</div>
        </SectionTitle>
        <Submenu items={this.state.menuItems} />
        <WidthContent>
          <Filter
            id={
              this.state.selectedMenu === 'invoices'
                ? 'invoice-filter'
                : 'statements-filter'
            }
            filters={this.state.filterItems}
            buttons={filterButtons}
            onFilter={this.onFilter}
            onUpload={this.onUpload}
            title={'Upload Invoices'}
          />
          <TableComponent
            id={
              this.state.selectedMenu === 'invoices'
                ? 'invoice-table'
                : 'statements-table'
            }
            columns={this.state.columns}
            data={local_data}
            meta={local_meta}
            handlePageClick={this.handlePageClick}
            sort={this.sort}
          />
          <UploadFiles
            id='upload-window'
            type={
              this.state.selectedMenu === 'invoices'
                ? 'invoice'
                : 'accountStatement'
            }
            showModal={this.state.showUploadModal}
            files={{ files: this.props.files.files, log: this.props.files.log }}
            downloadLog={this.downloadLog}
            error={this.props.files.error}
            showLoading={() => {
              this.setState({ showLoading: true })
            }}
            hideLoading={() => {
              this.setState({ showLoading: false })
            }}
            closeModal={() => {
              this.setState({ showUploadModal: false })
            }}
          />
        </WidthContent>
      </ContentContainer>
    )
  }
}

function mapStateToProps({ auth, invoices, files, statements }) {
  const isAdmin =
    auth.auth.user.role === 'Admin' || auth.auth.user.role === 'SuperAdmin'
      ? true
      : false
  return {
    auth: auth,
    invoices: invoices,
    files,
    statements,
    isAdmin
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchInvoices,
        clearErrorMsgs,
        clearJustLoggedIn,
        downloadInvoice,
        downloadStatusLog,
        fetchSOAs,
        downloadSoa
      },
      dispatch
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceContainer)
