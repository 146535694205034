import axios from 'axios';
import { axiosInstance } from '../../redux/AuthRedux/axiosInterceptors';

const baseUrl = `${process.env.REACT_APP_CMS_API_URL}account-statements`;
export const REFRESH_FAILURE = 'REFRESH_FAILURE';

export async function getSOAs(filterStr) {
  const response = await axiosInstance.get(`${baseUrl}${filterStr ? filterStr : ''}`);
  return response.data;
}

export async function downloadSoa(soaId){
  const url = `${baseUrl}/${soaId}/download`;
  const response = await axiosInstance.get(url);
  return response.data;
}

export async function downloadS3(link, fileName, s3CustomerKey, s3CustomerAlgorithm, s3customerKeyMD5){
  const response = await axios.get(link, {
    headers:{
    'x-amz-server-side-encryption-customer-key' : s3CustomerKey,
    'x-amz-server-side-encryption-customer-algorithm':s3CustomerAlgorithm,
    'x-amz-server-side-encryption-customer-key-MD5':s3customerKeyMD5
  },
  responseType:'blob',
  });
  downloadFile(fileName, URL.createObjectURL(response.data));
  return 'success!';
}

function downloadFile(fileName, urlData) {

  var aLink = document.createElement('a');
  aLink.download = fileName;
  aLink.href = urlData;

  var event = new MouseEvent('click');
  aLink.dispatchEvent(event);
}
