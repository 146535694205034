import axios from 'axios';
import { axiosInstance } from '../../redux/AuthRedux/axiosInterceptors'

const baseUrl = `${process.env.REACT_APP_CMS_API_URL}action-logs`

export async function getAuditLog(filterStr) {
  const response = await axiosInstance.get(
    `${baseUrl}${filterStr ? filterStr : ''}`
  )

  return response.data
}

export async function downloadAudit(link) {
  return await axiosInstance.get(link)
}

export async function downloadS3(link, s3CustomerKey, s3CustomerAlgorithm, s3customerKeyMD5){
  const response = await axios.get(link, {
    headers:{
    'x-amz-server-side-encryption-customer-key' : s3CustomerKey,
    'x-amz-server-side-encryption-customer-algorithm':s3CustomerAlgorithm,
    'x-amz-server-side-encryption-customer-key-MD5':s3customerKeyMD5
  },
  responseType:'blob',
  });
  window.open(URL.createObjectURL(response.data));
  return 'success!';
}
