import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './CompanyDetail.scss';
import {
  fetchCompanyById,
  updateCompany
} from '../../redux/CompaniesRedux/CompaniesRedux'
import {
  getCompanyDetailSuccess,
  getUsersByCompany,
  getAgreementsByCompany,
  updateCompanyDetail
} from '../../redux/CompaniesRedux/CompanyDetailRedux'
import LoadingContainer from '../../Loading/components/LoadingContainer'
import TableComponent from '../../common/TableComponent/TableComponent'
import {
  GlobalStyle,
  ContentContainer,
  WidthContent,
  BackLink
} from '../../global-style'
import {
  Title,
  SectionInfoWrapper,
  SectionInfo,
  InfoWrapper,
  ItemInfo,
  SectionEmailWrapper,
  EmailIconWrapper,
  EmailInfoWrapper,
  EmailInfo,
  Action,
  TableTitle
} from './style'
import { Icon } from 'react-icons-kit'
import { chevronLeft } from 'react-icons-kit/fa/chevronLeft'
import { envelopeO } from 'react-icons-kit/fa/envelopeO'
import Modal from 'react-modal'
import '../../common/Modals/Modals.scss'

function CompanyDetail({
  companyId,
  companyDetail,
  currentCompany,
  fetchCompanyById,
  updateCompany,
  companyDetailAction,
  history,
  ...others
}) {
  const [loading, showLoading] = useState(false)
  const [emailOptin, setEmailOptin] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const columnUserRef = useRef({
    name: {
      name: 'NAME',
      attr: 'name',
      type: 'text',
      bold: true,
      onClickHandle: user => {
        history.push(`/users/customers/${user.id}?companyId=${companyId}`)
      }
    },
    email: {
      name: 'EMAIL',
      attr: 'email',
      type: 'text'
    },
    agreements: {
      name: 'AGREEMENT NUMBER',
      attr: 'agreements',
      type: 'arrayLink',
      bold: true,
      arrayLinkType: 'company_agreements'
    },
    action: {
      name: 'ACTION',
      attr: 'action',
      type: 'arrayLink',
      action: true,
      values: [
        {
          label: 'Edit',
          onClickHandle: user => {
            history.push(`/users/customers/edit/${user.id}?companyId=${companyId}`)
          },
          color: '#fcb72d'
        }
      ]
    }
  })

  const columnAgreementRef = useRef({
    code: {
      name: 'AGREEMENT NUMBER',
      attr: 'code',
      type: 'text',
      bold: true
    },
    users: {
      name: 'USERS',
      attr: 'users',
      type: 'arrayLink',
      arrayLinkType: 'company_users'
    },
    action: {
      name: 'ACTION',
      attr: 'action',
      type: 'arrayLink',
      action: true,
      values: [
        {
          label: 'Edit',
          onClickHandle: item =>
            history.push(`/companies/${companyId}/agreements/edit/${item.id}`),
          color: '#fcb72d'
        }
      ]
    }
  })

  useEffect(
    () => {
      async function fetchCompanyDetail() {
        showLoading(true)
        const {
          getUsersByCompany,
          getAgreementsByCompany
        } = companyDetailAction
        const promises = [
          getUsersByCompany(companyId, '?page=1&perPage=5'),
          getAgreementsByCompany(companyId, '?page=1&perPage=5')
        ]

        try {
          fetchCompanyById(companyId, async data => {
            companyDetailAction.getCompanyDetailSuccess(data.company)
            setEmailOptin(data.company.isEmailOptedIn)
            await Promise.all(promises)
            showLoading(false)
          })
        } catch {
          showLoading(false)
        }
      }
      fetchCompanyDetail()
    },
    [companyId]
  )

  const goToCompanies = () => {
    history.push('/companies?isBack=true')
  }

  const handlePageClick = type => async data => {
    const { getUsersByCompany, getAgreementsByCompany } = companyDetailAction

    const getDatas =
      type === 'users' ? getUsersByCompany : getAgreementsByCompany
    showLoading(true)
    try {
      await getDatas(companyId, `?page=${data.selected + 1}&perPage=5`)
      showLoading(false)
    } catch {
      showLoading(false)
    }
  }

  const handleClickEditEmailSetting = () => {
    setShowEmailModal(true)
  }

  const onChangeEmailSetting = e => {
    switch (e.target.id) {
      case 'allow':
        return setEmailOptin(true)
      case 'not-allow':
        return setEmailOptin(false)
      default:
        return setEmailOptin(emailOptin)
    }
  }

  const handleClickCancelEmailModal = () => {
    setEmailOptin(currentCompany.isEmailOptedIn)
    setShowEmailModal(false)
  }

  const handleSaveEmailSetting = () => {
    updateCompany(
      companyId,
      {
        isEmailOptedIn: emailOptin
      },
      data => {
        companyDetailAction.updateCompanyDetail(data.company)
          fetchCompanyById(companyId, async data => {
            companyDetailAction.getCompanyDetailSuccess(data.company)
            showLoading(false)
          })
        setShowEmailModal(false)
      }
    )
  }

  const renderChangeEmailSetting = () => {
    return (
      <Modal
        className='change-email-setting-modal'
        isOpen={showEmailModal}
        ariaHideApp={false}
      >
        <div className='email-setting-wrapper'>
          <div className='title'>Email Notification Settings</div>
          <div className='radio-wrapper'>
            <div className='round'>
              <input
                type='radio'
                id='allow'
                name='notify'
                defaultChecked={emailOptin}
                onChange={onChangeEmailSetting}
              />
              <label htmlFor='allow' />
              <span>Opt-In</span>
            </div>
            <div className='round'>
              <input
                type='radio'
                id='not-allow'
                name='notify'
                defaultChecked={!emailOptin}
                onChange={onChangeEmailSetting}
              />
              <label htmlFor='not-allow' />
              <span>Opt-Out</span>
            </div>
          </div>
          {!emailOptin && (
            <div className='warning'>
              <p>
                Please note that by choosing to opt-out, all members of your
                company will <b>NOT</b> be notified when your invoice is ready.
              </p>
              <p>
                By opting out from the email notification, you are hereby
                acknowledging that you will soley be responsible for payment of
                your company’s invoices to GoldBell on time.
              </p>
              <p>Your invoices will still be accessible on GoldBell Portal.</p>
            </div>
          )}
          <div className='action-buttons'>
            <button id='cancel-button' onClick={handleClickCancelEmailModal}>
              <span>Cancel</span>
            </button>
            <button id='save-button' onClick={handleSaveEmailSetting}>
              <span>Save</span>
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  if (!currentCompany) return null

  return (
    <div id='customer-detail-container'>
      <ContentContainer>
        <GlobalStyle />
        <LoadingContainer showLoading={loading} />
        <BackLink onClick={goToCompanies}>
          <span>
            <Icon icon={chevronLeft} />
          </span>
          Back to All Companies
        </BackLink>
        <Title>{currentCompany.name}</Title>
        <SectionInfoWrapper>
          <SectionInfo>
            <InfoWrapper>
              <ItemInfo>
                <p>Company code</p>
                <p>{currentCompany.code}</p>
              </ItemInfo>
              <ItemInfo>
                <p>Address</p>
                <p>{currentCompany.address}</p>
              </ItemInfo>
              <ItemInfo>
                <p>Country</p>
                <p>{currentCompany.country}</p>
              </ItemInfo>
            </InfoWrapper>
            <Action>
              <span
                onClick={() => history.push(`/companies/edit/${companyId}`)}
              >
                Edit
              </span>
            </Action>
          </SectionInfo>

          <SectionInfo>
            <SectionEmailWrapper>
              <EmailIconWrapper>
                <span>
                  <Icon icon={envelopeO} />
                </span>
              </EmailIconWrapper>
              <EmailInfoWrapper>
                <EmailInfo optin={currentCompany.isEmailOptedIn}>
                  <span>Email Setting</span>
                  <span>
                    {currentCompany.isEmailOptedIn ? 'Opt-in' : 'Opt-out'}
                  </span>
                </EmailInfo>
                <p className='email-placeholder'>
                  {currentCompany.isEmailOptedIn ? 'All users under this company will receive email notifications for invoices linked to this company. User can individually opt-out if they wish to.' : 'All users under this company will not receive email notifications for invoices linked to this company.'}
                </p>
              </EmailInfoWrapper>
            </SectionEmailWrapper>
            <Action>
              <span onClick={handleClickEditEmailSetting}>Change</span>
            </Action>
          </SectionInfo>
        </SectionInfoWrapper>
        <WidthContent>
          {renderChangeEmailSetting()}
          {companyDetail.users && (
            <div className="users-section">
              <TableTitle>
                <div className='table-title'>
                  All Users({companyDetail.users.meta.paginate.totalCount})
                </div>
                <Action>
                  <span onClick={() => history.push(`/users/customers/new?companyId=${companyId}`)}>
                    + New User
                  </span>
                </Action>
              </TableTitle>
              <Action>
                <span>View All</span>
              </Action>
              <TableComponent
                columns={columnUserRef.current}
                data={companyDetail.users.users}
                meta={companyDetail.users.meta}
                handlePageClick={handlePageClick('users')}
              />
            </div>
          )}
          {companyDetail.agreements && (
            <div>
              <TableTitle>
                <div className='table-title'>
                  All Agreement Numbers(
                  {companyDetail.agreements.meta.paginate.totalCount})
                </div>
                <Action>
                  <span
                    onClick={() =>
                      history.push(`/companies/${companyId}/agreements/new`)
                    }
                  >
                    + New Agreement No
                  </span>
                </Action>
              </TableTitle>
              <TableComponent
                columns={columnAgreementRef.current}
                data={companyDetail.agreements.agreements}
                meta={companyDetail.agreements.meta}
                handlePageClick={handlePageClick('agreements')}
              />
            </div>
          )}
        </WidthContent>
      </ContentContainer>
    </div>
  )
}

export default connect(
  (state, ownProps) => ({
    companies: state.companies,
    companyId: ownProps.match.params.id,
    companyDetail: state.companyDetail,
    currentCompany: state.companyDetail.companyDetail
  }),
  dispatch => ({
    fetchCompanyById: bindActionCreators(fetchCompanyById, dispatch),
    updateCompany: bindActionCreators(updateCompany, dispatch),
    companyDetailAction: bindActionCreators(
      {
        getCompanyDetailSuccess,
        getUsersByCompany,
        getAgreementsByCompany,
        updateCompanyDetail
      },
      dispatch
    )
  })
)(CompanyDetail)
