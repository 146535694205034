import styled from 'styled-components';

export const EmailHeader = styled.div`
font-family: VistaSansOT-Reg;
font-size: 16px;
text-align: center;
color: #2d2926;
position:relative;
top:-10px;
`;

export const CountryCode = styled.input`
width: 60px;
height: 35px;
border: 1px solid #ccc;
background-color: #fff;
border-radius:4px;
padding-left:10px;
`;

export const AreaCode = styled.input`
width: 79px;
height: 35px;
border: 1px solid #ccc;
background-color: #fff;
border-radius:4px;
padding-left:10px;
margin-left:8px;
float:left;
`;
export const PhoneNumber = styled.input`
width: 144px;
height: 35px;
border: 1px solid #ccc;
background-color: #fff;
border-radius:4px;
padding-left:10px;
margin-left:8px;
float:left;
`;

export const Text = styled.p`
  color: ${props => props.color || '#4d4d4d'};
  margin-bottom:0px;
  position:relative;
  left:${props => props.isPhone ? '-145px' : '0px'};
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #777;
  font-size: 0.8em;
  margin: 0.5em 0;
  position: relative;
  float:left;
`;
