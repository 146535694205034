import styled from 'styled-components'

export const Title = styled.div`
  font-family: VistaSansOT;
  font-size: 30px;
  font-weight: bold;
  width: 1023px;
  margin: 15px auto 10px auto;
`

export const SectionInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1023px;
  margin: 0 auto;
`

export const SectionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 480px;
  border-radius: 1px;
  background-color: #fafafa;
  padding: 25px 20px;
`

export const InfoWrapper = styled.div``

export const SectionEmailWrapper = styled.div`
  display: flex;
  width: 365px;
`

export const EmailIconWrapper = styled.div`
  margin-right: 20px;
`

export const EmailInfoWrapper = styled.div`
  .email-placeholder {
    font-family: VistaSansOT;
    font-size: 14px;
    font-style: italic;
    color: #a9aaac;
    margin: 15px 0 0 0;
  }
`

export const ItemInfo = styled.div`
  p {
    display: inline-block
    &:first-child {
      font-family: VistaSansOT;
      width: 105px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
    }
    &:last-child {
      font-family: VistaSansOT-Reg;
      font-size: 14px;
      color: #2d2926;
    }
  }
`

export const EmailInfo = styled.div`
  display: inline-block;
  span {
    &:first-child {
      font-family: VistaSansOT;
      width: 105px;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      margin-right: 20px;
    }
    &:last-child {
      font-family: VistaSansOT-Reg;
      font-size: 14px;
      color: ${props => (props.optin ? '#1d9f0c' : '#9b9b9b')};
    }
  }
`

export const Action = styled.div`
  span {
    font-family: VistaSansOT;
    font-size: 14px;
    font-weight: bold;
    color: #fcb72d;
    cursor: pointer;
  }
`

export const TableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1023px;
  margin: 30px auto 5px auto;

  .table-title {
    font-family: VistaSansOT;
    font-size: 20px;
    font-weight: bold;
  }
`
