import _ from 'lodash';
import moment from 'moment';

export function formatCustomers(data){
  //trunacte name at 10
  //email at 26
  //company name 15
  let formatted_customers = _.map(data, (customer) => {
    let new_customer = customer;
    new_customer.companyArr = _.map(new_customer.companies,(company) => {
      if(company.name && (company.name).length > 18){
        const nameTip = company.name;
        const name = _.truncate(company.name, {length:18});
        return {name, id:company.id, nameTip};
      }
      else{
        return {name:company.name, id:company.id};
      }
    });
    if(customer.name && (customer.name).length > 17){
      new_customer.nameTip = customer.name;
      new_customer.nameStr= _.truncate(customer.name, {length: customer.role === 'Admin' ? 25 : 17});
    }
    else{
      new_customer.nameStr = customer.name;
    }
    if(customer.email && customer.email.length > 28){
      new_customer.emailTip = customer.email;
      new_customer.emailStr= _.truncate(customer.email, {length: 28});
    }
    else{
      new_customer.emailStr = customer.email;
    }
    new_customer.createdDate = new_customer.createdAt ? `${moment(new_customer.createdAt).format('DD/MM/YYYY')}` : '';
    if(new_customer.createdAt){
      new_customer.createdDateNextLine = `${moment(new_customer.createdAt).format('hh:mm A')}`;
    }
    new_customer.lastLoginStr = new_customer.lastLogin ? moment(new_customer.lastLogin).format('DD/MM/YYYY') : 'N/A';
    if(new_customer.lastLogin){
      new_customer.lastLoginStrNextLine = `${moment(new_customer.lastLogin).format('hh:mm A')}`;
    }
    return new_customer;
  });
  //formatted_customers = _.sortBy(formatted_customers,'createdAt');
  return formatted_customers;
}

export function formatStaff(data){
  const formatted_staff = _.map(data, (staff) => {
    let new_staff = staff;

    return new_staff;
  });
  return formatted_staff;
}
