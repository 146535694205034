import axios from 'axios'
import { axiosInstance } from '../../redux/AuthRedux/axiosInterceptors'

const baseUrl = `${process.env.REACT_APP_CMS_API_URL}`
export const REFRESH_FAILURE = 'REFRESH_FAILURE'

export async function upload(files, type) {
  let uploadUrl;
  switch(type){
    case 'invoice':
      uploadUrl = 'invoices'
      break
    case 'accountStatement':
      uploadUrl = 'account-statements'
      break
    case 'customer':
      uploadUrl = 'import/customers'
      break
    case 'company':
      uploadUrl = 'import/companies'
      break
  }
  //const uploadUrl = (type === 'invoice') ? 'invoices' : 'account-statements';
  let data = null
  const response = await axiosInstance.post(`${baseUrl}${uploadUrl}`, files)
    .catch(
      err => {
        data = err.response.data
      })
  data = data !== null ? data : response.data
  return data

  /*const expected_response = {
    "accessToken": "ewfwef890w8ef9ew8few9f8ew9f89ef8ef8e90wf",
    "refreshToken": "wefwef8ew9f8ew90f8ew08f0wef809ewf"
  };
  return expected_response;*/
}
