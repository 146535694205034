import styled from 'styled-components';

export const UserFormContainer = styled.div`
  width:100%;
  margin: 40px;
`;

export const InnerDiv = styled.div`
  width: 100%;
`;

export const UserForm = styled.form`
width: 945px;
height: auto;
border-radius: 4px;
border: solid 1px #dcdcdc;
background-color: #f9f9f9;
padding-left:74px;
padding-right:74px;
padding-top:33px;
margin:auto;
`;


export const Label = styled.label`
font-size: 12px;
font-weight: bold;
color: #53565a;
width:100%;
`;

export const Title = styled.div`
font-family: VistaSansOT;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #53565a;
  padding-bottom:36px;
`;
export const BackLink = styled.div`
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  color: #2d2926;
  padding-bottom:50px;
  cursor:pointer;
  width:1023px;
  margin:auto;
  margin-top:17px;
  padding-left:40px;
`;

export const FormItem = styled.div`
  width:385px;
  float:left;
  padding-left:${props => props.secondColumn ? '22px' : '0px'};
  padding-top:${props => props.secondRow ? '32px' : '0px'};
`;

export const Text = styled.div`
  color: ${props => props.color || '#4d4d4d'}
  margin-bottom:-24px;
  display:${props => props.display}
  font-size:13px;
  height:24px;
`;

export const Input = styled.input`
  width: 100%;
  height: 35px;
  padding-left:10px;
  border-radius: 4px;
  border: solid 1px #dcdcdc;
  background-color: #ffffff;
`;

export const ButtonContainer = styled.div`
  width:470px;
  margin:auto;
  padding-top:63px;
  display:inline-block;
  margin-left:159px;
  padding-bottom:67px;
`;

export const Button = styled.button`
  width: 225px;
  height: 36px;
  border-radius: 4px;
  box-shadow: ${props => (props.type === 'submit') ? '0 3px 0 0 #d0931a' : '0 3px 0 0 #929292'};
  background-color: ${props => (props.type === 'submit') ? '#fcb72d' : '#a9aaac'};
  cursor:pointer;
  margin-left:${props => (props.type === 'submit') ? '20px' : '0px'};
  color:#ffffff;
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: bold;
  cursor:pointer;
`;

export const ForgotLink = styled.div`
  width:100%;
  font-family: VistaSansOT-Reg;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d2926;
  margin-top:36px;
  text-decoration:underline;
  cursor:pointer;
`;

export const PlainText = styled.div`
  font-family: VistaSansOT;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  position:relative;
  top:113px;
`;

export const WebsiteLink = styled.span`
font-family: VistaSansOT-Reg;
font-size: 14px;
font-weight: 800;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #fcb72d;
cursor:pointer;
`;

export const LoginFooter = styled.div`
  width: 100%;
  height: 68px;
  background-color: #f1f1f1;
`;

export const FooterImgLeft = styled.img`
  float:left;
  padding-top:25px;
  padding-left:45px;
`;

export const FooterImgRight = styled.img`
  float:right;
  padding-top:15px;
  padding-right:45px;
`;
