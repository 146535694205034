import React from 'react';
import _ from 'lodash';
import LoadingContainer from '../../Loading/components/LoadingContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStaff, updateStaff, fetchStaff, fetchStaffById, clearErrorMsgs } from '../../redux/StaffRedux/StaffRedux';
import { Formik } from "formik";
import { GlobalStyle, ContentContainer, WidthContent } from '../../global-style';
import { UserFormContainer, UserForm, InnerDiv, Title, BackLink, Button, Text, Label, Input, FormItem, ButtonContainer } from './style';
import { Error } from '../ManageCustomer/style';
import { Icon } from 'react-icons-kit';
import {close} from 'react-icons-kit/fa/close';
import {chevronLeft} from 'react-icons-kit/fa/chevronLeft';

export class ManageStaff extends React.Component{
  constructor(props){
    super(props);
    let isCreate = true;
    let currentId = null;
    let showLoading = true;
    let submitBtnText = 'Create New Staff User';
    const path = window.location.pathname.match(/\/admins\/edit\/(.*)/);
    if(path && path.length > 1){
      isCreate = false;
      currentId = path[1];
      submitBtnText = 'Save'
    }
    else{
      showLoading = false;
    }
    this.state = {
      current:null,
      showLoading,
      isCreate,
      currentId,
      submitBtnText
    }
  }

  componentDidMount = () => {
    if(!this.state.isCreate){
      this.props.actions.fetchStaffById(this.state.currentId,() => {
        this.setState({showLoading:false});
      });
    }
  }
  componentWillReceiveProps = ({staff}) => {
    if(!this.state.isCreate && !this.state.current){
      const staffUser = staff.staff[this.state.currentId];
      if(staffUser){
        this.setState({current: staffUser});
      }
    }
  }

  goToUsers = () => {
    this.props.history.push('/users?type=Admin&isBack=true');
  }

  onChangeHandler = (field, e) => {
    const my_fields = this.state.fields;
    my_fields[field] = e.target.value;
    this.setState({fields: my_fields});
  }

  handleSubmit = (values) => {
    this.setState({showLoading:true});
    let data = values;
    data.role = 'Admin';
    if(!values.phone){
      data = _.omit(data,'phone');
    }
    if(this.state.isCreate){
      this.props.actions.createStaff(data, (success) => {
        this.setState({showLoading:false});
        if(success){
          this.goToUsers();
        }
      });
    }
    else{
      data.firstName = data.name;
      data = _.omit(data, 'confirmEmail');
      this.props.actions.updateStaff(data, this.state.currentId, (success) => {
        this.setState({showLoading:false});
        if(success){
          this.goToUsers();
        }
      });
    }

  }

  formatForEdit = (user) => {
    if(!user){return {}}
    if(user.firstName && user.lastName){
      user.name = `${user.firstName} ${user.lastName}`;
      user.confirmEmail = user.email;
      //staff.phone
    }
    return user;
  }

  clearErrors = () => {
    this.props.actions.clearErrorMsgs();
  }

  render(){
    let {current} = this.state;
    if(!this.state.isCreate && !current){
      return null;
    }
  //  const {fields} = this.state;
    return(
      <div id="manage-staff-container">
      <ContentContainer>
      <GlobalStyle />
        <LoadingContainer showLoading={this.state.showLoading} />
        <BackLink onClick={this.goToUsers}><span style={{ color: '#a9aaac' }}><Icon icon={chevronLeft} /></span>Back to All Users</BackLink>
        <Title>{this.state.isCreate ? 'CREATE NEW STAFF USER' : 'EDIT STAFF USER'}</Title>
        <InnerDiv>
          <WidthContent>
          <Formik
          initialValues={{
            ...current
          }}
          validate={values => {
            let errors = {};
            // REGEX
            let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            // VALIDATION
            if(!values.name){
              errors.name = "Please enter a name."
            }
            if(!values.email){
              errors.email = "Please enter an email.";
            }
            else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))){
              errors.email = "Invalid email address.";
            }
            if(this.state.isCreate){
              if(!values.confirmEmail){
                errors.confirmEmail = "Please enter an email.";
              }
              else if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.confirmEmail))){
                errors.confirmEmail = "Invalid email address.";
              }
              if((values.email && values.confirmEmail) && !(values.email === values.confirmEmail)){
                errors.confirmEmail = "Confirmation email and email do not match.";
              }
            }
            if(values.phone && !(/^\+?\d{1,14}$/i.test(values.phone))){
              errors.phone = "Invalid phone number."
            }
            return errors;
          }}
          onSubmit={values => {
            this.handleSubmit(values);
          }}
          render={({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            return(
            <UserForm onSubmit={handleSubmit}>
              {this.props.staff.error && <Error id="manage-staff-error">{this.props.staff.error}<span onClick={this.clearErrors} style={{ color: '#a94442' }}><Icon icon={close} /></span></Error>}
              <FormItem>
                <Label>
                  NAME
                </Label>
                <Input
                  id="manage-staff-name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  border={touched.name && errors.name && "1px solid red"}
                  type="text"
                  name="name"
                />
                <Text display={(errors.name && touched.name) ? 'block' : 'none'} color="red">{errors.name}</Text>
              </FormItem>
              <FormItem secondColumn={true}>
                <Label>
                PHONE NUMBER
                </Label>
                <Input
                  id="manage-staff-phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  border={
                    touched.phone && errors.phone && "1px solid red"
                  }
                  type="text"
                  name="phone"
                />
                {errors.phone && <Text display={(errors.phone && touched.phone) ? 'block' : 'none'} color="red">{errors.phone}</Text>}
              </FormItem>
              <FormItem secondRow={true}>
                <Label>
                EMAIL
                </Label>
                <Input
                  id="manage-staff-email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  border={
                    touched.email && errors.email && "1px solid red"
                  }
                  type="text"
                  name="email"
                />
                <Text color="red" display={(errors.email && touched.email) ? 'block' : 'none'}>{errors.email}</Text>
              </FormItem>
              {this.state.isCreate && <FormItem secondColumn={true} secondRow={true}>
                <Label>
                CONFIRM EMAIL
                </Label>
                <Input
                  id="manage-staff-confirm-email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmEmail}
                  border={
                    touched.confirmEmail && errors.confirmEmail && "1px solid red"
                  }
                  type="text"
                  name="confirmEmail"
                />
                <Text display={(errors.confirmEmail && touched.confirmEmail) ? 'block' : 'none'} color="red">{errors.confirmEmail}</Text>
              </FormItem>}
              <ButtonContainer>
                <Button id="manage-staff-cancel-button" type="button" onClick={this.goToUsers}>Cancel</Button>
                <Button id="manage-staff-submit-button" type="submit">{this.state.submitBtnText}</Button>
              </ButtonContainer>
            </UserForm>
          )}}
        />
        </WidthContent>
        </InnerDiv>
      </ContentContainer>
      </div>
    );
  }
}

function mapStateToProps({staff}){
  return({
    staff
  });
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ fetchStaff, fetchStaffById, createStaff, updateStaff, clearErrorMsgs }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageStaff);
